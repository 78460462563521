import Vue from 'vue';

import MultipleSelect from './MultipleSelect.vue'

Vue.component('s-multiple-select',MultipleSelect);

export default{
    MultipleSelect,
}

