<template>
    <div id="activity">
        <!-- v-if="(activitiesList && activitiesList.length && !loading) || showSearch" -->
        <!-- <div class="activity-table__search" >
            <s-input placeholder="Search for an account by a user’s name, email or domain" v-model="searchAccount"></s-input>
        </div> -->
        <s-card class="activity-table__card border-top-0 p-0">
          <s-card-body class="activity-table__table p-0">
              <div class="activity-table__table-container" @scroll="getScrollPos">
                  <div class="div-shadow-left" v-if="!hideShadows && scrollIn && scrollIn !== 'left' && activitiesList && activitiesList.length"></div>
                  <!-- activitiesList && activitiesList.length &&  -->
                  <!-- v-if="(!loading) || showSearch" -->
                  <table  class="activity-table__table--table"  id="myTable">
                      <thead class="activity-table__table--header border-0 p-0 mb-4">
                          <!-- Name -->
                          <td class="text-truncate activity-table__table--header-name">
                              <span>{{$t('activity.account_name')}}</span>
                          </td>
                          <!-- ACTION -->
                          <td class="text-truncate activity-table__table--header-action">
                              <span>{{$t('activity.action')}}</span>
                          </td>
                          <!-- YOUR SHARE -->
                          <td class="text-truncate activity-table__table--header-share">
                              <span>{{$t('activity.your_share')}}</span>
                          </td>
                          <!-- PLATFORM FEES -->
                          <td class="text-truncate activity-table__table--header-share">
                              <span>{{$t('activity.platform_fees')}}</span>
                          </td>
                          <!-- PAID BY USER ON -->
                          <td class="text-truncate activity-table__table--header-paid-user">
                              <span>{{$t('activity.paid_by_user_on')}}</span>
                          </td>
                          <!-- PAID TO YOU ON -->
                          <td class="text-truncate activity-table__table--header-paid-to-you">
                              <span>{{$t('activity.paid_to_you_on')}}</span>
                          </td>
                      </thead>
                      <tbody class="activity-table__table--body">
                        <!-- && showSearch -->
                          <tr class="w-100 display-table-cell" v-if="loading ">
                              <s-loader :type="'scalify'" class="my-3" style="left:calc(50% - 42px);position:relative;"></s-loader>
                          </tr>
                          <tr class="w-100 display-table-cell" v-if="!loading && (activitiesList == null || activitiesList.length == 0)">
                              <p class="my-3 position-relative font-weight-sbold" style="left:40%;">{{$t('globale_world.no_result')}}</p>
                          </tr>
                          <div v-if="activitiesList && activitiesList.length > 0">

                            <tr  v-show="!loading" v-for="(account, accountIndex) in activitiesList" :key="accountIndex" class="activity-table__table--row w-100">

                              <!-- ACCOUNT NAME -->
                              <td :tooltip-position="accountIndex==0?'right':'top'" :tooltip="account.name" class="display-table-cell activity-table__table--body-name">
                                <p  class="text-truncate">{{account.name}}</p>
                              </td>

                              <!-- ACTION -->
                              <td class="display-table-cell activity-table__table--body-action">
                                <s-badge
                                :class="account.type=='SIGNED UP'?'badge--blue-dark':account.type=='PAID'?'badge--success':account.type=='Upgrade'?'badge--primary':account.type=='CANCELED SUBSCRIPTION'?'badge--warning':account.type=='refunded'?'badge--danger':account.type=='Downgrade'?'badge--input':''"
                                 class="badge display-table-cell">
                                 {{account.type=='PAID'?`PAID ${new Intl.NumberFormat('en-US', {style: 'currency',currency: 'USD',}).format(account.amount).split("$")[1]} USD`:account.type=='refunded'?`REFUNDED ${new Intl.NumberFormat('en-US', {style: 'currency',currency: 'USD',}).format(account.amount_refunded).split("$")[1]} USD`: account.type}}</s-badge>
                              </td>

                              <!-- YOUR SHARE -->
                              <td class="display-table-cell activity-table__table--body-share">
                                <p class="text-truncate">{{account.type=='refunded' ? ' - ' : account.aff_commission == null ? `-` :account.aff_commission > 0 ? `+ ${new Intl.NumberFormat('en-US', {style: 'currency',currency: 'USD',}).format(account.aff_commission).split("$")[1]} USD` : `- ${new Intl.NumberFormat('en-US', {style: 'currency',currency: 'USD',}).format(account.aff_commission).split("$")[1]} USD`}}</p>
                              </td>

                              <!-- PLATFORM FEES -->
                              <td class="display-table-cell activity-table__table--body-share">
                                <p class="text-truncate">{{account.type=='refunded' ? ' - ' : account.platform_fees == null ? `-` : `${account.platform_fees * 100} %`}}</p>
                              </td>

                              <!-- PAID BY USER ON -->
                              <td class="display-table-cell activity-table__table--body-paid-user">
                                <p class="text-truncate">{{account.type != 'refunded' && account.paid_by_user ? formatDate(account.paid_by_user) : '-'}}</p>
                              </td>

                              <!-- PAID TO YOU ON -->
                              <td class="display-table-cell activity-table__table--body-paid-to-you">
                                <p class="text-truncate">{{account.paid_to_aff?formatDate(account.paid_to_aff):'-'}}</p>
                              </td>
                            </tr>


                          </div>

                      </tbody>
                  </table>
                  <div class="div-shadow-right" v-if="!hideShadows && scrollIn && scrollIn !== 'right' && ((activitiesList && activitiesList.length)||showSearch)"></div>
              </div>
          </s-card-body>
          <s-card-body v-if="activitiesList && activitiesList.length && !showSearch && loadMoreActivities && !loading" class="p-0 activity-table__load-more text-center">
            <button v-if="!loadingLoadMore" class="btn btn--white" @click="btnLoadMoreActivities()">{{$t('globale_world.load_more')}}</button>
            <button v-else class="btn btn--white"><s-loader :type="'scalify'" ></s-loader></button>
          </s-card-body>
        </s-card>
    </div>
</template>

<script>

export default {
  name: 'activity',

  components:{
  },

  props: {},

  computed:{
  },

  data(){
    return{
      searchAccount: "",
      hideShadows:false,
      scrollIn:"left",
      showSearch: false,
      loading: false,
      loadMoreActivities: false,
      loadingLoadMore: false,
      activitiesPage: 0,
      activitiesList: [],
    }
  },

  methods:{
    updateTableWidth(){
      this.$nextTick(()=>{
        var x = document.getElementById("myTable")
        if(x){
            var items = x.children[0].children
            var w = 0
            for(var i=0;i<items.length;i++){
                w = w + items[i].clientWidth
            }
            if(parseInt(w) < 1110){
                this.hideShadows = true
            }
            else{
                this.hideShadows = false
            }
            x.style.width=w+'px'
            this.$forceUpdate()
        }
      })
    },
    getScrollPos ({ target: { scrollLeft ,clientWidth, scrollWidth }}) {
      var scroll = "left"
      if(scrollLeft == 0){
          scroll = "left"
      }
      else if(scrollLeft+clientWidth < scrollWidth){
          scroll = "middle"
      }
      if(scrollLeft+clientWidth >= scrollWidth){
          scroll = "right"
      }
      this.scrollIn = scroll
    },
    async btnLoadMoreActivities(){
      try {

        this.loadingLoadMore = true;
        const activities = await this.getActivities(this.activitiesPage);
        if (activities && activities.data && activities.data.getActivities && activities.data.getActivities.length == 10) {
          this.loadMoreActivities = true;
          this.activitiesPage = this.activitiesPage + 1;
        }else{
          this.loadMoreActivities = false;
        }
        if (activities && activities.data && activities.data.getActivities) {
          this.activitiesList =  [...this.activitiesList, ...activities.data.getActivities]
        }

        this.loadingLoadMore = false;

      } catch (error) {
        console.log('error: ', error);
        this.loadingLoadMore = false;
      }

    }
  },

  async created(){

  },

  watch:{

  },

  async mounted(){

    try {

      this.loading = true
      const activities = await this.getActivities(this.activitiesPage);
      if (activities && activities.data && activities.data.getActivities && activities.data.getActivities.length == 10) {
        this.loadMoreActivities = true;
        this.activitiesPage = this.activitiesPage + 1;
      }
      if (activities && activities.data && activities.data.getActivities) {
        this.activitiesList = activities.data.getActivities;
      }
      this.loading = false

    } catch (error) {
      console.log('error: ', error);
      this.loading = false;
    }


  }


}
</script>

<style scoped>

</style>
