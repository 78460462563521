<template>
    <div id="reset-form">
        <!-- wrapper card -->
        <div class="reset-form__card">
            <!-- body (form) -->
            <form @submit.prevent="updatePassword" class="reset-form__card--body">
                <h1 class="reset-form__card--body-title mb-1">{{$t('reset_password.reset_password')}}</h1>
                <p class="reset-form__card--body-para mb-4 display-block">{{$t('reset_password.enter_password')}}</p>
                <s-input name="password" v-model="password" fieldFor="settings" :placeholder="$t('reset_password.placeholder_password')" type="password" class="reset-form__card--body__input mt-4"></s-input>
                <s-input name="confirm_password" v-model="confirmPassword" fieldFor="settings" :placeholder="$t('reset_password.placeholder_confirm_password')" type="password" class="reset-form__card--body__input mt-4"></s-input>
                <s-button v-if="!loading" class="btn btn--success btn--lg reset-form__card--body__button mt-3">{{$t('reset_password.reset_password')}}</s-button>
                <s-button v-else class="btn btn--success btn--lg reset-form__card--body__button mt-3 disabled">
                    <s-loader :isWhite="true"></s-loader>
                </s-button>
            </form>
        </div>
    </div>
</template>

<script>
export default {
  name: 'reset-form',
  props:{

  },
  components: {
  },
  watch:{

  },

  data(){
    return{
        password:'',
        confirmPassword:'',
        loading: false,
        tokenResetPassword: null
    };
  },
  mounted(){
    if (this.$route.query.at) {
        this.tokenResetPassword = this.$route.query.at;
    }
  },
  methods:{
    async updatePassword(){
        if (this.tokenResetPassword) {
            this.loading = true;
            if (this.validationSchema.validate({ password: this.password, confirm_password: this.confirmPassword}) && this.validationSchema.validate({ password: this.password, confirm_password: this.confirmPassword}).error) {
                if (this.validationSchema.validate({ password: this.password, confirm_password: this.confirmPassword}).error.details[0].context.key == "password") {
                    this.notify('error',this.$t('error.password_9_charactere'));
                }else if(this.validationSchema.validate({ password: this.password, confirm_password: this.confirmPassword}).error.details[0].context.key == "confirm_password") {
                    this.notify('error',this.$t('error.password_not_identical'));
                }
            }else{
                try{
                    const resultUpdatePassword = await this.receiveNewPassword(this.password, this.tokenResetPassword)
                    console.log('resultUpdatePassword: ', resultUpdatePassword);
                    if (resultUpdatePassword.data && resultUpdatePassword.data.receiveNewPassword) {
                        this.notify('success', resultUpdatePassword.data.receiveNewPassword);
                        this.$router.push('/login');
                    }
                }
                catch(err){
                    this.handleErrors(err)
                }
            }
            this.loading = false;
        }
    },

    signIn(){
        this.$router.push("login")
    },
    signUp(){
        this.$router.push("register")
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
