<template>
    <div id="default">

        <!-- Content -->
        <div id="content" >

          <!-- Navbar and sidebar   -->
          <NavbarSideBar ></NavbarSideBar>
          <!-- Body Content -->
          <div id="body">
            <slot />
          </div>
        </div>

    </div>
</template>

<script>

import NavbarSideBar from '../components/NavbarSidebarContainer.vue';

export default {

  name: 'DefaultLayout',
  components: {
    NavbarSideBar,
  },
  watch:{

  },
  data() {
    return {

    };
  },
  methods:{

  },
  computed:{

  },

  mounted(){

  }
};
</script>

