<template>
    <div id="about-you">
        <div class="about-you__container m-auto">
            <h1 class="about-you__container--title header">About You</h1>
            <p class="about-you__container--para">Tell us a little bit about you.</p>

            <p class="about-you__container--label">Please enter the URL for your website or social media accounts such as Instagram, YouTube, Twitter etc.</p>
            <div class="chaines-youtube display-table mb-3 w-100" v-for="(chaine,index) in listChainesYoutube" :key="index">
              <s-input :style="(listChainesYoutube.length > 1 && index != listChainesYoutube.length-1) || (listChainesYoutube.length == 1) ? 'width: calc(100% - 50px);' : 'width: calc(100% - 100px);'" v-model="chaine.name" fieldFor="settings" placeholder="youtube.com/username" class="about-you__container--input  display-table-cell" ></s-input>

              <button v-if="listChainesYoutube.length > 1" class="btn-remove btn btn--white display-table-cell" @click.stop="removeChaineYoutube(index)">
                <svg width="15" height="5" viewBox="0 0 15 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.9286 0.892822C5.80002 0.892822 9.5 0.8929 1.07143 0.892822C0.479799 0.892822 0 1.37262 0 1.96425V3.03568C0 3.62731 0.479799 4.10711 1.07143 4.10711C8.97309 4.10711 6.78787 4.10711 13.9286 4.10711C14.5202 4.10711 15 3.62731 15 3.03568V1.96425C15 1.37262 14.5202 0.892822 13.9286 0.892822Z" fill="#2B4D75"/>
                </svg>
              </button>

              <button v-if="listChainesYoutube.length-1 == index" class="btn-add btn btn--white display-table-cell" :class="listChainesYoutube.length == 1 ? 'ml-0':''" @click.stop="addChaineYoutube()">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.9286 5.89286H9.10714V1.07143C9.10714 0.479799 8.62734 0 8.03571 0H6.96429C6.37266 0 5.89286 0.479799 5.89286 1.07143V5.89286H1.07143C0.479799 5.89286 0 6.37266 0 6.96429V8.03571C0 8.62734 0.479799 9.10714 1.07143 9.10714H5.89286V13.9286C5.89286 14.5202 6.37266 15 6.96429 15H8.03571C8.62734 15 9.10714 14.5202 9.10714 13.9286V9.10714H13.9286C14.5202 9.10714 15 8.62734 15 8.03571V6.96429C15 6.37266 14.5202 5.89286 13.9286 5.89286Z" fill="#2B4D75"/>
                </svg>
              </button>
            </div>

            <p class="about-you__container--label">Average monthly traffic to your website or social media.</p>
            <s-input v-model="averageTraffic" fieldFor="settings" placeholder="100,000" class="about-you__container--input mb-3" ></s-input>

            <p class="about-you__container--label">Your PayPal email where you would like to receive your funds.</p>
            <s-input v-model="paypalEmail" type="email" name="email" placeholder="john@gmail.com" fieldFor="settings" class="about-you__container--input" ></s-input>


            <button @click="goToDashboard()" :class="canProceed?'':'disabled'" v-if="!loadingNext" class="about-you__container--btn btn btn--success btn--lg w-100">Submit</button>
            <!-- loading button -->
            <button v-else class="about-you__container--btn btn btn--success btn--lg w-100 disabled">
                <s-loader :isWhite="true"></s-loader>
            </button>

        </div>
    </div>
</template>

<script>

export default {
  name: 'about-you',
  props:{

  },

  components: {

  },
  watch:{

  },
  computed:{
    canProceed(){
        let create = false
        if(this.listChainesYoutube.length && this.paypalEmail.length && this.averageTraffic.length){
          create = true
        }
        return create
    }
  },
  created(){
  },
  mounted(){
  },
  data(){
    return{
      loadingNext:false,
      listChainesYoutube:[{name:""}],
      averageTraffic: "",
      paypalEmail: ""
    };
  },

  methods:{

    addChaineYoutube(){
      this.listChainesYoutube.push({name:""})
    },

    removeChaineYoutube(index){
      this.listChainesYoutube.splice(index,1);
    },

    async goToDashboard(){
        if(!this.listChainesYoutube.length || !this.paypalEmail.length || !this.averageTraffic.length){
            return
        }else{
          let urls = [];
          this.listChainesYoutube.map((chaine)=>{
            urls.push(chaine.name);
          })
          const data = { urls:urls, traffic: this.averageTraffic }

          this.loadingNext = true
          let save = await this.updateAffiliate(this.paypalEmail, null ,null, null, data,null);
          if(save){
            this.$emit("next",true)
          }
          this.loadingNext = false
        }
    },


  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
