<template>
    <div id="check-inbox-parent">
        <div id="check-inbox">
            <div class="check-inbox__container">
                <div>
                    <svg class="check-inbox__container--icon" width="89" height="68" viewBox="0 0 89 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M77.8715 0H10.8466C4.86353 0 0 4.86353 0 10.8466V56.6555C0 62.6385 4.86353 67.5021 10.8466 67.5021H77.8531C83.8362 67.5021 88.6997 62.6385 88.6997 56.6555V10.8649C88.718 4.88188 83.8545 0 77.8715 0ZM83.7627 56.6555C83.7627 59.904 81.1199 62.5468 77.8715 62.5468H10.8466C7.59811 62.5468 4.95529 59.904 4.95529 56.6555V10.8649C4.95529 7.61646 7.59811 4.97364 10.8466 4.97364H77.8531C81.1016 4.97364 83.7444 7.61646 83.7444 10.8649V56.6555H83.7627Z" fill="#59D07D"/>
                        <path d="M55.9397 33.1821L77.6329 13.728C78.6423 12.8103 78.734 11.2503 77.8164 10.2226C76.8987 9.21315 75.3387 9.12139 74.311 10.039L44.3957 36.8894L38.5595 31.6771C38.5411 31.6588 38.5228 31.6404 38.5228 31.6221C38.3943 31.4936 38.2658 31.3835 38.119 31.2734L14.3703 10.0207C13.3426 9.10303 11.7826 9.1948 10.8649 10.2226C9.94727 11.2503 10.039 12.8103 11.0668 13.728L33.0169 33.3472L11.1586 53.8108C10.1675 54.7468 10.1124 56.3068 11.0484 57.3162C11.544 57.83 12.2047 58.1053 12.8654 58.1053C13.471 58.1053 14.0767 57.8851 14.5539 57.4446L36.7425 36.6875L42.7623 42.0649C43.2395 42.487 43.8268 42.6889 44.4141 42.6889C45.0014 42.6889 45.607 42.4687 46.0658 42.0465L52.2508 36.504L74.311 57.463C74.7882 57.9218 75.4122 58.142 76.0178 58.142C76.6785 58.142 77.3209 57.8851 77.8164 57.3712C78.7524 56.3802 78.7157 54.8018 77.7246 53.8658L55.9397 33.1821Z" fill="#59D07D"/>
                    </svg><br>
                    <h1 class="check-inbox__container--title">{{$t('check_inbox.title')}}</h1><br>
                    <p class="check-inbox__container--para">{{$t('check_inbox.description')}}</p><br>
                </div>

            </div>
        </div>
        <Notes></Notes>
    </div>
</template>

<script>
import Notes from './Notes.vue'
export default {
  name: 'check-inbox',
  props:{

  },
  components: {
    Notes
  },
  watch:{

  },
  data(){
    return{

    };
  },
  methods:{

      }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
