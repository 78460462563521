<template>
    <div id="payouts">
      <div class="payouts__cards-header row no-gutters">
        <div class="payouts__cards--col col-12 col-md-4">
          <s-card class="mb-4">
            <s-card-body>
                <p class="text">{{$t('payouts.total_earned')}}</p>
                <h1 class="under-text">{{new Intl.NumberFormat('en-US', {style: 'currency',currency: 'USD',}).format(user.total_earned).split("$")[1]}} USD</h1>
            </s-card-body>
          </s-card>
        </div>
        <div class="payouts__cards--col col-12 col-md-4">
          <s-card class="mb-4">
            <s-card-body>
                <p class="text">{{$t('payouts.upcoming_payout')}}</p>
                <h1 class="under-text text-success">{{new Intl.NumberFormat('en-US', {style: 'currency',currency: 'USD',}).format(user.upcoming_payout).split("$")[1]}} USD</h1>
            </s-card-body>
          </s-card>
        </div>
        <div class="payouts__cards--col col-12 col-md-4">
          <s-card class="mb-4">
            <s-card-body>
                <button @click="btnWithdraw()" v-if="user.available_balance >= 100 && !user.is_requested" class="btn btn--primary" :class="loadingWithdraw?'disabled':''">
                  <span v-if="!loadingWithdraw">{{$t('payouts.withdraw_funds')}}</span>
                  <s-loader v-else :isWhite="true" ></s-loader>
                </button>
                <p v-else-if="user.available_balance < 100 " style="line-height: 165%;">{{$t('payouts.min_100_usd')}}</p>
                <p v-else-if="user.is_requested" style="line-height: 165%;">{{$t('payouts.we_have_receive_your_withdrawal')}}</p>
            </s-card-body>
          </s-card>
        </div>
      </div>
      <div class="payouts-table">
        <s-card class="payouts-table__card border-top-0 p-0">
          <s-card-body class="payouts-table__table p-0">
              <div class="payouts-table__table-container" @scroll="getScrollPos">
                  <div class="div-shadow-left" v-if="!hideShadows && scrollIn && scrollIn !== 'left' && payoutsList && payoutsList.length"></div>
                  <!-- payoutsList && payoutsList.length &&  -->
                  <table class="payouts-table__table--table"  id="myTable">
                      <thead class="payouts-table__table--header border-0 p-0 mb-4">
                          <!-- PAYOUT ID -->
                          <td class="text-truncate payouts-table__table--header-id">
                              <span>{{$t('payouts.payout_id')}}</span>
                          </td>
                          <!-- FROM -->
                          <td class="text-truncate payouts-table__table--header-from">
                              <span>{{$t('payouts.from')}}</span>
                          </td>
                          <!-- TO -->
                          <td class="text-truncate payouts-table__table--header-to">
                              <span>{{$t('payouts.to')}}</span>
                          </td>
                          <!-- AMOUNT -->
                          <td class="text-truncate payouts-table__table--header-amount">
                              <span>{{$t('payouts.amount')}}</span>
                          </td>
                          <!-- STATUS -->
                          <td class="text-truncate payouts-table__table--header-status">
                              <span>{{$t('payouts.status')}}</span>
                          </td>
                          <!-- PAID ON -->
                          <td class="text-truncate payouts-table__table--header-paid-on">
                              <span>{{$t('payouts.paid_on')}}</span>
                          </td>
                      </thead>
                      <tbody class="payouts-table__table--body">
                          <tr class="w-100 display-table-cell" v-if="loading">
                              <s-loader :type="'scalify'" class="my-3" style="left:calc(50% - 42px);position:relative;"></s-loader>
                          </tr>
                          <tr class="w-100 display-table-cell" v-if="!loading && (payoutsList == null || payoutsList.length == 0)">
                              <p class="my-3 position-relative font-weight-sbold" style="left:40%;">{{$t('globale_world.no_result')}}</p>
                          </tr>
                          <div v-if="payoutsList && payoutsList.length > 0">
                            <tr  v-show="!loading" v-for="(account, accountIndex) in payoutsList" :key="accountIndex" class="payouts-table__table--row w-100">
                                <!-- PAYOUT ID -->
                                <td class="display-table-cell payouts-table__table--body-id">
                                  <p  class="text-truncate">#{{account.id}}</p>
                                </td>
                                <!-- FROM -->
                                <td class="display-table-cell payouts-table__table--body-from">
                                  <p  class="text-truncate">{{account.from?formatDate(account.from):'-'}}</p>
                                </td>
                                <!-- TO -->
                                <td class="display-table-cell payouts-table__table--body-to">
                                    <p class="text-truncate">{{account.to?formatDate(account.to):'-'}}</p>
                                </td>
                                <!-- AMOUNT -->
                                <td class="display-table-cell payouts-table__table--body-amount">
                                    <p class="text-truncate">{{new Intl.NumberFormat('en-US', {style: 'currency',currency: 'USD',}).format(account.amount).split("$")[1]}} USD</p>
                                </td>
                                <!-- STATUS -->
                                <td class="display-table-cell payouts-table__table--body-status">
                                    <s-badge :class="account.status=='UPCOMING'?'badge--blue-dark':account.status=='PAID'?'badge--success':''" class="badge display-table-cell">{{account.status}}</s-badge>
                                </td>
                                <!-- PAID ON  -->
                                <td class="display-table-cell payouts-table__table--body-paid-on">
                                    <p class="text-truncate">{{account.paid_on?formatDate(account.paid_on):'-'}}</p>
                                </td>
                            </tr>
                          </div>

                      </tbody>
                  </table>
                  <div class="div-shadow-right" v-if="!hideShadows && scrollIn && scrollIn !== 'right' && ((payoutsList && payoutsList.length)||showSearch)"></div>
              </div>
          </s-card-body>
          <s-card-body v-if="payoutsList && payoutsList.length && !showSearch && loadMorePayouts && !loading" class="p-0 payouts-table__load-more text-center">
              <button v-if="!loadingLoadMore" class="btn btn--white" @click="btnLoadMorepayouts()">{{$t('globale_world.load_more')}}</button>
              <button v-else class="btn btn--white"><s-loader :type="'scalify'"></s-loader></button>

          </s-card-body>
        </s-card>
      </div>
    </div>
</template>

<script>

export default {
  name: 'payouts',


  props: {
    newPayout: {type: Object, Default: null}
  },

  watch:{
    newPayout:{
      deep:true,
      handler(){
        console.log('this.newPayout1: ', this.newPayout)
        if (this.newPayout) {
          console.log('this.newPayout2: ', this.newPayout)
          this.payoutsList.unshift(this.newPayout);
        }
      }
    }
  },

  data(){
    return{
      searchAccount: "",
      hideShadows:false,
      scrollIn:"left",
      showSearch: false,
      loading: false,
      loadMorePayouts: true,
      loadingLoadMore: false,
      payoutsPage: 0,
      loadingWithdraw: false,
      payoutsList: []
    }
  },

  methods:{
    updateTableWidth(){
      this.$nextTick(()=>{
        var x = document.getElementById("myTable")
        if(x){
            var items = x.children[0].children
            var w = 0
            for(var i=0;i<items.length;i++){
                w = w + items[i].clientWidth
            }
            if(parseInt(w) < 1110){
                this.hideShadows = true
            }
            else{
                this.hideShadows = false
            }
            x.style.width=w+'px'
            this.$forceUpdate()
        }
      })
    },
    getScrollPos ({ target: { scrollLeft ,clientWidth, scrollWidth }}) {
      var scroll = "left"
      if(scrollLeft == 0){
          scroll = "left"
      }
      else if(scrollLeft+clientWidth < scrollWidth){
          scroll = "middle"
      }
      if(scrollLeft+clientWidth >= scrollWidth){
          scroll = "right"
      }
      this.scrollIn = scroll
    },
    async btnLoadMorepayouts(){
      try {

        this.loadingLoadMore = true;
        const payouts = await this.getPayouts(this.payoutsPage);
        if (payouts && payouts.data && payouts.data.getPayouts && payouts.data.getPayouts.length == 10) {
          this.loadMorePayouts = true;
          this.payoutsPage = this.payoutsPage + 1;
        }else{
          this.loadMorePayouts = false;
        }
        if (payouts && payouts.data && payouts.data.getPayouts) {
          this.payoutsList =  [...this.payoutsList, ...payouts.data.getPayouts]
        }

        this.loadingLoadMore = false;

      } catch (error) {
        console.log('error: ', error);
        this.loadingLoadMore = false;
      }

    },
    async btnWithdraw(){
      this.loadingWithdraw = true;
      try {

        const withdraw = await this.withdraw();
        console.log('withdraw: ', withdraw);
        if (withdraw && withdraw.data && withdraw.data.withdraw) {
          withdraw.data.withdraw.amount = this.user.available_balance;
          this.payoutsList.unshift(withdraw.data.withdraw);
          // this.notify('success', this.$t(withdraw.data.withdraw));
          this.$store.state.user.upcoming_payout = this.user.upcoming_payout + this.user.available_balance;
          this.$store.state.user.is_requested = true;
          this.$store.state.user.available_balance = 0;
        }else{
          this.notify('error', withdraw.errors[0].message);
        }
        this.loadingWithdraw = false;
      } catch (error) {
        this.loadingWithdraw = false;
        console.log('error: ', error);
      }
    }
  },

  async created(){

  },

  async mounted(){
    try {

      this.loading = true
      const payouts = await this.getPayouts(this.payoutsPage);
      if (payouts && payouts.data && payouts.data.getPayouts && payouts.data.getPayouts.length == 10) {
        this.loadMorePayouts = true;
        this.payoutsPage = this.payoutsPage + 1;
      }else{
        this.loadMorePayouts = false;
      }
      if (payouts && payouts.data && payouts.data.getPayouts) {
        this.payoutsList = payouts.data.getPayouts;
      }
      this.loading = false

    } catch (error) {
      console.log('error: ', error);
      this.loading = false;
    }
  }


}
</script>

<style scoped>

</style>
