<template>

    <div id="content-no-sidebar">
      <!-- Probleme Connection With Fcaebook -->
        <slot />
    </div>
</template>

<script>

// Main layout components

export default {

  name: 'NoSidebarLayout',
  components: {
  },
  data() {
    return {

    };
  },

};
</script>

