<template>
    <div id="multiple-select" class="">
        <div class="select-dropdown h-100" v-clickaway="hideMenu">
            <div class="select-dropdown__button display-table" :class="color=='white'?'isWhite':''" @click="showMenu = !showMenu">
                <p class="m-0 display-table-cell select-dropdown__button__text">{{message}}</p>
                <svg class="select-dropdown__button__icon display-table-cell ml-3" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.594098 0.908325L8.22252 0.908325C8.75074 0.908325 9.01526 1.54697 8.64176 1.92047L4.82755 5.73468C4.59602 5.96621 4.2206 5.96621 3.98907 5.73468L0.174856 1.92047C-0.198674 1.54697 0.0658798 0.908325 0.594098 0.908325Z" fill="#fff"/>
                </svg>
            </div>
            <s-card class="select-dropdown__card flat" v-show="showMenu && listOptions.length">
                <s-list-group class="select-dropdown__card__list m-0">
                    <s-list-group-item :style="blockedIndex==key?{pointerEvents:'none',opacity:'0.7'}:{}" @click.native="selectOption(option)" :class="isSelected(option)?'active':''" class="select-dropdown__card__list__item" v-for="(option,key) in listOptions" :key="key">
                    <div class="display-table w-100">
                        <span class="mr-4 display-table-cell w-100">{{option.name}}</span>
                        <div v-if="isSelected(option)" class="display-table-cell text-right">
                            <svg class="" width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.75502 11.015L0.205017 6.46495C-0.0683389 6.1916 -0.0683389 5.74838 0.205017 5.475L1.19494 4.48505C1.4683 4.21166 1.91154 4.21166 2.1849 4.48505L5.24999 7.55012L11.8151 0.985046C12.0884 0.71169 12.5317 0.71169 12.805 0.985046L13.795 1.975C14.0683 2.24835 14.0683 2.69157 13.795 2.96495L5.74497 11.015C5.47159 11.2883 5.02837 11.2883 4.75502 11.015Z" fill="#2B4D75"/>
                            </svg>
                        </div>
                        
                    </div>
                    </s-list-group-item>
                </s-list-group>
            </s-card>
            <s-card class="select-dropdown__card flat" v-show="showMenu && !listOptions.length">
                <s-list-group class="select-dropdown__card__list m-0">
                    <s-list-group-item class="select-dropdown__card__list__item non-active text-center">
                        <span>No data found</span>
                    </s-list-group-item>
                </s-list-group>
            </s-card>
        </div>
    </div>
</template>

<script>

export default {
  name: 'multiple-select',
  props:{
    options:{type:Array,default(){return []}},
    value:{type:Array,default(){return []}},
    message:{type:String,default:""},
    color:{type:String,default:"primary"},
    isRefineBy:{type:Boolean,default:false}
  },
  components: {
  },
  data(){
    return {
        listOptions:this.options,
        selectedOptions:this.value,
        showMenu:false,
        blockedIndex:null
    }
  },
  watch:{
    options(){
        this.listOptions = this.options
        this.selectedOptions = []
    },
    selectedOptions(){
        this.$emit("input",this.selectedOptions)
        let freq = this.selectedOptions.findIndex(obj => obj.value == "Frequency")
        let TS = this.selectedOptions.findIndex(obj => obj.value == "Time Spent")
        let DV = this.selectedOptions.findIndex(obj => obj.value == "Device")
        if(freq !== -1){
            this.blockedIndex = 0
            this.selectedOptions[freq].data = {event:this.isEmpty(this.selectedOptions[freq].data.event)?this.frequencyOptions[0]:this.selectedOptions[freq].data.event,value:"10"}
            return
        }
        else if(TS !== -1){
            this.blockedIndex = 1
            this.selectedOptions[TS].data = {event:this.isEmpty(this.selectedOptions[TS].data.event)?this.timeSpentOptions[0]:this.selectedOptions[TS].data.event}
            return
        }
        else if(DV !== -1){
            // this.blockedIndex = null
            this.selectedOptions[DV].data = {event:this.isEmpty(this.selectedOptions[DV].data.event)?this.devicesOptions[0]:this.selectedOptions[DV].data.event}
            return
        }
        this.blockedIndex = null
    },
    value:{
        deep:true,
        handler(){
            this.selectedOptions = this.value
        }
    }
  },
  methods:{
    selectOption(option){
        if(this.isSelected(option)){
            var index = this.selectedOptions.findIndex(obj => obj===option)
            this.selectedOptions.splice(index,1)
        }
        else{
            this.selectedOptions.push(option)
        }
    },
    isSelected(option){
        var index = this.selectedOptions.findIndex(obj => obj===option)
        if(index !== -1){
            return true
        }
        return false
    },
    hideMenu(){
        this.showMenu=false
    }
  }
}
</script>

<style>

</style>
