<template>
<div class="w-100">

  <ul v-if="!hide" id="tab" class="tab" @scroll="onScroll">
    <li v-for="(tab,idx) in tabs" :key="idx" class="tab__item"  :class="[{'active': tab.isActive} , align=='left'?'w-auto':'' ]">
      <a class="tab__item__link text-truncate px-3" @click.stop="selectTab(tab)">
        <span class="display-table-cell">{{tab.name}}</span>
        <span class="number display-table-cell" v-if="number && idx==0">{{number}}</span>
      </a>
      <div class="tab__item__active-div"></div>
    </li>
  </ul>
  <div class="tab-body w-100" :class="[hide?'':'border-top',hideBorderTop?'border-top-0':'']">
    <div class="tab-body__pane fade">
      <slot></slot>
    </div>
  </div>
</div>

</template>

<script>


export default {
  name: 's-tabs',
  data(){
    return {
      tabs:[],
      currentTab:0
    }
  },
  mounted(){
    // var elem = document.getElementById('tab')
    // if (elem.scrollHeight > elem.clientHeight){
    //   this.$emit('scrollIn','left')
    // }
    // else{
      this.$emit('scrollIn',"left")
    // }
  },
  updated(){

  },
  created(){
    this.tabs = this.$children;
  },
  props: {
    align:{
      type:String,
      default:""
    },
    navigable:{type:Boolean,default:true},
    hide:{type:Boolean,default:false},
    hideBorderTop:{type:Boolean,default:false},
    number:{type:Number,default:0}
  },
  methods:{
    change(){
    },
    onScroll ({ target: { scrollLeft ,clientWidth, scrollWidth }}) {
      var scroll = "left"
      if(scrollLeft == 0){
        scroll = "left"
      }
      else if(scrollLeft+clientWidth < scrollWidth){
        scroll = "middle"
      }
      if(scrollLeft+clientWidth >= scrollWidth){
        scroll = "right"
      }
      this.$emit("scrollIn",scroll)
    },
    selectTab(selectedTab) {
     if(this.navigable){
        this.tabs.forEach(tab => {
          tab.isActive = (tab == selectedTab);
        });
        selectedTab.isActive=true
        this.currentTab=this.tabs.indexOf(selectedTab)
        this.$emit("currentTab",this.tabs[this.currentTab].name)
        this.$emit("currentTabIndex",this.currentTab)
     }
    },
    nextTab(){
      if(this.currentTab<this.tabs.length-1){
        this.selectTab(this.tabs[this.currentTab + 1])
      }
    },
    previousTab(){
      if(this.currentTab>0){
        this.selectTab(this.tabs[this.currentTab-1])
      }
    },
    goToTab(name){
      console.log('name: ', name);
      let index = this.tabs.findIndex(obj => obj.name.toLowerCase().includes(name.toLowerCase()))
      if(index !== -1){
        this.selectTab(this.tabs[index])
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
