<template>
    <div id="forgot">
        <!-- wrapper card -->
        <div class="forgot__card">
            <!-- body (form) -->
            <div class="forgot__card--body">
                <form @submit.prevent="resetMyPassword" >
                    <h1 class="forgot__card--body-title">{{$t('forgot_password.reset_password')}}</h1>
                    <p class="forgot__card--body-para display-block">{{$t('forgot_password.enter_your_email')}}</p>
                    <s-input name="email" v-model="email" fieldFor="settings" :placeholder="$t('forgot_password.placeholder_email')" class="forgot__card--body__input mt-4"></s-input>
                    <s-button v-if="!loading" class="btn btn--success btn--lg forgot__card--body__button mt-3" >{{$t('forgot_password.reset_password')}}</s-button>
                    <s-button v-else class="btn btn--success btn--lg forgot__card--body__button disabled mt-3">
                        <s-loader :isWhite="true"></s-loader>
                    </s-button>
                </form>
                <!-- loading button -->


                <!-- error alert -->
                <div class="sign-up__card--body__alert mt-3" v-if="messageErreur.length">{{messageErreur}}</div>

            </div>
        </div>
        <!-- footer card (login or register) -->
        <div class="forgot__card mt-4">
            <div class="forgot__card--body">
                <p class="forgot__card--body__login-signup m-0 display-block">{{$t('forgot_password.back_to')}}<span @click.stop="signIn" class="forgot__card--body__login"> {{$t('forgot_password.log_in')}} </span></p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'forgot',
  props:{

  },
  components: {
  },
  watch:{

  },
  data(){
    return{
        email: '',
        loading: false,
        messageErreur: '',
    };
  },
  methods:{
    async resetMyPassword(){
        this.loading=true;
        try{
            const resultResetPassword = await this.resetPassword(this.email);
            console.log('resultResetPassword: ', resultResetPassword);
            if (resultResetPassword.data.resetPassword) {
                this.notify('success', resultResetPassword.data.resetPassword);
                this.$router.push('/login');
            }else{
                this.messageErreur = resultResetPassword.errors[0].message;
            }
            this.loading=false;
        }
        catch(err){
            this.loading = false
            this.handleErrors(err)
        }
    },

    signIn(){
        this.$router.push("login")
    },
    signUp(){
        this.$router.push("register")
    }

  },
  mounted(){
      if(this.$route.params.email){
        this.email = this.$route.params.email
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
