<template>
    <button @click="handleClick" class="btn">
    <slot />
    </button>
</template>

<script>



export default {
  name: 's-button',
  props: {
    msg: String
  },
  methods:{
    handleClick(e) {
      this.$emit('click', e);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
