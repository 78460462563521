<template>
    <div class="text-area">
        <textarea :placeholder="placeholder" ref="textArea" :rows="rows" class="text-area__field" v-model="inputValue" @click="sendCursorPos" @keyup="sendCursorPos">
        </textarea>
        <svg v-if="showClear" @click="clearField" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.27454 6.33521L11.686 2.92373C12.1047 2.50509 12.1047 1.82634 11.686 1.40736L10.9278 0.649182C10.5092 0.230546 9.83045 0.230546 9.41148 0.649182L6 4.06066L2.58852 0.649182C2.16989 0.230546 1.49114 0.230546 1.07216 0.649182L0.313977 1.40736C-0.104659 1.826 -0.104659 2.50475 0.313977 2.92373L3.72545 6.33521L0.313977 9.74668C-0.104659 10.1653 -0.104659 10.8441 0.313977 11.263L1.07216 12.0212C1.4908 12.4399 2.16989 12.4399 2.58852 12.0212L6 8.60975L9.41148 12.0212C9.83011 12.4399 10.5092 12.4399 10.9278 12.0212L11.686 11.263C12.1047 10.8444 12.1047 10.1657 11.686 9.74668L8.27454 6.33521Z" fill="#9FB3CA"/>
        </svg>
    </div>
</template>

<script>



export default {
  name: 's-text-area',
  props: {
      rows:{type:String,default:"6"},
      value:{ type:String, default:"" },
      showClear:{type:Boolean,default:true},
      placeholder:{type:String,default:""},
      index:{type:[Number,String],default:null},
  },
  data(){
    return{
        inputValue:this.value
    }
  },
  watch: {
    inputValue(val){
        this.$emit("input",val)
    },
    value(){
      this.inputValue=this.value
    }
  },
  methods:{
    clearField(){
      this.$emit("delete",this.index)
    },
    sendCursorPos(){
      var textarea = this.$refs.textArea
      var pos={}
      pos.start=textarea.selectionStart
      pos.end=textarea.selectionEnd
      this.$emit("cursor",pos)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
