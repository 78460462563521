<template>
    <ul class="list-group">
      <slot />
    </ul>
</template>

<script>



export default {
  name: 's-list-group',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
