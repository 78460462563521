<template>
    <div id="settings">

      <div class="settings__row row no-gutters">

        <!-- Paypal Account -->
        <div class="settings__col col-12 col-md-6 ">
          <s-card class="">
            <s-card-header >
              <h3 class="card-title">{{$t('settings.you_paypal_account.title')}}</h3>
            </s-card-header>
            <s-card-body v-if="updatePaypal">
              <p class="card-text mb-4">{{$t('settings.you_paypal_account.description')}}</p>
              <div class="form-row row no-gutters mb-3">
                <div class="form-col col-5">
                  <p class="form-col-text">{{$t('settings.you_paypal_account.paypal_email')}}</p>
                </div>
                <div class="form-col col-7">
                  <s-input v-model="email" fieldFor='settings' type="email" name="email" :placeholder="$t('settings.you_paypal_account.placeholder_paypal_email')"></s-input>
                </div>
              </div>
              <div class="form-row row no-gutters mb-3">
                <div class="form-col col-5">
                  <p class="form-col-text">{{$t('settings.you_paypal_account.paypal_email_confirmation')}}</p>
                </div>
                <div class="form-col col-7">
                  <s-input v-model="confirmEmail" fieldFor='settings' type="email" name="email" :placeholder="$t('settings.you_paypal_account.placeholder_paypal_email')"></s-input>
                </div>
              </div>
              <div class="form-row row no-gutters">
                <div class="form-col col-5">
                  <button @click="updatePaypal = false" class="btn btn--white w-100">{{$t('globale_world.cancel')}}</button>
                </div>
                <div class="form-col col-7">
                  <button v-if="!loading.paypalEmail" @click="updateAff(email, null, null)" class="btn btn--primary w-100">{{$t('settings.you_paypal_account.update_paypal_email')}}</button>
                  <button v-else class="btn btn--primary w-100 disabled">
                    <s-loader :isWhite="true"></s-loader>
                  </button>
                </div>
              </div>
            </s-card-body>
            <s-card-body v-else class="current-paypal-account">
              <div class="form-row row no-gutters mb-3">
                <div class="form-col col-5">
                  <p class="form-col-text">{{$t('settings.you_paypal_account.current_email')}}</p>
                </div>
                <div class="form-col col-7">
                  <p class="form-col-text">{{user.paypal_email}}</p>
                </div>
              </div>
              <div class="form-row row no-gutters">
                <div class="form-col col-5"></div>
                <div class="form-col col-7">
                  <p class="form-col-text cursor-pointer text-primary font-weight-sbold" style="border-bottom: 1px solid #0E7DFF;" @click="updatePaypal = true">{{$t('settings.you_paypal_account.change_account')}}</p>
                </div>
              </div>
            </s-card-body>
          </s-card>
        </div>

        <!-- Change Password -->
        <div class="settings__col col-12 col-md-6 ">
          <s-card class="">
            <s-card-header class="">
              <h3 class="card-title">{{$t('settings.change_password.title')}}</h3>
            </s-card-header>
            <s-card-body>
              <p class="card-text mb-4">{{$t('settings.change_password.description')}}</p>
              <div class="form-row row no-gutters mb-3">
                <div class="form-col col-5">
                  <p class="form-col-text">{{$t('settings.change_password.new_password')}}</p>
                </div>
                <div class="form-col col-7">
                  <s-input v-model="newPass" fieldFor='settings' type="password" name="password" :placeholder="$t('settings.change_password.placeholder_password')"></s-input>
                </div>
              </div>
              <div class="form-row row no-gutters mb-3">
                <div class="form-col col-5">
                  <p class="form-col-text">{{$t('settings.change_password.confirm_your_new_password')}}</p>
                </div>
                <div class="form-col col-7">
                  <s-input v-model="confirmNewPass" fieldFor='settings' type="password" name="password" :placeholder="$t('settings.change_password.placeholder_password')"></s-input>
                </div>
              </div>
              <div class="form-row row no-gutters">
                <div class="form-col col-5"></div>
                <div class="form-col col-7">
                  <button v-if="!loading.password" @click="updateAff(null, null, newPass)" class="btn btn--primary w-100">{{$t('settings.change_password.update_password')}}</button>
                  <button v-else class="btn btn--primary w-100 disabled">
                    <s-loader :isWhite="true"></s-loader>
                  </button>
                </div>
              </div>
            </s-card-body>
          </s-card>
        </div>

        <!-- Change Launguages -->
        <!-- <div class="settings__col col-12 col-md-6 ">
          <s-card class="">
            <s-card-header class="">
              <h3 class="card-title">{{$t('settings.change_language.title')}}</h3>
            </s-card-header>
            <s-card-body>
              <p class="card-text mb-4">{{$t('settings.change_language.description')}}</p>
              <div class="form-row row no-gutters mb-3">
                <div class="form-col col-5">
                  <p class="form-col-text">{{$t('settings.change_language.language')}}</p>
                </div>
                <div class="form-col col-7">
                  <s-dropdown v-model="languageSelected" display="select" :options="listLanguages"></s-dropdown>
                </div>
              </div>
              <div class="form-row row no-gutters">
                <div class="form-col col-5"></div>
                <div class="form-col col-7">
                  <button v-if="!loading.language" @click="updateAff(null, languageSelected.value, null)" class="btn btn--primary w-100">{{$t('settings.change_language.update_language')}}</button>
                  <button v-else class="btn btn--primary w-100 disabled">
                    <s-loader :isWhite="true"></s-loader>
                  </button>
                </div>
              </div>
            </s-card-body>
          </s-card>
        </div> -->

      </div>
    </div>
</template>

<script>

import moment from 'moment';

export default {
  name: 'settings',

  components:{
  },

  props: {},

  computed:{
  },

  data(){
    return{
      email: "",
      confirmEmail: "",
      newPass: "",
      confirmNewPass: "",
      loading:{
        paypalEmail:false,
        password:false,
        language: false
      },
      languageSelected: {name:this.$t('language.english'),value:'en'},
      listLanguages: [{name:this.$t('language.english'),value:'en'},{name:this.$t('language.frensh'),value:'fr'}],
      updatePaypal: false

    }
  },

  methods:{
    async updateAff(paypalEmail, lang, password){
      if (password && this.validationSchema.validate({ password: this.newPass, confirm_password: this.confirmNewPass}) && this.validationSchema.validate({ password: this.newPass, confirm_password: this.confirmNewPass}).error) {

        if (this.validationSchema.validate({ password: this.newPass, confirm_password: this.confirmNewPass}).error.details[0].context.key == "password") {
          this.notify('error',this.$t('error.password_9_charactere'));
        }else if(this.validationSchema.validate({ password: this.newPass, confirm_password: this.confirmNewPass}).error.details[0].context.key == "confirm_password") {
          this.notify('error',this.$t('error.password_not_identical'));
        }

      }else if (paypalEmail && this.validationSchema.validate({ email: this.email, confirm_email: this.confirmEmail}) && this.validationSchema.validate({ email: this.email, confirm_email: this.confirmEmail}).error) {

        if (this.validationSchema.validate({ email: this.email, confirm_email: this.confirmEmail}).error.details[0].context.key == "email") {
          this.notify('error',this.$t('error.valid_email'));
        }else if(this.validationSchema.validate({ email: this.email, confirm_email: this.confirmEmail}).error.details[0].context.key == "confirm_email") {
          this.notify('error',this.$t('error.email_not_identical'));
        }

      }else if (lang && lang == this.user.language) {

        this.notify('error',this.$t('error.already_use_lang'));

      }else{

        this.loading[paypalEmail?'paypalEmail':password?'password':'language'] = true;
        try{

          const editAffiliate = await this.updateAffiliate(paypalEmail, null, password, null,null,lang);

          if (editAffiliate && editAffiliate.data && editAffiliate.data.updateAffiliate) {

            this.notify('success', this.$t(editAffiliate.data.updateAffiliate));

          }else{

            this.notify('error', editAffiliate.errors[0].message);

          }

          this.loading[paypalEmail?'paypalEmail':password?'password':'language'] = false

        }
        catch(err){

          this.loading[paypalEmail?'paypalEmail':password?'password':'language'] = false
          this.handleErrors(err)
        }

      }

    }
  },

  async created(){

  },

  watch:{

  },

  async mounted(){

    if (this.user && this.user.language) {

      this.listLanguages.map((lang)=>{
        if (lang.value == this.user.language) {
          this.languageSelected = lang;

        }
      })
      this.$i18n.locale = this.languageSelected.value;
      moment.locale(this.$i18n.locale);
    }

  }


}
</script>

<style scoped>

</style>
