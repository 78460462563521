import Vue from 'vue';

import Card from './Card.vue'
import CardBody from './CardBody.vue'
import CardFooter from './CardFooter.vue'
import CardHeader from './CardHeader.vue'
Vue.component('s-card', Card);
Vue.component('s-card-header', CardHeader);
Vue.component('s-card-body', CardBody);
Vue.component('s-card-footer', CardFooter);

export default{
    Card,
    CardHeader,
    CardBody,
    CardFooter
}

