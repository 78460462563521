<template>
    <nav id="navbar" class="container" >
        <div class="display-table w-100 navbar__container">

            <!-- Scalify Logo -->
            <div class="navbar__logo display-table-cell">
                <img class="name" src="../assets/images/logo-full.svg"/>
                <img class="logo" src="../assets/images/logo-S.svg"/>
            </div>
            <!-- Title Page If LG or Less -->
            <div class="navbar__title display-table">
                <svg class="navbar__title-icone h-100 display-table-cell" width="27" height="30" viewBox="0 0 27 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.625 18.75C19.3003 18.75 18.0828 19.2081 17.1217 19.9743L11.1166 16.2212C11.2945 15.4167 11.2945 14.5832 11.1166 13.7788L17.1217 10.0256C18.0828 10.7919 19.3003 11.25 20.625 11.25C23.7316 11.25 26.25 8.73158 26.25 5.625C26.25 2.51842 23.7316 0 20.625 0C17.5184 0 15 2.51842 15 5.625C15 6.04441 15.0463 6.45293 15.1334 6.84615L9.12832 10.5993C8.16721 9.83315 6.94969 9.375 5.625 9.375C2.51842 9.375 0 11.8934 0 15C0 18.1066 2.51842 20.625 5.625 20.625C6.94969 20.625 8.16721 20.1669 9.12832 19.4007L15.1334 23.1538C15.0446 23.5548 14.9999 23.9643 15 24.375C15 27.4816 17.5184 30 20.625 30C23.7316 30 26.25 27.4816 26.25 24.375C26.25 21.2684 23.7316 18.75 20.625 18.75Z" fill="#2B4D75"/>
                </svg>

                <h1 class="navbar__title--text m-0 display-table-cell">{{$t('dashboard.title')}}</h1>
            </div>
            <!-- User Accounts -->
            <div class="navbar__accounts display-table display-table-cell mr-md user-select-none position-relative">
                <div @click.stop="dropdownAccount = !dropdownAccount" class="display-table cursor-pointer" style="position: absolute; top: 50%; right: 0; transform: translateY(-50%);">
                    <img class="user-picture display-table-cell" width="25" height="25" :src="'https://www.gravatar.com/avatar/'+md5(this.user.email)+'?s=100'" alt="">
                    <span class="px-2 display-table-cell text-truncate">{{user.name}}</span>
                    <svg class="display-table-cell flex-shrink-0" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.237535 0.684322L0.718834 0.22601C0.885621 0.0753511 1.08042 0 1.30278 0C1.52973 0 1.72221 0.0753511 1.88054 0.22601L4.99998 3.19558L8.11939 0.226096C8.2777 0.0754366 8.47023 8.5718e-05 8.69709 8.5718e-05C8.91956 8.5718e-05 9.11431 0.0754366 9.28112 0.226096L9.7561 0.684408C9.91873 0.839112 10 1.0245 10 1.24041C10 1.46035 9.91864 1.64364 9.75612 1.79025L5.5777 5.76785C5.42359 5.92257 5.23113 6 5 6C4.77321 6 4.57848 5.9226 4.41601 5.76785L0.237557 1.79025C0.0792236 1.63951 0 1.45624 0 1.24041C-2.19345e-05 1.02853 0.0792007 0.843242 0.237535 0.684322Z" fill="#52749C"/></svg>
                </div>
                <s-card v-if="dropdownAccount" class="navbar__accounts--dropdown overflow-hidden" v-clickaway="hideDropdown">
                    <s-card-body class="p-0">
                        <li class="overflow-hidden" style="list-style: none;" @click="goSettings()">
                            <div class="display-table  w-100">
                                <div class="navbar__accounts--dropdown-left"><svg class="display-table-cell h-100" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.8212 8.68225L12.5651 7.9879C12.6919 7.33306 12.6919 6.66129 12.5651 6.00646L13.8212 5.3121C13.9657 5.23307 14.0305 5.06936 13.9833 4.91694C13.6561 3.91211 13.0988 3.00324 12.3705 2.24679C12.2585 2.13107 12.0757 2.10284 11.9342 2.18187L10.6781 2.87623C10.1503 2.44155 9.54294 2.10566 8.88543 1.8855V0.499621C8.88543 0.341557 8.77044 0.203251 8.60827 0.169381C7.52618 -0.0620701 6.41755 -0.0507798 5.38853 0.169381C5.22636 0.203251 5.11137 0.341557 5.11137 0.499621V1.88833C4.45681 2.11131 3.84942 2.44719 3.31869 2.87905L2.06559 2.1847C1.92112 2.10566 1.74126 2.13107 1.62922 2.24961C0.900941 3.00324 0.343678 3.91211 0.0163971 4.91977C-0.0337271 5.07218 0.034088 5.23589 0.178563 5.31493L1.43462 6.00928C1.30783 6.66411 1.30783 7.33589 1.43462 7.99072L0.178563 8.68507C0.034088 8.76411 -0.0307786 8.92781 0.0163971 9.08023C0.343678 10.0851 0.900941 10.9939 1.62922 11.7504C1.74126 11.8661 1.92406 11.8943 2.06559 11.8153L3.32164 11.121C3.84942 11.5556 4.45681 11.8915 5.11432 12.1117V13.5004C5.11432 13.6584 5.22931 13.7967 5.39148 13.8306C6.47357 14.0621 7.5822 14.0508 8.61122 13.8306C8.77338 13.7967 8.88837 13.6584 8.88837 13.5004V12.1117C9.54294 11.8887 10.1503 11.5528 10.681 11.121L11.9371 11.8153C12.0816 11.8943 12.2614 11.8689 12.3735 11.7504C13.1018 10.9968 13.659 10.0879 13.9863 9.08023C14.0305 8.92499 13.9657 8.76128 13.8212 8.68225ZM6.9984 9.25523C5.69812 9.25523 4.63961 8.24193 4.63961 6.99718C4.63961 5.75242 5.69812 4.73912 6.9984 4.73912C8.29868 4.73912 9.35718 5.75242 9.35718 6.99718C9.35718 8.24193 8.29868 9.25523 6.9984 9.25523Z" fill="#7796B9"/></svg></div>
                                <div class="text-left display-table-cell pl-2 navbar__accounts--dropdown-text"><span>{{$t('navbar.settings')}}</span></div>
                            </div>
                        </li>
                        <li class="overflow-hidden border-top" style="list-style: none;" @click="logout()">
                            <div class="display-table  w-100">
                                <div class="navbar__accounts--dropdown-left"><svg class="display-table-cell" width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.7924 6.22118L9.79215 11.2214C9.3457 11.6679 8.57185 11.3554 8.57185 10.7154V7.85817H4.52403C4.12818 7.85817 3.80971 7.5397 3.80971 7.14384V4.28656C3.80971 3.89071 4.12818 3.57224 4.52403 3.57224H8.57185V0.714954C8.57185 0.0780178 9.34272 -0.237474 9.79215 0.208977L14.7924 5.20922C15.0692 5.489 15.0692 5.9414 14.7924 6.22118ZM5.71457 11.0726V9.88208C5.71457 9.68564 5.55385 9.52491 5.35741 9.52491H2.85728C2.33047 9.52491 1.90486 9.0993 1.90486 8.57249V2.85792C1.90486 2.33111 2.33047 1.90549 2.85728 1.90549H5.35741C5.55385 1.90549 5.71457 1.74477 5.71457 1.54833V0.357794C5.71457 0.161355 5.55385 0.000633025 5.35741 0.000633025H2.85728C1.27983 0.000633025 0 1.28046 0 2.85792V8.57249C0 10.1499 1.27983 11.4298 2.85728 11.4298H5.35741C5.55385 11.4298 5.71457 11.269 5.71457 11.0726Z" fill="#7795B9"/></svg></div>
                                <div class="text-left display-table-cell pl-2 navbar__accounts--dropdown-text"><span>{{$t('navbar.logout')}}</span></div>
                            </div>
                        </li>
                    </s-card-body>
                </s-card>
            </div>
            <div class="navbar__accounts--menu-hamburger display-table-cell">
                <svg @click="menuHam = true" width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="display-inline-block text-right navbar-sidebar__sticky--burger display-table-cell"><rect width="25" height="4" rx="2" fill="#2B4D75"></rect><rect y="8" width="25" height="4" rx="2" fill="#2B4D75"></rect><rect y="16" width="25" height="4" rx="2" fill="#2B4D75"></rect></svg>
            </div>
            <s-card v-if="menuHam" class="card-menu">
                <s-card-body class="px-0">
                    <div class="header px-2">

                        <img class="logo" src="../assets/images/logo-S.svg"/>

                        <svg class="close" @click="menuHam = false" data-v-7d686094="" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg"><rect data-v-7d686094="" x="2.82843" y="6.10352e-05" width="25" height="4" rx="2" transform="rotate(45 2.82843 6.10352e-05)" fill="#2B4D75"></rect><rect data-v-7d686094="" x="6.10352e-05" y="17.6777" width="25" height="4" rx="2" transform="rotate(-45 6.10352e-05 17.6777)" fill="#2B4D75"></rect></svg>

                    </div>
                    <div class="body">

                        <div class="section user-profile">
                            <img class="user-picture display-table-cell" width="25" height="25" :src="'https://www.gravatar.com/avatar/'+md5(this.user.email)+'?s=100'" alt="">
                            <span class="pl-2 display-table-cell text-truncate">{{user.name}}</span>
                        </div>
                        <div class="section" @click="goSettings()">
                            <svg class="display-table-cell h-100" width="15" height="15" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.8212 8.68225L12.5651 7.9879C12.6919 7.33306 12.6919 6.66129 12.5651 6.00646L13.8212 5.3121C13.9657 5.23307 14.0305 5.06936 13.9833 4.91694C13.6561 3.91211 13.0988 3.00324 12.3705 2.24679C12.2585 2.13107 12.0757 2.10284 11.9342 2.18187L10.6781 2.87623C10.1503 2.44155 9.54294 2.10566 8.88543 1.8855V0.499621C8.88543 0.341557 8.77044 0.203251 8.60827 0.169381C7.52618 -0.0620701 6.41755 -0.0507798 5.38853 0.169381C5.22636 0.203251 5.11137 0.341557 5.11137 0.499621V1.88833C4.45681 2.11131 3.84942 2.44719 3.31869 2.87905L2.06559 2.1847C1.92112 2.10566 1.74126 2.13107 1.62922 2.24961C0.900941 3.00324 0.343678 3.91211 0.0163971 4.91977C-0.0337271 5.07218 0.034088 5.23589 0.178563 5.31493L1.43462 6.00928C1.30783 6.66411 1.30783 7.33589 1.43462 7.99072L0.178563 8.68507C0.034088 8.76411 -0.0307786 8.92781 0.0163971 9.08023C0.343678 10.0851 0.900941 10.9939 1.62922 11.7504C1.74126 11.8661 1.92406 11.8943 2.06559 11.8153L3.32164 11.121C3.84942 11.5556 4.45681 11.8915 5.11432 12.1117V13.5004C5.11432 13.6584 5.22931 13.7967 5.39148 13.8306C6.47357 14.0621 7.5822 14.0508 8.61122 13.8306C8.77338 13.7967 8.88837 13.6584 8.88837 13.5004V12.1117C9.54294 11.8887 10.1503 11.5528 10.681 11.121L11.9371 11.8153C12.0816 11.8943 12.2614 11.8689 12.3735 11.7504C13.1018 10.9968 13.659 10.0879 13.9863 9.08023C14.0305 8.92499 13.9657 8.76128 13.8212 8.68225ZM6.9984 9.25523C5.69812 9.25523 4.63961 8.24193 4.63961 6.99718C4.63961 5.75242 5.69812 4.73912 6.9984 4.73912C8.29868 4.73912 9.35718 5.75242 9.35718 6.99718C9.35718 8.24193 8.29868 9.25523 6.9984 9.25523Z" fill="#7796B9"/></svg>
                            <span class="pl-2 display-table-cell text-truncate">{{$t('navbar.settings')}}</span>
                        </div>
                        <div class="section" @click="logout()">
                            <svg class="display-table-cell" width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.7924 6.22118L9.79215 11.2214C9.3457 11.6679 8.57185 11.3554 8.57185 10.7154V7.85817H4.52403C4.12818 7.85817 3.80971 7.5397 3.80971 7.14384V4.28656C3.80971 3.89071 4.12818 3.57224 4.52403 3.57224H8.57185V0.714954C8.57185 0.0780178 9.34272 -0.237474 9.79215 0.208977L14.7924 5.20922C15.0692 5.489 15.0692 5.9414 14.7924 6.22118ZM5.71457 11.0726V9.88208C5.71457 9.68564 5.55385 9.52491 5.35741 9.52491H2.85728C2.33047 9.52491 1.90486 9.0993 1.90486 8.57249V2.85792C1.90486 2.33111 2.33047 1.90549 2.85728 1.90549H5.35741C5.55385 1.90549 5.71457 1.74477 5.71457 1.54833V0.357794C5.71457 0.161355 5.55385 0.000633025 5.35741 0.000633025H2.85728C1.27983 0.000633025 0 1.28046 0 2.85792V8.57249C0 10.1499 1.27983 11.4298 2.85728 11.4298H5.35741C5.55385 11.4298 5.71457 11.269 5.71457 11.0726Z" fill="#7795B9"/></svg>
                            <span class="pl-2 display-table-cell text-truncate">{{$t('navbar.logout')}}</span>
                        </div>

                    </div>
                </s-card-body>
            </s-card>

        </div>

    </nav>

</template>

<script>


export default {
  name: 'NavbarNav',
  props: {
  },
  watch:{

  },
  computed:{

  },
  data(){
    return{
        dropdownAccount:false,
        menuHam: false
    };
  },

  async mounted(){

  },
  updated(){
  },
  methods:{

    goSettings(){
        this.$store.state.dashboardStep = 'settings';
        this.dropdownAccount = false;
    },

    logout(){
        this.$store.state.user = null;
        this.$Cookies.remove('X-Scalify-Token');
        this.$router.push({name:'login'});
    },
    hideDropdown(){
        this.dropdownAccount = false;
    }

  }


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
