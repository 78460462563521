import Vue from 'vue';

import Loader from './Loader.vue'

Vue.component('s-loader', Loader);

export default{
    Loader, 
}


