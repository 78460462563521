import Vue from 'vue';

import Badge from './Badge.vue'

Vue.component('s-badge',Badge);


export default{
    Badge,
}

