<template>
    <div id="navbar-sidebar">

      <!-- navbar & sidebar Components -->
      <div class="navbar-sidebar__items">
        <NavbarNav ></NavbarNav>
        <!-- <Sidebar></Sidebar> -->
      </div>
    </div>
</template>

<script>

// import Sidebar from './Sidebar.vue';
import NavbarNav from './NavbarNav.vue';
export default {

  name: 'navbar-sidebar',
  components: {
    // Sidebar,
    NavbarNav
  },
  watch:{

  },
  data() {
    return {

    };
  },
  computed:{

  },
  methods:{
  },
  destroyed(){
  },
  mounted(){
  }
};
</script>

