<template>
  <div id="app" ref="app">

    <div v-if="requireUser && !userLoaded" class="center-screen" style="background:white;">
      <s-loader :type="'scalify'"></s-loader>
    </div>
    <component v-else :is="layout">
      <router-view :key="$route.fullPath" />
      <!-- Toast -->
      <div class="toast-container">
        <div v-for="(toast, indexToast) in this.$store.state.toasts" :key="indexToast">
          <s-toast :id="toast.id" :btn="toast.btn" :state="toast.state" :title="toast.title" :text="toast.text"></s-toast>
        </div>
      </div>
    </component>

  </div>
</template>

<script>

const default_layout = "default";
const moment = require("moment");
export default {

  name: 'app',
  components: {
  },
  data(){
    return {
      userLoaded: false
    }
  },
  methods:{

  },
  computed:{
    requireUser() {
      var routesThatDontRequireUser = [
        "/login",
        "/forgot-password",
        "/register",
        "/reset-password",
        "/check-inbox",
        "/complete-subscribe"
      ];
      return routesThatDontRequireUser.indexOf(this.$route.path) == -1;
    },
    layout() {
      return `${this.$route.meta.layout || default_layout}-layout`;
    }
  },

  watch: {

    async user(){
      if (this.user != null) {
        if (this.user && !this.user.approved) {
          this.$router.push('/complete-subscribe')
        }
        this.userLoaded = true;
        this.$i18n.locale = this.user.language;
        moment.locale(this.$i18n.locale);
      }
    },
    async requireUser(){
      if (this.requireUser && !this.user) {
        this.checkToken();
        if (this.user == null) {
          this.$store.state.user = await this.userData();
        }
      }
    },

  },

  async created(){
    if (this.requireUser) {
      const token = await this.checkToken();
      if (this.user == null && token) {
        this.$store.state.user = await this.userData();
      }
    }
  },
  async mounted(){

  }

}
</script>
<style lang="scss">

</style>
