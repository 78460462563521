import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import Cookies from 'js-cookie';
import functions from './helpers/functions'
// import VJsoneditor from 'v-jsoneditor/src/index'
import joi from '@hapi/joi';
import md5 from 'md5';
import i18n from './i18n'

Vue.prototype.$joi = joi;
Vue.prototype.$md5 = md5;

Vue.prototype.$gapi = null;


// Vue.use(VJsoneditor)
Vue.prototype.$axios = axios;
Vue.prototype.$Cookies = Cookies;
Vue.mixin(functions);

//make all scalify-components public
require('./components/scalify-ui');

// Styles
import '@/assets/scss/scalify.scss';


// clickaway directives
Vue.directive('clickaway', {
  bind(el, binding, vnode) {
    let vm = vnode.context;
    let callback = binding.value;
    el.clickOutsideEvent = (event) => {
      event.stopPropagation();
      if (!(el == event.target || el.contains(event.target))) {
        return callback.call(vm, event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  }
});


Vue.config.productionTip = false

// Layouts
import Default from './layouts/Default.vue';
import NoSidebar from './layouts/NoSidebar.vue';

Vue.component('default-layout', Default);
Vue.component('no-sidebar-layout', NoSidebar);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
