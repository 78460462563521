<template>
    <s-card class="dashboard-multiple-charts__card h-100">
        <!-- Header Multiple Chart -->
        <s-card-header class="dashboard-multiple-charts__header border-bottom-0">

            <div class="dataset-name">
                <svg class="icone" width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect width="15" height="13.3333" rx="6.66667" fill="#59D07D"/></svg>
                <span class="text">{{$t('overview.revenue')}}</span>
            </div>
            <div class="dataset-name">
                <svg class="icone" width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect width="15" height="13.3333" rx="6.66667" fill="#FDC02F"/></svg>
                <span class="text">{{$t('overview.clicks')}}</span>
            </div>
            <div class="dataset-name">
                <svg class="icone" width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect width="15" height="13.3333" rx="6.66667" fill="#2A8CFF"/></svg>
                <span class="text">{{$t('overview.sign_ups')}}</span>
            </div>

        </s-card-header>

        <!-- Body Chart Normal -->
        <!-- style="height: calc(100% - 70px) !important;" -->
        <s-card-body v-show="chart.data" class="h-100 p-3 dashboard-multiple-charts__body-chart " >
            <div class="dashboard__body--container-chart">
                <canvas :style="loading?'opacity: 0.1;pointer-events: none;':''" class="dashboard__body--chart" ref="canvas"></canvas>
            </div>
        </s-card-body>

        <!-- Body Chart Loader -->
        <s-card-body style="top:0;height:100%;" v-show="loading" class="p-0 dashboard-multiple-charts__body-chart w-100 text-center">
            <div v-if="loading" class="loader-chart" style="opacity: 1 !important;z-index:8;">
                <s-loader :type="'scalify'"></s-loader>
            </div>
        </s-card-body>

    </s-card>
</template>

<script>

import Chart from 'chart.js';
import moment from 'moment';
export default {
  name: 'multiple-chart',

  components:{
  },

  props:['startDate','endDate'],

  watch:{
    startDate:{
        deep:true,
        handler(){
            this.changesInChart();
        }
    },
    endDate:{
        deep:true,
        handler(){
            this.changesInChart();
        }
    },
  },

  data(){
    return{
      loading:false,
      chart: {
          chartID: null,
          typeChart: 'line',
          showPlatformMetric:false,
          loadingChart: false,
          chartcanvas: null,
          showMetric: false,
          data:null,
          topDropDownMetrics: 20,
          listSelected: [],
          settingMetric: false,
          metric: null
      },
      dataChart: null
    }
  },
  methods:{
    async changesInChart(){
        if (this.chart.chartcanvas != null) {
        this.loading = true;
        try{


            let chartData = await this.getMultipleCharts(moment(this.startDate).format("YYYY-MM-DD"), moment(this.endDate).format("YYYY-MM-DD"));

            const arrayDates = this.getDates(this.startDate, this.endDate);

            let dataChart = [];

            if (chartData && chartData.data && chartData.data.revenues) {

                dataChart.push({ data:chartData.data.revenues.data, borderWidth: 2, borderColor:"#59D07D", backgroundColor: "#59D07D", label: this.$t('overview.revenues'), lineTension: 0, pointBorderWidth: 3,pointHoverBorderColor: "#FFFFFF", pointHoverBorderWidth: 1, radius: 3, pointHoverBackgroundColor:"#59D07D",pointBackgroundColor: "#59D07D", pointHoverRadius:4, fill:false })
                dataChart.push({ data:chartData.data.clicks.data, borderWidth: 2, borderColor:"#FFC04B", backgroundColor: "#FFC04B", label: this.$t('overview.clicks'), lineTension: 0, pointBorderWidth: 3,pointHoverBorderColor: "#FFFFFF", pointHoverBorderWidth: 1, radius: 3, pointHoverBackgroundColor:"#FFC04B",pointBackgroundColor: "#FFC04B", pointHoverRadius:4, fill:false })
                dataChart.push({ data:chartData.data.signups.data, borderWidth: 2, borderColor:"#2A8CFF", backgroundColor: "rgba(42, 140, 255, 0.2)", label: this.$t('overview.sign_ups'), lineTension: 0, pointBorderWidth: 3,pointHoverBorderColor: "#FFFFFF", pointHoverBorderWidth: 1, radius: 3, pointHoverBackgroundColor:"#2A8CFF",pointBackgroundColor: "#2A8CFF", pointHoverRadius:4, fill:true })

                this.chart.data = {dataLabel:arrayDates, dataCharts:dataChart, lastValue:null, totalData:null}

            }else{

                arrayDates.map(()=>{ dataChart.push(0) })
                this.chart.data = {dataLabel:arrayDates, dataCharts:dataChart, lastValue:null, totalData:null}

            }

            this.dataChart.labels = this.chart.data.dataLabel;
            this.dataChart.datasets = this.chart.data.dataCharts;

            this.chart.chartcanvas.update();

            this.loading = false;
        }
        catch(err){
            this.loading = false;
        }

    }
    },
    async getCharts(){



        this.chart.chartID = this.$refs.canvas;

        this.loading = true
        this.chart.loadingChart = true;


        try{

            let chartData = await this.getMultipleCharts(moment(this.startDate).format("YYYY-MM-DD"), moment(this.endDate).format("YYYY-MM-DD"));

            const arrayDates = this.getDates(this.startDate, this.endDate);
            let dataChart = [];

            if (chartData && chartData.data && chartData.data.revenues) {

                dataChart.push({ data:chartData.data.revenues.data, borderWidth: 2, borderColor:"#59D07D", backgroundColor: "#59D07D", label: this.$t('overview.revenues'), lineTension: 0, pointBorderWidth: 3,pointHoverBorderColor: "#FFFFFF", pointHoverBorderWidth: 1, radius: 3, pointHoverBackgroundColor:"#59D07D",pointBackgroundColor: "#59D07D", pointHoverRadius:4, fill:false })
                dataChart.push({ data:chartData.data.clicks.data, borderWidth: 2, borderColor:"#FFC04B", backgroundColor: "#FFC04B", label: this.$t('overview.clicks'), lineTension: 0, pointBorderWidth: 3,pointHoverBorderColor: "#FFFFFF", pointHoverBorderWidth: 1, radius: 3, pointHoverBackgroundColor:"#FFC04B",pointBackgroundColor: "#FFC04B", pointHoverRadius:4, fill:false })
                dataChart.push({ data:chartData.data.signups.data, borderWidth: 2, borderColor:"#2A8CFF", backgroundColor: "rgba(42, 140, 255, 0.2)", label: this.$t('overview.sign_ups'), lineTension: 0, pointBorderWidth: 3,pointHoverBorderColor: "#FFFFFF", pointHoverBorderWidth: 1, radius: 3, pointHoverBackgroundColor:"#2A8CFF",pointBackgroundColor: "#2A8CFF", pointHoverRadius:4, fill:true })

                this.chart.data = {dataLabel:arrayDates, dataCharts:dataChart, lastValue:null, totalData:null}

            }else{

                arrayDates.map(()=>{ dataChart.push(0) })
                this.chart.data = {dataLabel:arrayDates, dataCharts:dataChart, lastValue:null, totalData:null}

            }

            this.dataChart = {
                labels: this.chart.data.dataLabel,
                datasets: this.chart.data.dataCharts
            }

            this.chart.chartcanvas = new Chart(this.chart.chartID, {
                type: this.chart.typeChart,
                data: this.dataChart,
                options: {
                    layout: { padding: { left: 0, right: 0, top: 0, bottom: 0 } },
                    legend: { display: false },
                    elements: { point:{ radius: 3 }, line: { skipNull: true, drawNull: false, } },
                    tooltips: {
                        mode: 'index', intersect: false,
                        backgroundColor: '#FFFFFF', titleFontFamily: 'Walsheim Pro', titleFontSize: 14, titleFontColor: '#52749C',
                        titleMarginBottom: 10, bodyFontFamily: 'arial' , bodyFontSize: 14, bodyFontColor: '#52749C', bodySpacing: 5, xPadding: 15, yPadding: 15, bodyFontStyle: '500', caretSize: 0, borderColor: 'rgba(42, 140, 255, 0.27)', borderWidth: 1,caretPadding: 10,
                        callbacks: {
                            label: (tooltipItem, data)=> {
                                var label = data.datasets[tooltipItem.datasetIndex].label || '';
                                if (label) {
                                    label += ' ';
                                }
                                label += Math.round(tooltipItem.yLabel * 100) / 100;

                                if (data.datasets[tooltipItem.datasetIndex].label == this.$t('overview.revenues')) {
                                    return label+' '+this.$t('globale_world.usd');
                                }
                                return label;
                            }
                        },

                    },
                    hover: {
                        mode: 'index',intersect: false,
                        onHover: function(e) {
                            var point = this.getElementAtEvent(e);
                            if (point.length){ e.target.style.setProperty('cursor','pointer','important');
                            }else{ e.target.style.setProperty('cursor','default','important');}
                        }
                    },
                    maintainAspectRatio: false,
                    onResize: ()=>{
                        this.chart.chartcanvas.update();
                    },
                    // responsive: true,
                    scales: {
                        // xAxes: [{
                        //     ticks: {
                        //         beginAtZero: true
                        //     }
                        // }],
                        // yAxes: [{
                        //     labelOffset: 20,
                        //     gridLines: {
                        //         display: false,
                        //         lineWidth: 1,
                        //         color: "rgba(0,0,0,0.5)"
                        //     },
                        //     ticks: {
                        //         beginAtZero: true,
                        //             display: true,
                        //         padding: 20
                        //     }
                        // }]
                        xAxes: [
                            {
                                display: true, color: '#C6D9FF', type: 'time', beginAtZero: true,
                                time: { tooltipFormat: 'll', unit: 'day', unitStepSize: 1, displayFormats: { 'day': 'MMM D','week': 'll','month': 'MMM YYYY','year': 'YYYY' } },
                                gridLines: { display: true, drawBorder: false, color: "#C6D9FF", tickMarkLength: 10,zeroLineColor:"#C6D9FF" },
                                ticks: {display: true, fontFamily: "Walsheim Pro", fontSize: 14, autoSkip: true, maxRotation: 0, minRotation: 0, fontStyle: "normal", fontColor: "#2B4D75", padding: 10}
                            }
                        ],

                        yAxes: [
                            {
                                display: true, color: '#C6D9FF',type:'logarithmic',
                                gridLines: { display: true, drawBorder: false, color: "#C6D9FF", tickMarkLength: 10,zeroLineColor:"#C6D9FF" },
                                ticks: {display: true, fontFamily: "Walsheim Pro", fontSize: 12, autoSkip: true, maxRotation: 0, minRotation: 0, fontStyle: "normal", fontColor: "#2B4D75", padding: 10, callback: (value, index, values)=> {
                                        if (index == 0 || index == values.length-1 || index == Math.round((values.length - 1) / 2)) {
                                            return this.kFormatter(value);
                                        }
                                    }
                                }
                            }
                        ]
                    }
                }
            });


            this.chart.loadingChart = false;
        }
        catch(err){
            this.chart.loadingChart = false;
            this.handleErrors(err)
        }

        this.loading = false
    }
  },

  async mounted(){

    await this.getCharts()
  },




}
</script>

<style scoped>

</style>
