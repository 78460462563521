<template>
  <!-- <div :id="Math.random()"> -->
    <div :id="Math.random()" class="checkbox display-table w-100" v-if="hasLabel&&checkboxPosition=='left'">
      <input type="checkbox" :value="inputValue" v-model="itemTo">
      <span class="checkbox__checkmark display-table-cell" @click.stop="check()"></span>
      <span class="checkbox__label display-table-cell" @click.stop.prevent="check()">
        <slot />
      </span>
    </div>
    <div :id="Math.random()" v-else-if="hasLabel&&checkboxPosition=='right'" class="display-table w-100">
      <div class="checkbox__label cursor-pointer display-inline-block mr-2 display-table-cell w-100" @click.stop="check()">
        <slot></slot>
      </div>
      <s-checkbox class="pl-4 display-table-cell right" @input="sendData" :textValue="inputValue" v-model="itemTo"></s-checkbox>
    </div>
    <div :id="Math.random()" class="checkbox display-table" v-else>
      <input type="checkbox" :value="inputValue" v-model="itemTo">
      <!-- <input type="checkbox" :value="inputValue" v-model="itemTo" @click.stop="check()" v-if="!forTree"  > -->
      <span class="checkbox__checkmark display-table-cell" @click.stop="check()"></span>
      <!-- @click.stop="check()" -->
    </div>
  <!-- </div> -->
</template>

<script>



export default {
  name: 's-checkbox',
  data(){
    return {
      inputValue : this.textValue,
      itemTo: this.value
    }
  },
  mounted(){
    this.inputValue = this.textValue,
    this.itemTo= this.value
    // this.check()
  },
  props: {
    value:{},
    textValue:[String,Number,Object],
    checkboxPosition:{
      type:String,
      default:'left',
    },
    to:null,
    hasLabel:{
      default:false,
      type:Boolean
    }
  },
  methods:{
    sendData(data){
      this.$emit("input",data)
    },
    // changeInput(ev){
    //   this.$emit("changed",this.itemTo)
    // },
    check(){
      if(Array.isArray(this.itemTo)){
        var x = this.itemTo.findIndex(obj => obj == this.inputValue)
        if(x !== -1){
          this.itemTo.splice(x,1)
          this.$emit("input",this.itemTo)
        }
        else{
          this.itemTo.push(this.inputValue)
          this.$emit("input",this.itemTo)
        }
      }
      else{
        this.itemTo= !this.itemTo
        this.$emit("input",this.itemTo)
      }


    }
  },
  watch:{
    itemTo(){

      // this.$emit("input",this.itemTo)
      // this.$emit("changed",this.itemTo)
    },
    value(){
      // if(!Array.isArray(this.itemTo)){
      this.itemTo=this.value
      // this.$emit("input",this.itemTo)
      // }
    },
    textValue(){
      this.inputValue = this.textValue
      // this.check()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
