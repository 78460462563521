import Vue from 'vue';

import Tag from './Tag.vue'

Vue.component('s-tag',Tag);


export default{
    Tag,
}

