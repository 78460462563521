<template>
        <span class="badge">
            <slot />
            <svg class="ml-2 h-100 cursor-pointer" v-if="close >= 0" @click="remove()" width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.20591 4.5L8.76452 1.94139C9.07849 1.62741 9.07849 1.11835 8.76452 0.804119L8.19588 0.235483C7.8819 -0.0784943 7.37284 -0.0784943 7.05861 0.235483L4.5 2.79409L1.94139 0.235483C1.62741 -0.0784943 1.11835 -0.0784943 0.804119 0.235483L0.235483 0.804119C-0.0784943 1.1181 -0.0784943 1.62716 0.235483 1.94139L2.79409 4.5L0.235483 7.05861C-0.0784943 7.37259 -0.0784943 7.88165 0.235483 8.19588L0.804119 8.76452C1.1181 9.0785 1.62741 9.0785 1.94139 8.76452L4.5 6.20591L7.05861 8.76452C7.37259 9.0785 7.8819 9.0785 8.19588 8.76452L8.76452 8.19588C9.07849 7.8819 9.07849 7.37284 8.76452 7.05861L6.20591 4.5Z" fill="white"/>
            </svg>

        </span>
</template>

<script>



export default {
  name: 's-toggle',
  props: {
    value: {
      type:Boolean,
      default:false
    },
    close: {
      type:Number,
      default:-1
    }
  },

  methods:{
    remove(){
      this.$emit('remove-badge',this.close);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
