<template>
    <div id="affiliat-link">

      <div class="affiliat-link__row row no-gutters">
        <div class="affiliat-link__col col-12 col-md-6 ">
          <s-card class="mb-4 affiliat-link__link">
            <s-card-header class="">
              <h3 class="affiliat-link__link-title">{{$t('my_affiliate_link.your_affiliate_link.title')}}</h3>
            </s-card-header>
            <s-card-body v-if="user.approved">
              <div class="form-row row no-gutters mb-4">
                <div class="form-col col-4">
                  <p class="form-col-text">{{$t('my_affiliate_link.your_affiliate_link.landing_page')}}</p>
                </div>
                <div class="form-col col-8">
                  <s-dropdown v-model="typeLandingPage" display="select" :options="listLandingPages"></s-dropdown>
                </div>
              </div>
              <div class="form-row row no-gutters">
                <div class="form-col col-4">
                  <p class="form-col-text">{{$t('my_affiliate_link.your_affiliate_link.copy_your_link')}}</p>
                </div>
                <div class="form-col col-8">
                  <s-input id="affiliateLink" class="disabled" fieldFor="settings" :value="landingPageLink" placeholder="Link"></s-input>
                  <button @click="copyAffiliateLink()" class="btn btn--primary btn-copy">{{$t('my_affiliate_link.your_affiliate_link.copy')}}</button>
                </div>
              </div>
            </s-card-body>
            <s-card-body v-else>
              <p class="text-danger">{{$t('my_affiliate_link.your_affiliate_link.reviewing_your_application')}} <a class="card-link cursor-pointer" @click.stop="goSupport()">{{$t('my_affiliate_link.your_affiliate_link.contact_us')}}</a> {{$t('my_affiliate_link.your_affiliate_link.if_you_have_questions')}}</p>
            </s-card-body>
          </s-card>

          <s-card class="mb-4 affiliat-link__discount">
            <s-card-header>
              <h3 class="affiliat-link__discount-title">{{$t('my_affiliate_link.enable_discount.title')}}</h3>
              <s-toggle v-if="!loadingToggleDiscount" class="affiliat-link__discount-toggle" v-model="discount"></s-toggle>
              <s-loader class="affiliat-link__discount-toggle" v-else></s-loader>
            </s-card-header>
            <s-card-body v-if="discount">
              <div class="form-row row no-gutters">
                <div class="form-col col-4">
                  <p class="form-col-text">{{$t('my_affiliate_link.enable_discount.discount_amount')}}</p>
                </div>
                <div class="form-col col-8">
                  <s-dropdown v-if="!loadingDropdownDiscount" v-model="discountAmount" display="select" :options="listdiscountAmount"></s-dropdown>
                  <button v-else class="btn btn--white"><s-loader></s-loader></button>
                </div>
              </div>
              <div class="form-row row no-gutters">
                <div class="form-col col-4"></div>
                <div class="form-col col-8">
                  <p class="text-under">{{$t('my_affiliate_link.enable_discount.discount_available_in_all_your_links')}}</p>
                </div>
              </div>
            </s-card-body>
            <s-card-body v-else>
              <p class="encourage-text">{{$t('my_affiliate_link.enable_discount.attaching_special_discount')}}</p>
            </s-card-body>
          </s-card>

        </div>
        <div class="affiliat-link__col col-12 col-md-6 ">

          <s-card class="mb-4">
            <s-card-header>
              <h3 class="card-title">{{$t('my_affiliate_link.how_it_works.title')}}</h3>
            </s-card-header>
            <s-card-body>

              <p class="card-text">{{$t('my_affiliate_link.how_it_works.tell_your_audience')}}</p>
              <p class="card-text mt-4">{{$t('my_affiliate_link.how_it_works.when_someone_coming_from_your_link')}}</p>

            </s-card-body>
          </s-card>

          <s-card class="mb-4">
            <s-card-header>
              <h3 class="card-title">{{$t('my_affiliate_link.your_commision.title')}}</h3>
            </s-card-header>
            <s-card-body>

              <p class="card-text">{{$t('my_affiliate_link.your_commision.you_receive')}}</p>

            </s-card-body>
          </s-card>

          <s-card class="">
            <s-card-header>
              <h3 class="card-title">{{$t('my_affiliate_link.need_help.title')}}</h3>
            </s-card-header>
            <s-card-body>

              <p class="card-text">{{$t('my_affiliate_link.need_help.you_need_help')}} <a target="_blank" @click="goSupport()" class="card-link cursor-pointer">{{$t('my_affiliate_link.need_help.support_page')}}</a>.</p>

            </s-card-body>
          </s-card>

        </div>
      </div>
    </div>
</template>

<script>

export default {
  name: 'affiliate-link',

  components:{
  },

  props: {},

  computed:{
    landingPageLink(){
      return `https://link.scalify.com/${this.user.id}/${this.typeLandingPage.value}`
    }
  },

  data(){
    return{
      typeLandingPage: {name:this.$t('my_affiliate_link.your_affiliate_link.website_homepage'),value:1},
      listLandingPages: [{name:this.$t('my_affiliate_link.your_affiliate_link.website_homepage'),value:1},{name:this.$t('my_affiliate_link.your_affiliate_link.shopify_listing'),value:2},{name:this.$t('my_affiliate_link.your_affiliate_link.sign_up_page'),value:3}],
      discount: false,
      loadingToggleDiscount: false,
      loadingDropdownDiscount: false,
      discountAmount: {name:"5%",value:0.05},
      listdiscountAmount: [{name:"5%",value:0.05},{name:"10%",value:0.10},{name:"15%",value:0.15},{name:"20%",value:0.20},{name:"25%",value:0.25}]
    }
  },

  methods:{
    copyAffiliateLink(){
      var copyText = document.getElementById(`affiliateLink`);
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");
      this.notify('success', this.$t('my_affiliate_link.text_copied'));
    },
    goSupport(){
      this.$store.state.dashboardStep = 'support';
    },
    async updateAff(discount){
      try{

        const editAffiliate = await this.updateAffiliate(null, discount, null, null,null,null);
        if (editAffiliate && editAffiliate.data && editAffiliate.data.updateAffiliate) {
          this.$store.state.user.discount = discount;
          this.notify('success', this.$t(editAffiliate.data.updateAffiliate));
          return true;
        }else{
          this.notify('error', editAffiliate.errors[0].message);
          return false;
        }

      }catch(err){

        this.handleErrors(err)

      }
    }

  },

  watch:{
    async discount(){
      if (!this.discount) {
        this.discountAmount = {name:"0%",value:0};
      }

      this.loadingToggleDiscount = true;
      try {

        if (this.user.discount != this.discountAmount.value) {
          const discount = await this.updateAff(this.discountAmount.value)
          if (!discount) {
            this.discount = false;
          }

        }

        this.loadingToggleDiscount = false;

      } catch (error) {
        this.loadingToggleDiscount = false;
      }

    },
    async discountAmount(){

      this.loadingDropdownDiscount = true;
      try {

        if (this.user.discount != this.discountAmount.value) {

          const discount = await this.updateAff(this.discountAmount.value)
          if (!discount) {
            this.discountAmount = {value:'wrong',name:this.$t('my_affiliate_link.something_wrong')}
          }
        }

        this.loadingDropdownDiscount = false;

      } catch (error) {
        this.loadingDropdownDiscount = false;
      }

    }
  },

  async mounted(){
    if (this.user && this.user.discount >= 0) {
      if (this.user.discount == 0) {
        this.discount = false;
      }else{
        this.discount = true;
        this.listdiscountAmount.map((discount)=>{
          if (this.user.discount == discount.value) {
            this.discountAmount = discount;
          }
        })
      }
    }
  }


}
</script>

<style scoped>

</style>
