import Vue from 'vue';

import Tooltip from './Tooltip.vue'

Vue.component('s-tooltip', Tooltip);

export default{
    Tooltip, 
}


