import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // scalifyAccessToken: null,
    user: null,
    scalifyAccessToken: null,
    toasts:[],
    dashboardStep: 'overview'
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
