<template>
    <div class="progress" :class="['progress--'+color, isPage?'progress--page':'']">
        <div v-if="!isPage" class="progress__bar" :style="{width:value+'%'}">
            
        </div>
        <div v-if="isPage && value >= 1" class="progress__bar" style="width:20%; margin-right:2px;border-radius:14px 0 0 14px;"></div>
        <div v-if="isPage && value >= 2" class="progress__bar" style="width:20%; margin-right:2px;border-radius:0px;"></div>
        <div v-if="isPage && value >= 3" class="progress__bar" style="width:20%; margin-right:2px;border-radius:0px;"></div>
        <div v-if="isPage && value >= 4" class="progress__bar" style="width:20%; margin-right:2px;border-radius:0px;"></div>
        <div v-if="isPage && value == 5" class="progress__bar" style="width:20%;border-radius:0 14px 14px 0"></div>
    </div>
</template>

<script>



export default {
  name: 's-progress',
  props: {
    isPage:{
      type: Boolean,
      default: false
    },
    value: {
      type:Number,
      default:0
    },
    color:{type:String,default:"primary"}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
