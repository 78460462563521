import Vue from 'vue';

import Input from './Input.vue'
import InputGroup from './InputGroup.vue'
import TextArea from './TextArea.vue'

Vue.component('s-input',Input);
Vue.component('s-text-area',TextArea);
Vue.component('s-input-group',InputGroup);


export default{
    Input,
    TextArea,
    InputGroup
}

