<template>
    <li class="list-group__item">
      <slot />
    </li>
</template>

<script>



export default {
  name: 's-list-group-item',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
