<template>
    <div id="forget">
        <div class="forget__wrapper">
            <div>
              <img src="../../../assets/images/logo-S.svg" class="forget_wrapper__logo mb-4" />
              <!-- Components (signIn or signUp) -->
              <forgot></forgot>
            </div>
        </div>
        <Notes></Notes>
    </div>
</template>

<script>

import Forgot from './ForgotPasswordForm.vue'
import Notes from './Notes.vue'
export default {
  name: 'reset-password',
  props:{
    
  },
  components: {
    Notes,
    Forgot
  },
  watch:{
    
  },
  data(){
    return{
    };
  },
  methods:{
     

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
