<template>
    
    <div class="input-group">
        <s-input v-model="inputValue" type="text" fieldFor="settings" class="input-group__field" :placeholder="placeholder" />
        <div class="input-group__label display-table">
          <span class="display-table-cell py-0">.myshopify.com</span>
        </div>
        <s-button class="btn btn--success input-group__button">Install Scalify</s-button>
    </div> 
</template>

<script>



export default {
  name: 's-input-group',
  props: {
    placeholder: {
        type:String,
        default:"",
    },
    value:{
        type:String,
        default:""
    },
  },
  data(){
      return{
          inputValue:this.value,
      }
  },
  watch: {
    inputValue(val) {
      this.$emit('input', val);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
