<template>
    <div id="creatives">

      <div class="creatives__row row no-gutters">
        <!-- Overview -->
        <div class="creatives__col col-12">
          <s-card class="mb-4 ">
            <s-card-header class="">
              <h3 class="">{{$t('creatives.overview')}}</h3>
            </s-card-header>
            <s-card-body>
              <div class="row creatives__row row no-gutters">
                <div v-for="(video, index) in listVideos.overview" :key="index" class="creatives__col col-sm-12 col-md-6 col-lg-6">
                  <!-- <s-card> -->
                    <div class="p-0 bg-video">
                      <video class="video" controls>
                        <source :src="video.src" type="video/mp4">
                      </video>
                    </div>
                    <div class="text-center">
                      <a class="text-primary cursor-pointer"  @click="downloadVideo(video.src)" > {{$t('creatives.download_video')}}</a>
                    </div>
                  <!-- </s-card> -->
                </div>
              </div>
            </s-card-body>
          </s-card>
        </div>

        <!-- Retargeting -->
        <div class="creatives__col col-12">
          <s-card class="mb-4 ">
            <s-card-header class="">
              <h3 class="">{{$t('creatives.retargeting')}}</h3>
            </s-card-header>
            <s-card-body>
              <div class="row">
                <div v-for="(video, index) in listVideos.retargeting" :key="index" class="col-sm-12 col-md-6 col-lg-6">
                  <!-- <s-card> -->
                    <div class="p-0 bg-video">
                      <video class="video" controls :style="video.id == 1 ? 'width: 46%;' : ''">
                        <source :src="video.src" type="video/mp4">
                      </video>
                    </div>
                    <div class="text-center">
                      <a class="text-primary cursor-pointer" @click="downloadVideo(video.src)"  > {{$t('creatives.download_video')}}</a>
                    </div>
                  <!-- </s-card> -->
                </div>
              </div>
            </s-card-body>
          </s-card>
        </div>

        <!-- Scaling -->
        <div class="creatives__col col-12">
          <s-card class="mb-4 ">
            <s-card-header class="">
              <h3 class="">{{$t('creatives.scaling')}}</h3>
            </s-card-header>
            <s-card-body>
              <div class="row">
                <div v-for="(video, index) in listVideos.scaling" :key="index" class="col-sm-12 col-md-6 col-lg-6">
                  <!-- <s-card> -->
                    <div class="p-0 bg-video">
                      <video class="video" controls>
                        <source :src="video.src" type="video/mp4">
                      </video>
                    </div>
                    <div class="text-center">
                      <a class="text-primary cursor-pointer" @click="downloadVideo(video.src)"  > {{$t('creatives.download_video')}}</a>
                    </div>
                  <!-- </s-card> -->
                </div>
              </div>
            </s-card-body>
          </s-card>
        </div>

        <!-- Social Proof -->
        <div class="creatives__col col-12">
          <s-card class="mb-4 ">
            <s-card-header class="">
              <h3 class="">{{$t('creatives.social_proof')}}</h3>
            </s-card-header>
            <s-card-body>
              <div class="row">
                <div v-for="(video, index) in listVideos.socialProof" :key="index" class="col-sm-12 col-md-6 col-lg-6">
                  <!-- <s-card> -->
                    <div class="p-0 bg-video">
                      <video class="video" controls>
                        <source :src="video.src" type="video/mp4">
                      </video>
                    </div>
                    <div class="text-center">
                      <a class="text-primary cursor-pointer" @click="downloadVideo(video.src)"  > {{$t('creatives.download_video')}}</a>
                    </div>
                  <!-- </s-card> -->
                </div>
              </div>
            </s-card-body>
          </s-card>
        </div>
      </div>
    </div>
</template>

<script>

export default {
  name: 'creatives',

  components:{
  },

  props: {

  },

  data(){
    return{
      listVideos:{
        overview:[
          {id: 1, name: "Video Name 1", src:"https://scalify.s3.us-east-2.amazonaws.com/affiliate/Scalify_Explainer.mp4"},
          {id: 2, name: "Video Name 2", src:"https://scalify.s3.us-east-2.amazonaws.com/affiliate/Render_me_1_5.mp4"}
        ],
        retargeting:[
          {id: 1, name: "Video Name 1", src:"https://scalify.s3.us-east-2.amazonaws.com/affiliate/render_me_1.mp4"},
          {id: 2, name: "Video Name 2", src:"https://scalify.s3.us-east-2.amazonaws.com/affiliate/render_me_16-9_3.mp4"}
        ],
        scaling:[
          {id: 1, name: "Video Name 1", src:"https://scalify.s3.us-east-2.amazonaws.com/affiliate/Comp_1_1.mp4"},
          {id: 2, name: "Video Name 2", src:"https://scalify.s3.us-east-2.amazonaws.com/affiliate/Comp_1_3.mp4"}
        ],
        socialProof:[
          {id: 1, name: "Video Name 1", src:"https://scalify.s3.us-east-2.amazonaws.com/affiliate/social-proof.mp4"}
        ]
      }
    }
  },

  methods:{
    downloadVideo(src) {
      console.log('src: ', src);
      let xhr = new XMLHttpRequest();
      xhr.open('GET', src, true);
      xhr.responseType = 'blob';
      xhr.onload = function() {
        let urlCreator = window.URL || window.webkitURL;
        let videoUrl = urlCreator.createObjectURL(this.response);
        let tag = document.createElement('a');
        console.log('tag: ', tag);
        tag.href = videoUrl;
        tag.target = '_blank';
        let name = src.split('/');
        console.log('name: ', name);
        tag.download = name[name.length - 1];
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
      };
      xhr.onerror = err => {
        console.log('Failed to picture', err);
      };
      xhr.send();
    }
  },

  watch:{

  },

  async mounted(){

  }


}
</script>

<style scoped>

</style>
