<template>
    <div class="container" id="all-components" style="background-color:#f8fbff">
        <h2>Dashboard items</h2>
        <s-button-chart color="white" v-model="chart" class="mb-2"></s-button-chart>
        <s-button-group color="white" :options="listBtn" v-model="btn" class="mb-2"></s-button-group>
        <br><br>
        <h2>input</h2>
        <s-input fieldFor="settings" :hasLabel="true" v-model="searchy" placeholder="Email"></s-input>
        <br>
        <br>
        <br>
        <div class="toast-container">
          <div class="on" v-for="(toast, indexToast) in this.$store.state.toasts" :key="indexToast">
            <s-toast :id="toast.id" :state="toast.state" :title="toast.title" :text="toast.text"></s-toast>
          </div>
        </div>
        <h2>Click To Show Toast</h2>
        <button class="btn btn--success" @click="showToast('success')">Show Success Toast</button><br><br>
        <button class="btn btn--danger" @click="showToast('error')">Show Danger Toast</button>
        <br>
        <br>
        <h2>Button Loading</h2>
        <button class="btn btn--primary disabled">
          <s-loader :isWhite="true"></s-loader>
        </button>
        <br>
        <br>
        <h2>Video Chooser type button</h2>
        <br><br>
        <h2>Tag</h2>
        <s-tag>Backend</s-tag>
        <br>
        <br>
        <h2>Tooltip</h2>
        <div class="w-30">
          <span tooltip="is this you ? hayahayahayahay hayhyahyahya wooonderfull">
            <span>is this you ? hayahayahayahay hayhyahyahya ya salam</span>
          </span>
        </div>
        <br><br>
        <h2>Search badge</h2>
        <s-search-badge></s-search-badge>
        <br><br>
        <h2>Pulse</h2>
        <button class="btn btn--primary pulse--primary mr-3">Primary</button>
        <button class="btn btn--success pulse--success mr-3">Success</button>
        <button class="btn btn--danger pulse--danger mr-3">Danger</button>
        <button class="btn btn--white pulse--white mr-3">White</button>
        <br><br>
        <br><br>
        <h2>Video Chooser type select</h2>
        <br><br>
        <s-input v-model="search" type="number" fieldFor="currency" placeholder="Cost" currency="MAD"></s-input>
        <br><br>
        <h2>Dropdowns</h2>
        <div class="mb-5">
          <s-dropdown takeWidth="content" display="button" label="hahah" :reverse="true" :default="inline[1]" :options="inline"></s-dropdown>
          <br><br>
          <div style="width: 150px;">
            <s-dropdown v-model="abbas" label="abbas" :options="drop"></s-dropdown>
            <s-dropdown v-model="abbas" display='select' :options="drop"></s-dropdown>
            <s-dropdown v-model="abbas" display='button' :options="drop"></s-dropdown>
            <s-dropdown v-model="abbas" display='ageGender' :options="drop"></s-dropdown>
          </div>
        </div>
        <br><br>
        <h2>Slider</h2>
        <s-slider min="0" max="1000" minValue="100" step="100" v-model="slider"></s-slider>
        <br><br>
        <h2>Card</h2>
        <s-card>
            <s-card-header>
                card header
            </s-card-header>
            <s-card-body>
                <s-card>
                    <s-card-header>
                        card 2 header
                    </s-card-header>
                    <s-card-body>
                        new new new
                    </s-card-body>
                </s-card>
            </s-card-body>
            <s-card-footer>
                <button class="btn btn--primary">done</button>
            </s-card-footer>
        </s-card>
        <br><br>
        <h2>List</h2>
        <s-list-group>
          <s-list-group-item>Fist item</s-list-group-item>
          <s-list-group-item>second item</s-list-group-item>
          <s-list-group-item>third item</s-list-group-item>
        </s-list-group>
        <br><br>
        <h2>Toggle</h2>
        <s-toggle :disabled="true" v-model="activate"></s-toggle>
        <br><br>
        <h2>Checkbox</h2>
        <s-checkbox textValue="hello" :hasLabel="true" v-model="items">color white</s-checkbox>
        <s-checkbox textValue="item2" :hasLabel="true" v-model="items">color black</s-checkbox>
        <s-checkbox textValue="item3" :hasLabel="true" v-model="items">color pink</s-checkbox>
        <br><br>
        <h2>Buttons</h2>
        <button class="btn btn--success btn--lg" @click="activate= !activate">Success</button>
        <button class="btn btn--primary btn--lg ml-3" @click="activate= !activate">Primary</button>
        <button class="btn btn--danger btn--lg ml-3" @click="activate= !activate">Danger</button>
        <button class="btn btn--white btn--lg ml-3" @click="activate= !activate">White</button>
        <br><br>
        <button class="btn btn--success" @click="activate= !activate">Success</button>
        <button class="btn btn--primary ml-3" @click="activate= !activate">Primary</button>
        <br><br>
        <h2>Progress Bar</h2>
        <s-progress class="mb-2" :value="65"></s-progress>
        <s-progress class="mb-2" color="purple" :value="65"></s-progress>
        <s-progress class="mb-2" color="danger" :value="65"></s-progress>
        <s-progress color="warning" :value="65"></s-progress>
        <br><br>
        <h2>Badges</h2>
        <s-badge class="badge badge--success">success</s-badge>
        <s-badge class="badge badge--primary ml-3">primary</s-badge>
        <s-badge class="badge badge--danger ml-3">danger</s-badge>
        <br><br>
        <h2>Tabs</h2>
        <button class="btn btn--danger" @click="back">Previous tab</button>
        <button class="btn btn--white ml-3" @click="next">next tab</button>
        <br>
        <s-tabs ref="tabs">
          <s-tab-item name="First" :selected="true">
            Lorem Ipsum Emet
          </s-tab-item>
          <s-tab-item name="second">
            Lorem Ipsum Emet 2
          </s-tab-item>
          <s-tab-item name="third">
            Lorem Ipsum Emet 3
          </s-tab-item>
          <s-tab-item name="Fourth">
            Lorem Ipsum Emet 4
          </s-tab-item>
        </s-tabs>
        <br><br>
        <h2>Input for Interests</h2>
        <s-input v-model="searchy" fieldFor="interests" placeholder="Insert Text"></s-input>
        <br><br>
        <h2>Input for Product</h2>
        <s-input v-model="searchy" placeholder="Product to Promote..."></s-input>
        <br><br>
        <s-text-area v-model="searchy"></s-text-area>
        <br><br>
        <s-loader :isWhite="true"></s-loader>
        <br><br>
        <s-tree-select :options="options" :level="0"></s-tree-select>
        <br><br>
        <h1 class="mb-4"> H1, Scalify Heading</h1>
        <h2 class="mb-4"> H2, Scalify Heading</h2>
        <h3 class="mb-4"> H3, Scalify Heading</h3>
        <h4 class="mb-4"> H4, Scalify Heading</h4>
        <h5 class="mb-4"> H5, Scalify Heading</h5>
        <h6 class="mb-4"> H6, Scalify Heading</h6>
        <p class="mb-4"> P, Scalify</p>
        <span class="mb-4"> Span, Scalify</span>
    </div>
</template>
<script>
export default {
  name: 'all-components',
  props: {
    msg: Array
  },
  data(){
    return{
      text :'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      checkboxy:false,
      checkboxyy:[],
      listLocations: [],
      listAudiences: [],
      activate:false,
      dateRange: '',
      minRange: 1,
      maxRange: 1,
      abbas:{},
      groupo:{},
      chart:"",
      listBtn:["Monhly","Bullshit","Shit"],
      btn:"Bullshit",
      inline:[{name:"Only 1 device",value:"1"},{name:"Only 2 devices",value:"2"},{name:"Only 10 devices",value:"10"},{name:"Only 100 devices",value:"100"},{name:"Only 1000 devices",value:"1000"}],
      searchList: [
            {"name": "Xbox", "audience_size": "9,254,569"},
            {"name": "PlayStation", "audience_size": "9,254,569"},
            {"name": "Nintendo", "audience_size": "9,254,569"},
            {"name": "PlayStation", "audience_size": "9,254,569"},
            {"name": "Xbox", "audience_size": "9,254,569"},
            {"name": "PlayStation", "audience_size": "9,254,569"},
            {"name": "Nintendo", "audience_size": "9,254,569"},
            {"name": "PlayStation", "audience_size": "9,254,569"}
      ],
      search:10,
      searchy:"",
      item:false,
      item2:false,
      item3:true,
      slider:0,
      from:[{name:"13",value:"13"},{name:"25",value:"25"},{name:"65",value:"65"}],
      to:[{name:"+13",value:"13"},{name:"+25",value:"25"},{name:"+65",value:"65"}],
      split:[{name:"5 Years",value:"5"},{name:"10 Years",value:"10"},{name:"20 Years",value:"20"}],
      genders:[{name:"Male",value:"0"},{name:"Female",value:"1"},{name:"Unspecified",value:"3"}],
      drop:[{name:"13",value:"13"},{name:"25",value:"25"},{name:"65",value:"65"}],
      items:["hello"],
      options:[{
        name: "Ed Sharen",
        id:"Sharen",
        children: [
          {
            name: "justin",
            id:'justin',
            children: [
              { name: "Bieber",
                children: [
                { id:1,name: "Belive" },
                { id:2,name: "hey" }
                ],
                id:160
               },
              { id:5,name: "Timberlake" }
            ]
          },
          { id:44,name: "50 cent" }
        ]
        },
        {
          name: "Mohamed",
          id:12,
          children: [
            {
              name: "Father",
              id:111,
              children: [
                { id:19,name: "sister" },
                { id:165,name: "bro" }
              ]
            },
            { id:1442,name: "mother" }
          ]
        }
      ],
    }
  },
  methods:{
    next(){
      this.$refs.tabs.nextTab()
    },
    back(){
      this.$refs.tabs.previousTab()
    },
    dateFormat (classes, date) {
      let dt = new Date(date)
      dt.setHours(0, 0, 0, 0)
      let start = new Date(this.start)
      start.setHours(0, 0, 0, 0)
      let end = new Date(this.end)
      end.setHours(0, 0, 0, 0)
      classes['in-range'] = dt >= start && dt <= end
      return this.dateFormat ? this.dateFormat(classes, date) : classes
    }
  },
}
</script>
<style scoped>
</style>
