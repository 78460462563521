<template>

    <div ref="toast" id="toast">
        <s-card v-if="state == 'success'">
            <s-card-body class="toast__card success" :style="shadowSuccessCard">
                <svg @click="removeToaste(id)" class="float-right cursor-pointer" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.27455 6.1875L11.686 2.66941C12.1047 2.23769 12.1047 1.53773 11.686 1.10566L10.9278 0.323789C10.5092 -0.10793 9.83046 -0.10793 9.41148 0.323789L6 3.84187L2.58852 0.323789C2.16989 -0.10793 1.49114 -0.10793 1.07216 0.323789L0.313977 1.10566C-0.104659 1.53738 -0.104659 2.23734 0.313977 2.66941L3.72545 6.1875L0.313977 9.70559C-0.104659 10.1373 -0.104659 10.8373 0.313977 11.2693L1.07216 12.0512C1.4908 12.4829 2.16989 12.4829 2.58852 12.0512L6 8.53312L9.41148 12.0512C9.83011 12.4829 10.5092 12.4829 10.9278 12.0512L11.686 11.2693C12.1047 10.8376 12.1047 10.1377 11.686 9.70559L8.27455 6.1875Z" fill="#7796B9"/>
                </svg>
                <div class="toast__card--left-div div-success" :style="backgroundSuccessDiv"></div>
                <div v-if="title != ''" class="toast__card--header">
                    <h2 class="mb-2">{{title}}</h2>
                </div>
                <div  class="toast__card--body">
                    <p class="mt-0">{{text}}</p>
                </div>
            </s-card-body>
        </s-card>
        <s-card v-if="state == 'error'">
            <s-card-body class="toast__card" :style="shadowDangerCard">
                <svg @click="removeToaste(id)" class="float-right cursor-pointer" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.27455 6.1875L11.686 2.66941C12.1047 2.23769 12.1047 1.53773 11.686 1.10566L10.9278 0.323789C10.5092 -0.10793 9.83046 -0.10793 9.41148 0.323789L6 3.84187L2.58852 0.323789C2.16989 -0.10793 1.49114 -0.10793 1.07216 0.323789L0.313977 1.10566C-0.104659 1.53738 -0.104659 2.23734 0.313977 2.66941L3.72545 6.1875L0.313977 9.70559C-0.104659 10.1373 -0.104659 10.8373 0.313977 11.2693L1.07216 12.0512C1.4908 12.4829 2.16989 12.4829 2.58852 12.0512L6 8.53312L9.41148 12.0512C9.83011 12.4829 10.5092 12.4829 10.9278 12.0512L11.686 11.2693C12.1047 10.8376 12.1047 10.1377 11.686 9.70559L8.27455 6.1875Z" fill="#7796B9"/>
                </svg>
                <div class="toast__card--left-div" :style="backgroundDangerDiv"></div>
                <div v-if="title != ''" class="toast__card--header">
                    <h2 class="mb-2">{{title}}</h2>
                </div>
                <div  class="toast__card--body">
                    <p class="mt-0">{{text}}</p>
                </div>
            </s-card-body>
        </s-card>
        <s-card v-if="state == 'warning'">
            <s-card-body class="toast__card" :style="shadowWarningCard">
                <svg @click="removeToaste(id)" class="float-right cursor-pointer" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.27455 6.1875L11.686 2.66941C12.1047 2.23769 12.1047 1.53773 11.686 1.10566L10.9278 0.323789C10.5092 -0.10793 9.83046 -0.10793 9.41148 0.323789L6 3.84187L2.58852 0.323789C2.16989 -0.10793 1.49114 -0.10793 1.07216 0.323789L0.313977 1.10566C-0.104659 1.53738 -0.104659 2.23734 0.313977 2.66941L3.72545 6.1875L0.313977 9.70559C-0.104659 10.1373 -0.104659 10.8373 0.313977 11.2693L1.07216 12.0512C1.4908 12.4829 2.16989 12.4829 2.58852 12.0512L6 8.53312L9.41148 12.0512C9.83011 12.4829 10.5092 12.4829 10.9278 12.0512L11.686 11.2693C12.1047 10.8376 12.1047 10.1377 11.686 9.70559L8.27455 6.1875Z" fill="#7796B9"/>
                </svg>
                <div class="toast__card--left-div" :style="backgroundWarningDiv"></div>
                <div v-if="title != ''" class="toast__card--header">
                    <h2 class="mb-2">{{title}}</h2>
                </div>
                <div  class="toast__card--body">
                    <p class="mt-0" v-if="!btn">{{text}}</p>
                    <button @click.stop="showPlans" v-if="btn" class="btn btn--orange">{{btn}}</button>
                </div>
            </s-card-body>
        </s-card>
        <s-card v-if="state == 'primary'">
            <s-card-body class="toast__card" :style="shadowPrimaryCard">
                <svg @click="removeToaste(id)" class="float-right cursor-pointer" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.27455 6.1875L11.686 2.66941C12.1047 2.23769 12.1047 1.53773 11.686 1.10566L10.9278 0.323789C10.5092 -0.10793 9.83046 -0.10793 9.41148 0.323789L6 3.84187L2.58852 0.323789C2.16989 -0.10793 1.49114 -0.10793 1.07216 0.323789L0.313977 1.10566C-0.104659 1.53738 -0.104659 2.23734 0.313977 2.66941L3.72545 6.1875L0.313977 9.70559C-0.104659 10.1373 -0.104659 10.8373 0.313977 11.2693L1.07216 12.0512C1.4908 12.4829 2.16989 12.4829 2.58852 12.0512L6 8.53312L9.41148 12.0512C9.83011 12.4829 10.5092 12.4829 10.9278 12.0512L11.686 11.2693C12.1047 10.8376 12.1047 10.1377 11.686 9.70559L8.27455 6.1875Z" fill="#7796B9"/>
                </svg>
                <div class="toast__card--left-div" :style="backgroundPrimaryDiv"></div>
                <div v-if="title != ''" class="toast__card--header">
                    <h2 class="mb-2">{{title}}</h2>
                </div>
                <div  class="toast__card--body">
                    <p class="mt-0">{{text}}</p>
                </div>
            </s-card-body>
        </s-card>
    </div>
    
</template>

<script>


export default {
  name: 's-toast',

  props:{
      state:{type:String, default:'success'},
      title:{type:String, default:''},
      text:{type:String, default:'Success'},
      btn:{type:String, default:null},
      id:{type: Number}
  },
  components: {
    
  },

  data(){
    return{
        backgroundSuccessDiv : {backgroundColor: '#59D07D'},
        shadowSuccessCard: {boxShadow: '0px 30px 100px rgba(89, 208, 125, 0.2), 0px 10px 20px rgba(89, 208, 125, 0.2)'},
        backgroundDangerDiv : {backgroundColor: '#F80060'},
        shadowDangerCard: {boxShadow: '0px 30px 100px rgba(248, 0, 96, 0.2), 0px 10px 20px rgba(248, 0, 96, 0.2)'},
        backgroundWarningDiv: {backgroundColor: '#FDC02F'},
        shadowWarningCard: {boxShadow: '0px 30px 100px rgba(255, 167, 34, 0.2), 0px 10px 20px rgba(255, 167, 34, 0.2)'},
        backgroundPrimaryDiv : {backgroundColor: '#0E7DFF'},
        shadowPrimaryCard: {boxShadow: '0px 30px 100px rgba(14, 125, 255,0.2), 0px 10px 20px rgba(14, 125, 255,0.2)'}
    };
  },

  computed:{
    
  },

  methods:{
    removeToaste(id){
        this.$refs.toast.style.animation == 'fadeout 0.5s 0.5s';
        var index = this.$store.state.toasts.findIndex(obj => obj.id == id)
        if (index != -1) {
            this.$store.state.toasts.splice(index, 1)
        }
    },
    showPlans(){
      this.$router.push({name:"plans"})
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
