<template>
    <div class="input-interest" v-if="fieldFor=='interests'" :style="pulse?'animation: pulse-warning 1.5s infinite;border-radius:5px;':''">
        <input :id="id?id:Math.random()" :name="name" min="0" :ref="refe" :maxlength="max" v-model="inputValue" :type="type" class="input__field" :placeholder="placeholder"/>
        <svg v-if="inputValue==''" width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.6794 8.64564L8.70243 6.69856C8.6132 6.61068 8.49224 6.56186 8.36533 6.56186H8.04211C8.58941 5.87247 8.91461 5.00537 8.91461 4.0621C8.91461 1.81818 7.06848 0 4.79007 0C2.51166 0 0.665527 1.81818 0.665527 4.0621C0.665527 6.30602 2.51166 8.12421 4.79007 8.12421C5.74784 8.12421 6.62827 7.80393 7.32825 7.26492V7.58324C7.32825 7.70823 7.37782 7.82736 7.46706 7.91524L9.44406 9.86232C9.63046 10.0459 9.93187 10.0459 10.1163 9.86232L10.6775 9.30964C10.8639 9.12606 10.8639 8.82922 10.6794 8.64564ZM4.79007 6.56186C3.38812 6.56186 2.25189 5.44478 2.25189 4.0621C2.25189 2.68138 3.38614 1.56235 4.79007 1.56235C6.19202 1.56235 7.32825 2.67943 7.32825 4.0621C7.32825 5.44283 6.194 6.56186 4.79007 6.56186Z" fill="#7796B9"/>
        </svg>
    </div>
    <div class="input-product" v-else-if="fieldFor=='product'" :style="pulse?'animation: pulse-warning 1.5s infinite;border-radius:5px;':''">
        <input :id="id?id:Math.random()" :name="name" min="0" :ref="refe" :maxlength="max" v-model="inputValue" :type="type" class="input__field" :placeholder="placeholder"/>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.7266 17.293L15.832 13.3984C15.6562 13.2227 15.418 13.125 15.168 13.125H14.5312C15.6094 11.7461 16.25 10.0117 16.25 8.125C16.25 3.63672 12.6133 0 8.125 0C3.63672 0 0 3.63672 0 8.125C0 12.6133 3.63672 16.25 8.125 16.25C10.0117 16.25 11.7461 15.6094 13.125 14.5312V15.168C13.125 15.418 13.2227 15.6562 13.3984 15.832L17.293 19.7266C17.6602 20.0938 18.2539 20.0938 18.6172 19.7266L19.7227 18.6211C20.0898 18.2539 20.0898 17.6602 19.7266 17.293ZM8.125 13.125C5.36328 13.125 3.125 10.8906 3.125 8.125C3.125 5.36328 5.35937 3.125 8.125 3.125C10.8867 3.125 13.125 5.35938 13.125 8.125C13.125 10.8867 10.8906 13.125 8.125 13.125Z" fill="#52749C"/>
        </svg>
    </div>
    <div class="input-settings" v-else-if="fieldFor=='settings'" :class="hasLabel?'hasLabel':''" :style="pulse?'animation: pulse-warning 1.5s infinite;border-radius:5px;':''">
        <div class="input-settings__label" v-if="hasLabel && inputValue.length">{{placeholder}}</div>
        <span v-if="textLeft.length > 0" ref="labelLeft"  style="width: max-content;position: absolute;padding: 8px;background: white;height: 40px;border: 1px solid rgba(42, 140, 255, 0.27);border-radius: 5px 0 0 5px;" >{{textLeft}}</span>
        <input :id="id?id:Math.random()" :name="name"  :style="textLeft.length > 0 ?'padding-left: '+paddingPlaceHolder+'px !important;' : ''" :ref="refe" min="0" :max="max" :maxlength="max" v-model="inputValue" :type="type" class="input__field" :class="hasLabel && inputValue.length?'labelActive':''" :placeholder="placeholder"/>
    </div>
    <div class="input-currency" v-else-if="fieldFor=='currency'" :style="pulse?'animation: pulse-warning 1.5s infinite;border-radius:5px;':''">
        <input :id="id?id:Math.random()" :name="name" :ref="refe" min="0" :maxlength="max" v-model="inputValue" :type="type" :class="disabled?'disabled':''" class="input__field" :placeholder="placeholder"/>
        <span class="input-currency__currency">{{currency}}</span>
    </div>
    <div class="input-offer-currency display-table" v-else-if="fieldFor=='offer-currency'" :style="pulse?'animation: pulse-warning 1.5s infinite;border-radius:5px;':''">
        <div v-if="currencyPlace=='right'">
            <input :id="id?id:Math.random()" :name="name" :ref="refe" min="0" :maxlength="max" v-model="inputValue" :type="type" :class="disabled?'disabled':''" class="input__field" :placeholder="placeholder"/>
            <span class="input-offer-currency__currency">
                <div class="currency-container">
                    <span class="currency">{{currency}}</span>
                </div>
            </span>
        </div>
        <div v-if="currencyPlace=='left'">
            <span class="input-offer-currency__currency isLeft">
                <div class="currency-container">
                    <span class="currency">{{currency}}</span>
                </div>
            </span>
            <input :id="id?id:Math.random()" :name="name" :ref="refe" min="0" :maxlength="max" v-model="inputValue" :type="type" :class="[disabled?'disabled':'']" class="input__field isLeft" :placeholder="placeholder"/>
        </div>

    </div>
</template>

<script>



export default {
  name: 's-input',
  props: {
    hasLabel:{type:Boolean,default:false},
    currencyPlace:{type:String,default:"right"},
    placeholder: {
        type:String,
        default:"",
    },
    disabled: {
        type:Boolean,
        default:false,
    },
    pulse: {
        type:Boolean,
        default:false,
    },
    name: {
        type:String,
        default:"",
    },
    refe: {
        type:String,
        default:"",
    },
    value:null,
    max:{type:[String,Number],default:""},
    min:{type:[String,Number],default:""},
    fieldFor:{type:String,default:"product"},
    textLeft:{type:String,default:""},
    currency:{type:String,default:"USD"},
    type:{type:String,default:"text"},
    id:{}
  },
  data(){
      return{
          inputValue:this.value,
          paddingPlaceHolder: 0
      }
  },
  watch: {
    inputValue() {
      this.$emit('input', this.inputValue);
    },
    value(){
        this.inputValue= this.value
    }
  },
  mounted(){
      if (this.textLeft.length > 0) {
        this.$nextTick(()=>{
            if (this.$refs.labelLeft.clientWidth > 0) {
                this.paddingPlaceHolder = this.$refs.labelLeft.clientWidth+10
            }else{
                this.paddingPlaceHolder = 140;
            }
        })

      }

  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
