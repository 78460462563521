<template>

  <div class="container" id="container">
    <div id="dashboard" >
      <!-- Dashboard header -->
      <div class="dashboard__header mb-4 row no-gutters">

        <!-- Dashboard Title -->
        <div class="col-12 dashboard__header--col" :class="dashboardStep=='overview'?'col-xl-4':'col-xl-6'" ref="topTitle">
          <div class="display-table">
            <svg class="dashboard__header--icon h-100 display-table-cell" width="27" height="30" viewBox="0 0 27 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.625 18.75C19.3003 18.75 18.0828 19.2081 17.1217 19.9743L11.1166 16.2212C11.2945 15.4167 11.2945 14.5832 11.1166 13.7788L17.1217 10.0256C18.0828 10.7919 19.3003 11.25 20.625 11.25C23.7316 11.25 26.25 8.73158 26.25 5.625C26.25 2.51842 23.7316 0 20.625 0C17.5184 0 15 2.51842 15 5.625C15 6.04441 15.0463 6.45293 15.1334 6.84615L9.12832 10.5993C8.16721 9.83315 6.94969 9.375 5.625 9.375C2.51842 9.375 0 11.8934 0 15C0 18.1066 2.51842 20.625 5.625 20.625C6.94969 20.625 8.16721 20.1669 9.12832 19.4007L15.1334 23.1538C15.0446 23.5548 14.9999 23.9643 15 24.375C15 27.4816 17.5184 30 20.625 30C23.7316 30 26.25 27.4816 26.25 24.375C26.25 21.2684 23.7316 18.75 20.625 18.75Z" fill="#2B4D75"/>
            </svg>

            <h1 class="dashboard__header--title m-0 header display-table-cell">{{$t('dashboard.title')}}</h1>
          </div>
        </div>

        <!-- Balance and DateTimePicker -->
        <div class="col-12 text-right dashboard__header--col-right" :class="dashboardStep=='overview'?'col-xl-8':'col-xl-6'">

          <!-- <div class="" > -->

            <date-range-picker class="dashboard__header--date-picker display-table-cell" v-show="dashboardStep=='overview'" @toggle="detectDateRangeState" v-clickaway="closeDateRangePicker" @update="updateDatee" ref="picker" opens="left" :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY', applyLabel: 'Apply', cancelLabel: 'Cancel' }" :showWeekNumbers="false" :timePicker="false" :autoApply="false" v-model="showDateRange" placeholder="mm/dd/yyyy">
              <div slot="input" slot-scope="picker">
                  <button class="btn btn--white display-table px-3" ref="btn-picker">
                      <svg class="display-table-cell mr-2" width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 15.4062C0 16.2861 0.713867 17 1.59375 17H13.2812C14.1611 17 14.875 16.2861 14.875 15.4062V6.375H0V15.4062ZM2.125 9.03125C2.125 8.73906 2.36406 8.5 2.65625 8.5H12.2188C12.5109 8.5 12.75 8.73906 12.75 9.03125V11.1562C12.75 11.4484 12.5109 11.6875 12.2188 11.6875H2.65625C2.36406 11.6875 2.125 11.4484 2.125 11.1562V9.03125ZM13.2812 2.125H11.6875V0.53125C11.6875 0.239062 11.4484 0 11.1562 0H10.0938C9.80156 0 9.5625 0.239062 9.5625 0.53125V2.125H5.3125V0.53125C5.3125 0.239062 5.07344 0 4.78125 0H3.71875C3.42656 0 3.1875 0.239062 3.1875 0.53125V2.125H1.59375C0.713867 2.125 0 2.83887 0 3.71875V5.3125H14.875V3.71875C14.875 2.83887 14.1611 2.125 13.2812 2.125Z" fill="#52749C"/>
                      </svg>
                      <span class="display-table-cell mr-1" v-if="dateFormat(picker.ranges['This year'][0]) == dateFormat(picker.startDate) && dateFormat(picker.ranges['This year'][1]) == dateFormat(picker.endDate)">This Year: </span>
                      <span class="display-table-cell mr-1" v-if="dateFormat(picker.ranges['This month'][0]) == dateFormat(picker.startDate) && dateFormat(picker.ranges['This month'][1]) == dateFormat(picker.endDate)">This Month: </span>
                      <span class="display-table-cell mr-1" v-if="dateFormat(picker.ranges['Last week'][0]) == dateFormat(picker.startDate) && dateFormat(picker.ranges['Last week'][1]) == dateFormat(picker.endDate)">Last Week: </span>
                      <span class="display-table-cell mr-1" v-if="dateFormat(picker.ranges['Last month'][0]) == dateFormat(picker.startDate) && dateFormat(picker.ranges['Last month'][1]) == dateFormat(picker.endDate)">Last Month: </span>
                      <span class="display-table-cell mr-1" v-if="dateFormat(picker.ranges.Today[0]) == dateFormat(picker.startDate) && dateFormat(picker.ranges.Today[1]) == dateFormat(picker.endDate)">Today: </span>
                      <span class="display-table-cell mr-1" v-if="dateFormat(picker.ranges.Yesterday[0]) == dateFormat(picker.startDate) && dateFormat(picker.ranges.Yesterday[1] == picker.endDate)">Yesterday: </span>
                      <span class="display-table-cell" v-if="picker.startDate == null && picker.endDate == null">Select date range</span>
                      <span class="display-table-cell mx-2" v-else>{{dateFormat(picker.startDate)}} - {{dateFormat(picker.endDate)}}</span>
                      <svg class="display-table-cell" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.594098 0.908325L8.22252 0.908325C8.75074 0.908325 9.01526 1.54697 8.64176 1.92047L4.82755 5.73468C4.59602 5.96621 4.2206 5.96621 3.98907 5.73468L0.174856 1.92047C-0.198674 1.54697 0.0658798 0.908325 0.594098 0.908325Z" fill="#52749C"/>
                      </svg>
                  </button>
              </div>
            </date-range-picker>

            <div class="dashboard__header--balance-content">
              <s-card class="dashboard__header--balance card-shadow display-table-cell" :style="dashboardStep=='overview'?'margin-left: 20px;':'margin-left: 0px;'">
                <s-card-body>
                  <span class="dashboard__header--text">{{$t('dashboard.available_balance')}} </span>
                  <span class="dashboard__header--total" :style=" user.available_balance >= 0 ? 'color: #39C736 !important;' : 'color: #F80060 !important;'">{{new Intl.NumberFormat('en-US', {style: 'currency',currency: 'USD',}).format(user.available_balance)}}</span>
                  <div class="dashboard__header--button" :tooltip="user.available_balance  < 100 && !user.is_requested ? 'Balance should be over $100' : user.is_requested ? 'We’ll get back to you via email shortly' : ''">

                    <button v-if="!loadingWithdraw" @click="btnWithdraw()" class="btn" :class="user.available_balance >= 100 && !user.is_requested ? 'btn--primary' : 'btn--white disabled'">{{user.is_requested ? $t('dashboard.request_sent') :$t('dashboard.withdraw')}}</button>
                    <button v-else-if="loadingWithdraw" class="btn btn--primary disabled"><s-loader :isWhite="true"></s-loader></button>

                  </div>
                </s-card-body>
              </s-card>
            </div>

          <!-- </div> -->

        </div>

      </div>

      <div class="dashboard__body">
        <s-card class="dashboard__body--card-sections card-shadow">
          <s-card-header class="dashboard__body--card-header px-0 overflow-hidden">
            <div class="position-relative" >
              <!-- style="min-width: 150px;overflow-x: auto;" -->
              <div class="div-shadow-left hide-in-md" v-if="scrollHeaderCard && scrollHeaderCard !== 'left'"></div>
                <s-tabs style="min-width: 150px;overflow-x: auto;" @scrollIn="getScrollPosition" @currentTab="getCurrentTab" ref="tabs" class="">
                  <s-tab-item :name="$t('overview.title')" :selected="true">
                  </s-tab-item>
                  <s-tab-item :name="$t('my_affiliate_link.title')">
                  </s-tab-item>
                  <s-tab-item :name="$t('activity.title')">
                  </s-tab-item>
                  <s-tab-item :name="$t('creatives.title')">
                  </s-tab-item>
                  <s-tab-item :name="$t('email_swipes.title')">
                  </s-tab-item>
                  <s-tab-item :name="$t('payouts.title')">
                  </s-tab-item>
                  <s-tab-item :name="$t('settings.title')">
                  </s-tab-item>
                  <s-tab-item :name="$t('support.title')">
                  </s-tab-item>
                </s-tabs>
              <div class="div-shadow-right hide-in-md" v-if="scrollHeaderCard && scrollHeaderCard !== 'right'"></div>
            </div>
          </s-card-header>
          <s-card-body class="dashboard__body--card-body">
            <Overview v-show="dashboardStep == $t('overview.title').toLowerCase().replace(/\s/g, '_')" :showDateRange="showDateRange"></Overview>
            <AffiliateLink v-show="dashboardStep == $t('my_affiliate_link.title').toLowerCase().replace(/\s/g, '_')"></AffiliateLink>
            <Activity v-show="dashboardStep == $t('activity.title').toLowerCase().replace(/\s/g, '_')"></Activity>
            <Payouts :newPayout="payoutAdded" v-show="dashboardStep == $t('payouts.title').toLowerCase().replace(/\s/g, '_')"></Payouts>
            <Settings v-show="dashboardStep == $t('settings.title').toLowerCase().replace(/\s/g, '_')"></Settings>
            <Support v-show="dashboardStep == $t('support.title').toLowerCase().replace(/\s/g, '_')"></Support>
            <Creatives v-show="dashboardStep == $t('creatives.title').toLowerCase().replace(/\s/g, '_')"></Creatives>
            <EmailSwipes v-show="dashboardStep == $t('email_swipes.title').toLowerCase().replace(/\s/g, '_')"></EmailSwipes>
          </s-card-body>
        </s-card>
      </div>

      <!-- <div class="device-size"></div> -->

    </div>

  </div>

</template>

<script>
import moment from 'moment';
import DateRangePicker from 'vue2-daterange-picker';
import Overview from '../scalify-views/dashboard/Overview.vue';
import AffiliateLink from '../scalify-views/dashboard/AffiliateLink.vue';
import Activity from '../scalify-views/dashboard/Activity.vue';
import Payouts from '../scalify-views/dashboard/Payouts.vue';
import Settings from '../scalify-views/dashboard/Settings.vue';
import Support from '../scalify-views/dashboard/Support.vue';
import Creatives from '../scalify-views/dashboard/Creatives.vue';
import EmailSwipes from '../scalify-views/dashboard/EmailSwipes.vue';


export default {
  name: 'dashboard',

  components:{
    DateRangePicker,
    AffiliateLink,
    Activity,
    Payouts,
    Settings,
    Support,
    Creatives,
    EmailSwipes,
    Overview
  },

  props: {},

  computed:{
  },

  data(){
    return{
      showDateRange: {},
      valueUpdateDate: null,
      showRangePicker:false,
      loadingWithdraw: false,
      scrollHeaderCard:"left",
      payoutAdded: null
    }
  },

  methods:{
    closeDateRangePicker(){
      this.$nextTick(()=>{
        if(this.showRangePicker){
          this.$refs["btn-picker"].click()
        }
      })
    },
    detectDateRangeState(show){
      this.showRangePicker = show
    },
    async updateDatee(value){
      this.valueUpdateDate = value;
    },
    dateFormat(date){
      const m = moment(date);
      const ord = m.format("ll");
      return ord;
    },

    getScrollPosition(data){
      this.scrollHeaderCard = data
    },
    getCurrentTab(data){
      this.$store.state.dashboardStep = data.toLowerCase().replace(/\s/g, '_');
    },
    async btnWithdraw(){
      this.loadingWithdraw = true;
      try {

        const withdraw = await this.withdraw();
        console.log('withdraw: ', withdraw);
        if (withdraw && withdraw.data && withdraw.data.withdraw) {

          withdraw.data.withdraw.amount = this.user.available_balance;
          console.log('withdraw.data.withdraw: ', withdraw.data.withdraw);
          this.payoutAdded = withdraw.data.withdraw;
          console.log('this.payoutAdded: ', this.payoutAdded);
          this.$store.state.user.upcoming_payout = this.user.upcoming_payout + this.user.available_balance;
          this.$store.state.user.is_requested = true;
          this.$store.state.user.available_balance = 0;

        }else{
          this.notify('error', withdraw.errors[0].message);
        }
        this.loadingWithdraw = false;
      } catch (error) {
        this.loadingWithdraw = false;
        console.log('error: ', error);
      }
    }

  },

  async created(){
    this.showDateRange = {};
    this.showDateRange['endDate'] = moment(new Date()).format("YYYY-MM-DD");
    var makeDate = new Date();
    this.showDateRange['startDate'] = moment(new Date(new Date().setMonth(makeDate.getMonth()-1))).format("YYYY-MM-DD");
  },

  watch:{

    dashboardStep(){
      this.$refs.tabs.goToTab(this.dashboardStep);
    }

  },

  async mounted(){

  }


}
</script>

<style scoped>

</style>
