import Vue from 'vue';

import Tabs from './Tabs.vue'
import TabItem from './TabItem.vue'

Vue.component('s-tabs', Tabs);
Vue.component('s-tab-item', TabItem);

export default{
    Tabs, 
    TabItem ,
}


