<template>
    <div id="support">
      <p class="support__title">{{$t('support.contact_us')}}</p>
      <p class="support__description">{{$t('support.description')}}</p>
      <s-card>
        <s-card-body>
          <div class="support__row row no-gutters mb-4">

            <div class="support__col col-12 col-md-6 mb-4">
              <div class="form-input mb-4">
                <p class="text mb-1">{{$t('support.full_name')}}</p>
                <s-input fieldFor="settings" name="name" v-model="fullName" :placeholder="$t('support.placeholder_full_name')"></s-input>
              </div>
              <div class="form-input mb-4">
                <p class="text mb-1">{{$t('support.reply_to_email')}}</p>
                <s-input fieldFor="settings" name="email" v-model="email" :placeholder="$t('support.placeholder_reply_to_email')"></s-input>
              </div>
              <div class="form-input">
                <p class="text mb-1">{{$t('support.subject')}}</p>
                <s-input fieldFor="settings" name="subject" v-model="subject" :placeholder="$t('support.placeholder_subject')"></s-input>
              </div>
            </div>
            <div class="support__col col-12 col-md-6">
              <div class="form-input h-100">
                <p class="text mb-1">{{$t('support.message')}}</p>
                <s-text-area :showClear="false" class="textarea" name="message" v-model="message" :placeholder="$t('support.placeholder_message')"></s-text-area>
              </div>
            </div>

          </div>
          <div class="support__row row no-gutters">

            <div class="support__col col-12 col-md-6">

            </div>
            <div class="support__col col-12 col-md-6">
              <button v-if="!loadingBtnSend" @click="sendMessage()" class="btn btn--success w-100">{{$t('support.send')}}</button>
              <button v-else class="btn btn--success w-100 disabled">
                <s-loader :isWhite="true"></s-loader>
              </button>
            </div>

          </div>
        </s-card-body>
      </s-card>

    </div>
</template>

<script>

export default {
  name: 'support',

  components:{
  },

  props: {},

  computed:{
  },

  data(){
    return{
      fullName: "",
      email: "",
      subject: "",
      message: "",
      loadingBtnSend: false
    }
  },

  methods:{
    async sendMessage(){

      this.loadingBtnSend = true;
      try {

        if (this.validationSchema.validate({ full_name: this.fullName, email: this.email, subject: this.subject, message: this.message }) && this.validationSchema.validate({ full_name: this.fullName, email: this.email, subject: this.subject, message: this.message }).error) {
          if (this.validationSchema.validate({ full_name: this.fullName }).error && this.validationSchema.validate({ full_name: this.fullName }).error.details[0].context.key == "full_name") {
            this.notify('error',this.$t('error.valid_name'));
          }else if (this.validationSchema.validate({ email: this.email }).error && this.validationSchema.validate({ email: this.email }).error.details[0].context.key == "email") {
            this.notify('error',this.$t('error.valid_email'));
          }else if (this.validationSchema.validate({ subject: this.subject }).error && this.validationSchema.validate({ subject: this.subject }).error.details[0].context.key == "subject") {
            this.notify('error',this.$t('error.valid_subject'));
          }else if (this.validationSchema.validate({ message: this.message }).error && this.validationSchema.validate({ message: this.message }).error.details[0].context.key == "message") {
            this.notify('error',this.$t('error.valid_message'));
          }

        }else{

          const data = {name: this.fullName, email: this.email, subject: this.subject, category: "Affiliate", message: this.  message };
          var api_url_contact_us = 'https://api.scalify.app/contact-us'
          const contact = await this.contactUs(api_url_contact_us, data);
          if (contact && contact.status && contact.status == "success") {
            this.notify('success', this.$t('success.message_received'))
            this.fullName =  ""; this.email =  ""; this.subject =  ""; this.message =  "";
          }else{
            this.notify('success', this.$t('error.something_wrong'))
          }
        }

        this.loadingBtnSend = false;

      } catch (error) {

        console.log('error: ', error);
        this.loadingBtnSend = false;

      }
    }
  },

  async created(){

  },

  watch:{

  },

  async mounted(){

  }


}
</script>

<style scoped>

</style>
