<template>
    
        <div v-show="isActive" class="tab-item-body">
          <slot></slot>
        </div>
      
    
</template>

<script>



export default {
  name: 's-tab-item',
  props: {
    name: { required: true },
    selected: { default: false}
  },
  methods:{
  },
  data() {
    return {
      isActive: this.selected
    };    
    },
    computed: {
      
    },
    
    mounted() {
      // this.isActive = this.selected;
    },
    
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
