<template>
    <div class="tooltip">
        <slot></slot>
        <div class="tooltiptext">{{text}}</div>
    </div>
</template>

<script>
export default {
  name: 'tooltip',
  props:{
    text:{type:String,default:''}
  },
  components: {

  },
  watch:{
    
  },
  data(){
    return{
        
    };
  },
  methods:{
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
