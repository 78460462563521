<template>

    <!-- Inline Dropdown -->
    <div v-if="display=='inline'" class="dropdown" v-clickaway="hideMenu" :style="takeWidth=='content'?{width:'max-content',height:'auto !important'}:{height:'auto !important'}">
        <p class="m-0 display-inline-block dropdown__item-title" @click="showMenu = !showMenu">
          {{selectedOption?selectedOption.name:listOptions[0].name}}
          <svg class="ml-2" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.237535 0.684322L0.718834 0.22601C0.885621 0.0753511 1.08042 0 1.30278 0C1.52973 0 1.72221 0.0753511 1.88054 0.22601L4.99998 3.19558L8.11939 0.226096C8.2777 0.0754366 8.47023 8.5718e-05 8.69709 8.5718e-05C8.91956 8.5718e-05 9.11431 0.0754366 9.28112 0.226096L9.7561 0.684408C9.91873 0.839112 10 1.0245 10 1.24041C10 1.46035 9.91864 1.64364 9.75612 1.79025L5.5777 5.76785C5.42359 5.92257 5.23113 6 5 6C4.77321 6 4.57848 5.9226 4.41601 5.76785L0.237557 1.79025C0.0792236 1.63951 0 1.45624 0 1.24041C-2.19345e-05 1.02853 0.0792007 0.843242 0.237535 0.684322Z" fill="#52749C"/>
          </svg>
        </p>
        <s-card class="dropdown__card--inline flat" v-show="showMenu && listOptions.length" :style="takeWidth=='content'?{width:'max-content !important'}:{}">
          <s-list-group class="dropdown__card__list m-0">
              <s-list-group-item @click.native="selectOption(option)" :class="isSelected(option)?'active':''" class="dropdown__card__list__item" v-for="(option,key) in listOptions" :key="key">
                  <div class="display-table w-100">
                    <span class="display-table-cell w-100">{{option.name}}</span>
                    <div class="ml-4 display-table-cell text-right" v-if="isSelected(option)">
                      <svg class="display-table-cell" width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.75502 11.015L0.205017 6.46495C-0.0683389 6.1916 -0.0683389 5.74838 0.205017 5.475L1.19494 4.48505C1.4683 4.21166 1.91154 4.21166 2.1849 4.48505L5.24999 7.55012L11.8151 0.985046C12.0884 0.71169 12.5317 0.71169 12.805 0.985046L13.795 1.975C14.0683 2.24835 14.0683 2.69157 13.795 2.96495L5.74497 11.015C5.47159 11.2883 5.02837 11.2883 4.75502 11.015Z" fill="#2B4D75"/>
                      </svg>
                    </div>

                  </div>
              </s-list-group-item>
          </s-list-group>
        </s-card>
    </div>

    <!-- button Dropdown -->
    <div v-else-if="display=='button'" class="dropdown h-100" v-clickaway="hideMenu" :style="takeWidth=='content'?{width:'max-content'}:{}">
        <div class="dropdown__label display-table h-100" @click="showMenu = !showMenu" v-if="!reverse">
            <span class="dropdown__label__title display-table-cell pr-3" v-if="label">{{label}}</span>
            <span class="dropdown__button__title display-table-cell pr-2" :class="label?'text-center':''">{{selectedOption?selectedOption.name:""}}</span>
            <svg class="dropdown__button__icon display-table-cell " width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.594098 0.908325L8.22252 0.908325C8.75074 0.908325 9.01526 1.54697 8.64176 1.92047L4.82755 5.73468C4.59602 5.96621 4.2206 5.96621 3.98907 5.73468L0.174856 1.92047C-0.198674 1.54697 0.0658798 0.908325 0.594098 0.908325Z" fill="#52749C"/>
            </svg>
            <!--
            <svg class="dropdown__button__icon display-table-cell " width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.237535 1.01515L0.718834 0.622312C0.885621 0.493175 1.08042 0.428589 1.30278 0.428589C1.52973 0.428589 1.72221 0.493175 1.88054 0.622312L4.99998 3.16765L8.11939 0.622385C8.2777 0.493249 8.47023 0.428662 8.69709 0.428662C8.91956 0.428662 9.11431 0.493249 9.28112 0.622385L9.7561 1.01522C9.91873 1.14783 10 1.30673 10 1.4918C10 1.68031 9.91864 1.83742 9.75612 1.96309L5.5777 5.37246C5.42359 5.50508 5.23113 5.57144 5 5.57144C4.77321 5.57144 4.57848 5.5051 4.41601 5.37246L0.237557 1.96309C0.0792236 1.83388 0 1.67679 0 1.4918C-2.19345e-05 1.31018 0.0792007 1.15137 0.237535 1.01515Z" fill="#52749C"/>
            </svg> -->
        </div>
        <div class="dropdown__label display-table h-100" @click="showMenu = !showMenu" v-if="reverse">
          <span class="dropdown__button__title display-table-cell pr-2">{{selectedOption?selectedOption.name:""}}</span>
          <span class="dropdown__label__title display-table-cell pr-3" v-if="label">{{label}}</span>
          <svg class="dropdown__button__icon display-table-cell " width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.594098 0.908325L8.22252 0.908325C8.75074 0.908325 9.01526 1.54697 8.64176 1.92047L4.82755 5.73468C4.59602 5.96621 4.2206 5.96621 3.98907 5.73468L0.174856 1.92047C-0.198674 1.54697 0.0658798 0.908325 0.594098 0.908325Z" fill="#52749C"/>
          </svg>
          <!-- <svg class="dropdown__button__icon display-table-cell " width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.237535 1.01515L0.718834 0.622312C0.885621 0.493175 1.08042 0.428589 1.30278 0.428589C1.52973 0.428589 1.72221 0.493175 1.88054 0.622312L4.99998 3.16765L8.11939 0.622385C8.2777 0.493249 8.47023 0.428662 8.69709 0.428662C8.91956 0.428662 9.11431 0.493249 9.28112 0.622385L9.7561 1.01522C9.91873 1.14783 10 1.30673 10 1.4918C10 1.68031 9.91864 1.83742 9.75612 1.96309L5.5777 5.37246C5.42359 5.50508 5.23113 5.57144 5 5.57144C4.77321 5.57144 4.57848 5.5051 4.41601 5.37246L0.237557 1.96309C0.0792236 1.83388 0 1.67679 0 1.4918C-2.19345e-05 1.31018 0.0792007 1.15137 0.237535 1.01515Z" fill="#52749C"/>
          </svg> -->
        </div>
        <s-card class="dropdown__card flat" v-show="showMenu && listOptions.length" :style="takeWidth=='content'?{width:'max-content !important'}:{}" >
          <s-list-group class="dropdown__card__list m-0">
              <s-list-group-item @click.native="selectOption(option)" :class="isSelected(option)?'active':''" class="dropdown__card__list__item" v-for="(option,key) in listOptions" :key="key">
                  <div class="display-table w-100">
                    <span class="display-table-cell w-100">{{option.name}}</span>
                    <div class="display-table-cell w-10 text-right ml-4 icone-active" v-if="isSelected(option)">
                      <svg class="display-table-cell" width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.75502 11.015L0.205017 6.46495C-0.0683389 6.1916 -0.0683389 5.74838 0.205017 5.475L1.19494 4.48505C1.4683 4.21166 1.91154 4.21166 2.1849 4.48505L5.24999 7.55012L11.8151 0.985046C12.0884 0.71169 12.5317 0.71169 12.805 0.985046L13.795 1.975C14.0683 2.24835 14.0683 2.69157 13.795 2.96495L5.74497 11.015C5.47159 11.2883 5.02837 11.2883 4.75502 11.015Z" fill="#2B4D75"/>
                      </svg>
                    </div>
                  </div>
              </s-list-group-item>
          </s-list-group>
        </s-card>
    </div>

    <!-- age and gender single dropdowns -->
    <div v-else-if="display=='ageGender'" class="dropdown h-100" v-clickaway="hideMenu" :style="takeWidth=='content'?{width:'max-content'}:{}">
        <div class="dropdown__label display-table h-100" @click="showMenu = !showMenu" v-if="!reverse">
            <span class="dropdown__label__title display-table-cell pr-3" v-if="label">{{label}}</span>
            <span class="dropdown__button__title display-table-cell pr-2" :class="label?'text-center':''">{{selectedOption?selectedOption.value:""}}</span>
            <svg class="dropdown__button__icon display-table-cell " width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.594098 0.908325L8.22252 0.908325C8.75074 0.908325 9.01526 1.54697 8.64176 1.92047L4.82755 5.73468C4.59602 5.96621 4.2206 5.96621 3.98907 5.73468L0.174856 1.92047C-0.198674 1.54697 0.0658798 0.908325 0.594098 0.908325Z" fill="#52749C"/>
            </svg>
        </div>
        <div class="dropdown__label display-table h-100" @click="showMenu = !showMenu" v-if="reverse">
          <span class="dropdown__button__title display-table-cell pr-2">{{selectedOption?selectedOption.value:""}}</span>
          <span class="dropdown__label__title display-table-cell pr-3" v-if="label">{{label}}</span>
          <svg class="dropdown__button__icon display-table-cell " width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.594098 0.908325L8.22252 0.908325C8.75074 0.908325 9.01526 1.54697 8.64176 1.92047L4.82755 5.73468C4.59602 5.96621 4.2206 5.96621 3.98907 5.73468L0.174856 1.92047C-0.198674 1.54697 0.0658798 0.908325 0.594098 0.908325Z" fill="#52749C"/>
          </svg>
        </div>
        <s-card class="dropdown__card flat" v-show="showMenu && listOptions.length" :style="takeWidth=='content'?{width:'max-content !important'}:{}" >
          <s-list-group class="dropdown__card__list m-0">
              <s-list-group-item @click.native="selectOption(option)" :class="isSelected(option)?'active':''" class="dropdown__card__list__item" v-for="(option,key) in listOptions" :key="key">
                  <div class="display-table w-100">
                    <span class="display-table-cell w-100">{{option.name}}</span>
                    <div class="display-table-cell w-10 text-right ml-4 icone-active" v-if="isSelected(option)">
                      <svg class="display-table-cell" width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.75502 11.015L0.205017 6.46495C-0.0683389 6.1916 -0.0683389 5.74838 0.205017 5.475L1.19494 4.48505C1.4683 4.21166 1.91154 4.21166 2.1849 4.48505L5.24999 7.55012L11.8151 0.985046C12.0884 0.71169 12.5317 0.71169 12.805 0.985046L13.795 1.975C14.0683 2.24835 14.0683 2.69157 13.795 2.96495L5.74497 11.015C5.47159 11.2883 5.02837 11.2883 4.75502 11.015Z" fill="#2B4D75"/>
                      </svg>
                    </div>
                  </div>
              </s-list-group-item>
          </s-list-group>
        </s-card>
    </div>

    <!-- select dropdown -->
    <div v-else-if="display=='select'" :style="takeWidth=='content'?{display:'inline-block',width:'max-content'}:{}" class="select-dropdown h-100" v-clickaway="hideMenu">

        <div class="select-dropdown__button display-table" :style="withLogo?'padding: 7px 10px;':''" @click="showMenu = !showMenu" :class="color=='primary'?'dropdown--primary':''">
            <img v-if="withLogo" width="20" height="20" style="height:100%;margin-right: 5px;" src="../../../assets/images/logo-S.svg" >
            <p class="m-0 display-table-cell select-dropdown__button__text w-100 white-space-nowrap text-truncate" :style="svgType=='chevron'?{color:'#04518C'}:{}" :class="svgType=='chevron'?'font-weight-bold':''">{{!isEmpty(selectedOption)&&isPresent?selectedOption.name.replace("ed",""):!isEmpty(selectedOption)&&!isPresent?selectedOption.name:"No "+dropType+" found"}}</p>
            <div class="display-table-cell ml-2 w-content" style="margin-bottom:7px;">
              <!-- w-10 -->
              <svg class="select-dropdown__button__icon display-table-cell" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.594098 0.908325L8.22252 0.908325C8.75074 0.908325 9.01526 1.54697 8.64176 1.92047L4.82755 5.73468C4.59602 5.96621 4.2206 5.96621 3.98907 5.73468L0.174856 1.92047C-0.198674 1.54697 0.0658798 0.908325 0.594098 0.908325Z" fill="#52749C"/>
              </svg>
              <!-- <svg v-if="svgType=='arrow'" class="select-dropdown__button__icon display-table-cell" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.594098 0.908325L8.22252 0.908325C8.75074 0.908325 9.01526 1.54697 8.64176 1.92047L4.82755 5.73468C4.59602 5.96621 4.2206 5.96621 3.98907 5.73468L0.174856 1.92047C-0.198674 1.54697 0.0658798 0.908325 0.594098 0.908325Z" fill="#52749C"/>
              </svg>
              <svg v-if="svgType=='chevron'" class="select-dropdown__button__icon display-table-cell" width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.6437 1.02648L13.9218 0.339015C13.6716 0.113027 13.3794 0 13.0458 0C12.7054 0 12.4167 0.113027 12.1792 0.339015L7.50003 4.79337L2.82092 0.339144C2.58345 0.113155 2.29466 0.000128577 1.95437 0.000128577C1.62066 0.000128577 1.32853 0.113155 1.07832 0.339144L0.365846 1.02661C0.121904 1.25867 0 1.53676 0 1.86062C0 2.19052 0.122039 2.46546 0.365813 2.68538L6.63346 8.65177C6.86462 8.88386 7.15331 9 7.5 9C7.84018 9 8.13228 8.88389 8.37599 8.65177L14.6437 2.68538C14.8812 2.45926 15 2.18435 15 1.86062C15 1.54279 14.8812 1.26486 14.6437 1.02648Z" fill="#52749C"/>
              </svg>
              <svg v-if="svgType=='normal-chevron'" class="select-dropdown__button__icon display-table-cell" width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.08112 6.76113L10.7576 2.15319C11.0808 1.8347 11.0808 1.3197 10.7576 1.0046L9.98046 0.238867C9.65724 -0.0796226 9.13457 -0.0796226 8.81479 0.238867L5.5 3.50508L2.18521 0.238867C1.86199 -0.0796233 1.33932 -0.0796233 1.01954 0.238866L0.242419 1.0046C-0.0808068 1.32309 -0.0808069 1.83809 0.242419 2.15319L4.91888 6.76113C5.23523 7.07962 5.75789 7.07962 6.08112 6.76113Z" fill="#52749C"/>
              </svg> -->
            </div>
        </div>
        <s-card class="select-dropdown__card flat" v-show="showMenu && listOptions.length" :style="takeWidth=='content'?{width:'max-content !important'}:{}">
          <s-list-group class="select-dropdown__card__list m-0">
            <s-list-group-item v-for="(option,key) in includeFirst?listOptions:listOptions.slice(1)" :key="key" @click.native.stop="selectOption(option)" :style="blockOthers && !isSelected(option)?{opacity:'0.7',pointerEvents:'none'}:{}"  :class="[isSelected(option)?'active':'',isDisabled==key?'disabled':'']" class="select-dropdown__card__list__item" >
              <div class="display-table w-100">
                <span class="display-table-cell w-100">{{isPresent?option.name.replace("ed", ""):option.name}}</span>
                <div class="display-table-cell w-10 text-right ml-4 icone-active" v-if="isSelected(option)">
                  <svg class="display-table-cell" width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.75502 11.015L0.205017 6.46495C-0.0683389 6.1916 -0.0683389 5.74838 0.205017 5.475L1.19494 4.48505C1.4683 4.21166 1.91154 4.21166 2.1849 4.48505L5.24999 7.55012L11.8151 0.985046C12.0884 0.71169 12.5317 0.71169 12.805 0.985046L13.795 1.975C14.0683 2.24835 14.0683 2.69157 13.795 2.96495L5.74497 11.015C5.47159 11.2883 5.02837 11.2883 4.75502 11.015Z" fill="#2B4D75"/>
                  </svg>
                </div>
              </div>
            </s-list-group-item>
            <s-list-group-item v-if="paging !== null && paging !== '' && !loadingPaging"   class="cursor-pointer p-0 border-left-0 border-right-0 text-center border-bottom-0">
              <div class="p-1" @click.stop="loadMoreData(paging)">
                <span class=" text-uppercase text-secondary">Learn More</span>
              </div>
            </s-list-group-item>
            <s-list-group-item v-else-if="loadingPaging"  class="cursor-pointer p-2 border-left-0 border-right-0 text-center border-bottom-0">
              <s-loader :type="'scalify'"></s-loader>
            </s-list-group-item>
          </s-list-group>
        </s-card>
    </div>

    <!-- connection dropdown -->
    <div v-else-if="display=='connection'" :style="takeWidth=='content'?{display:'inline-block',width:'max-content'}:{}" class="select-dropdown h-100" v-clickaway="hideMenu">

        <div class="select-dropdown__button display-table" @click="showMenu = !showMenu" :class="color=='primary'?'dropdown--primary':''">
            <p class="m-0 display-table-cell select-dropdown__button__text">{{!isEmpty(selectedOption)?selectedOption.value.name:label}}</p>
            <svg class="select-dropdown__button__icon display-table-cell ml-3" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.594098 0.908325L8.22252 0.908325C8.75074 0.908325 9.01526 1.54697 8.64176 1.92047L4.82755 5.73468C4.59602 5.96621 4.2206 5.96621 3.98907 5.73468L0.174856 1.92047C-0.198674 1.54697 0.0658798 0.908325 0.594098 0.908325Z" fill="#52749C"/>
            </svg>
            <!-- <svg class="select-dropdown__button__icon display-table-cell ml-3" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.594098 0.908325L8.22252 0.908325C8.75074 0.908325 9.01526 1.54697 8.64176 1.92047L4.82755 5.73468C4.59602 5.96621 4.2206 5.96621 3.98907 5.73468L0.174856 1.92047C-0.198674 1.54697 0.0658798 0.908325 0.594098 0.908325Z" fill="#52749C"/>
            </svg> -->
        </div>
        <s-card class="select-dropdown__card flat" v-show="showMenu && listOptions.length" :style="takeWidth=='content'?{width:'max-content !important'}:{}">
          <s-list-group class="select-dropdown__card__list m-0" v-for="(item,index) in listOptions" :key="index" :class="index>0?'border-top':''">
            <s-list-group-item class="select-dropdown__card__list__item title">
              <span class="font-weight-sbold">{{item.name}}</span>
            </s-list-group-item>
            <s-list-group-item @click.native="selectOption(option,item)" :class="[isSelected(option)?'active':'',key==item.events.length-1?'border-radius-0':'']" class="select-dropdown__card__list__item" v-for="(option,key) in item.events" :key="key">
              <div class="display-table w-100">
                <span class="display-table-cell w-100">{{isPresent?option.name.replace("ed", ""):option.name}}</span>
                <div class="display-table-cell w-10 text-right ml-4 icone-active" v-if="isSelected(option)">
                  <svg class="display-table-cell" width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.75502 11.015L0.205017 6.46495C-0.0683389 6.1916 -0.0683389 5.74838 0.205017 5.475L1.19494 4.48505C1.4683 4.21166 1.91154 4.21166 2.1849 4.48505L5.24999 7.55012L11.8151 0.985046C12.0884 0.71169 12.5317 0.71169 12.805 0.985046L13.795 1.975C14.0683 2.24835 14.0683 2.69157 13.795 2.96495L5.74497 11.015C5.47159 11.2883 5.02837 11.2883 4.75502 11.015Z" fill="#2B4D75"/>
                  </svg>
                </div>
              </div>
            </s-list-group-item>
          </s-list-group>
        </s-card>
    </div>

    <!-- campaigns & adsets Dropdown -->
    <div v-else-if="display=='campaigns' || display=='adsets'" :style="takeWidth=='content'?{display:'inline-block',width:'max-content'}:{}" class="select-dropdown h-100" v-clickaway="hideMenu">

        <div class="select-dropdown__button-campaign select-dropdown__button display-table" @click="showMenu = !showMenu">
            <div class="select-dropdown__button-campaign--container" :class="selectedOption.value === null?'display-table':'display-table-cell'">
              <p v-if="selectedOption.value === null" class="m-0 select-dropdown__button__text text-truncate display-table-cell">{{display == 'campaigns' ? 'Choose a Campaign' : 'Choose an Ad Set'}}</p>
              <span v-if="selectedOption.value !== null" class="m-0 select-dropdown__button--text1 text-truncate">{{display == 'campaigns' ? 'CAMPAIGNS' : 'AD SET'}}</span>
              <span v-if="selectedOption.value !== null" class="m-0 select-dropdown__button--text2 text-truncate">{{selectedOption?selectedOption.name:display}}</span>
            </div>
            <svg class="ml-2 display-table-cell" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.594098 0.908325L8.22252 0.908325C8.75074 0.908325 9.01526 1.54697 8.64176 1.92047L4.82755 5.73468C4.59602 5.96621 4.2206 5.96621 3.98907 5.73468L0.174856 1.92047C-0.198674 1.54697 0.0658798 0.908325 0.594098 0.908325Z" fill="#52749C"/>
            </svg>
            <!-- <svg class="select-dropdown__button__icon-icon ml-2" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.594098 0.908325L8.22252 0.908325C8.75074 0.908325 9.01526 1.54697 8.64176 1.92047L4.82755 5.73468C4.59602 5.96621 4.2206 5.96621 3.98907 5.73468L0.174856 1.92047C-0.198674 1.54697 0.0658798 0.908325 0.594098 0.908325Z" fill="#52749C"/>
            </svg> -->
        </div>
        <s-card class="select-dropdown__card flat" v-show="showMenu">
          <s-list-group class="select-dropdown__card__list m-0">
            <s-list-group-item  class="p-0 border-left-0 border-right-0 border-bottom border-top-0 select-dropdown__card__list__item" style="">
              <div class="p-2 select-dropdown__card--search-input" >
                <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.796 12.9707L11.8749 10.0496C11.743 9.91772 11.5643 9.84447 11.3768 9.84447H10.8992C11.7079 8.81022 12.1884 7.50934 12.1884 6.0942C12.1884 2.72774 9.46065 0 6.0942 0C2.72774 0 0 2.72774 0 6.0942C0 9.46065 2.72774 12.1884 6.0942 12.1884C7.50934 12.1884 8.81021 11.7079 9.84447 10.8992V11.3768C9.84447 11.5643 9.91772 11.743 10.0496 11.8749L12.9707 14.796C13.2461 15.0714 13.6914 15.0714 13.9639 14.796L14.7931 13.9668C15.0685 13.6914 15.0685 13.2461 14.796 12.9707ZM6.0942 9.84447C4.02276 9.84447 2.34392 8.16857 2.34392 6.0942C2.34392 4.02276 4.01982 2.34392 6.0942 2.34392C8.16564 2.34392 9.84447 4.01983 9.84447 6.0942C9.84447 8.16564 8.16857 9.84447 6.0942 9.84447Z" fill="#9FB3CA"/></svg>
                <input type="text" v-model="searchText" @keyup="startSearch(display)" :placeholder="display == 'campaigns' ? 'Search Campaign' : 'Search Adsets'">
              </div>
            </s-list-group-item>
            <div v-if="!loadingData">
              <s-list-group-item class="select-dropdown__card__list__item" v-for="(option,key) in includeFirst ? listOptions :listOptions.slice(1) " :key="key" @click.native="selectOption(option)" :class="isSelected(option)?'active':''">
                <span class="mr-4">{{option.name}}</span>
                <svg class="float-right" v-if="isSelected(option)" width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.75502 11.015L0.205017 6.46495C-0.0683389 6.1916 -0.0683389 5.74838 0.205017 5.475L1.19494 4.48505C1.4683 4.21166 1.91154 4.21166 2.1849 4.48505L5.24999 7.55012L11.8151 0.985046C12.0884 0.71169 12.5317 0.71169 12.805 0.985046L13.795 1.975C14.0683 2.24835 14.0683 2.69157 13.795 2.96495L5.74497 11.015C5.47159 11.2883 5.02837 11.2883 4.75502 11.015Z" fill="#2B4D75"/>
                </svg>
              </s-list-group-item>
              <s-list-group-item v-if="paging != null && !loadingPaging"  class="cursor-pointer p-0 border-left-0 border-right-0 text-center border-bottom-0">
                <div class="p-2" @click.stop = "loadMoreData(paging)">
                  <span class="text-uppercase load-more">Learn More</span>
                </div>
              </s-list-group-item>
              <s-list-group-item v-else-if="loadingPaging"  class="cursor-pointer p-2 border-left-0 border-right-0 text-center border-bottom-0">
                <s-loader :type="'scalify'"></s-loader>
              </s-list-group-item>
              <s-list-group-item v-if="listOptions.length == 0"  class="p-2 border-left-0 border-right-0 text-center border-bottom-0 m-0">
                <p>No Data</p>
              </s-list-group-item>
            </div>
            <div v-else class="px-2 pb-2">
              <s-loader></s-loader>
            </div>

          </s-list-group>
        </s-card>
    </div>



</template>

<script>
export default {
  name: 's-dropdown',
  components:{
  },
  props: {
    options:{type:[Array,Object],default:() =>{return []}}, //the list of options
    label:String, //label or title of dropdown button
    loadMore: {type:String,default:null}, //loadmore request
    value:{type:Object,default(){return {}}}, //the value
    data:{type:Object,default(){return {}}}, //the data of type metric
    display:{type:String,default:"button"}, //dropdown display type
    reverse:{type:Boolean,default:false}, //reverse the dropdown type button
    default:{type:Object,default(){return {}}}, // set the default selected option from list
    takeWidth:{type:String,default:"all"}, //take all the full width or only content
    includeFirst:{type:Boolean,default:true}, //include the first list item in the dropdown
    selectable:{type:Boolean,default:true}, //the drop is selectable or not
    takeFirstListElement:{type:Boolean,default:true}, // taking or not the first element of list if the value is empty
    order:{type:Number,default:null},
    idCampaign:{type:String},
    color:{type:String,default:"white"},
    isPresent:{type:Boolean,default:false},
    hasTitles:{type:Boolean,default:false},
    isDisabled: {type:Number,default:-1},
    render:{type:Number},
    blockOthers:{type:Boolean,default:false},
    type:{type:String,default:"google"},
    dropType:{type:String,default:"data"},
    svgType:{type:String,default:"arrow"},
    offset:{type:[String,Number],default:0},
    isSm:{type:Boolean,default:false},
    withLogo:{type:Boolean,default:false},
    listMetrics:{type:[Array,Object],default:() =>{return []}}
  },
  updated(){
    if(this.display=="ctr"){
      this.loading=true
    }
  },
  mounted(){
    if(this.display=='metric'){
      this.addColors()
    }
  },
  created(){
    this.selectedOption={}
    this.checkDropDown();
    this.$forceUpdate()
  },
  destroyed(){
    // this.selectedOption=this.value
  },
  data(){
    return{
        selectedOption:this.value,
        listOptions:this.options,
        showMenu:false,
        previousSelected:{},
        loadingPaging : false,
        paging: this.loadMore,
        loading:false,
        colors:['#3970E8','#6CD16A','#FF477E'],
        searchText: '',
        loadingData: false,
        showSubMenu:false,
        listSubMenu:[],
        indexSelected:47,
        mouseIn:false
    }
  },
  watch: {
    showMenu(){
      this.$emit("menu-open",this.showMenu)
      if(!this.showMenu){
        this.showSubMenu = this.showMenu
        this.listSubMenu = []
      }
    },
    options(){
      this.listOptions=this.options
      // this.selectedOption = {}
      if(this.takeFirstListElement){
        this.checkDropDown();
      }
    },
    selectedOption(){
      // this.$emit('input',this.selectedOption)
      this.$emit('update',this.selectedOption)

      this.$emit("change",this.previousSelected)
      this.$forceUpdate()
    },
    value(){
      if(!this.isEmpty(this.value)){
        this.selectedOption=this.value
      }
    },
    loadMore(){
      this.paging=this.loadMore;
    },
    data:{
      deep:true,
      handler(){
        if(this.display=='metric'){
          this.$forceUpdate()
        }
      }
    }
  },
  methods:{
    connectGAView(type){
      if(type=="connect"){
        this.$router.push({name:"settings",params:{from:'google'}})
      }
      else{
        this.$router.push({name:"settings",params:{from:'stores'}})
      }
    },
    checkDropDown(){
      let notInList = false
      if(!this.isEmpty(this.value)){
        let idx = this.listOptions.find(obj => obj == this.value)
        if(!idx){
          notInList = true
        }
      }
      if(this.isEmpty(this.default) && this.listOptions.length && (this.isEmpty(this.value) || notInList) && !this.hasTitles){
        if(this.takeFirstListElement && this.listOptions[0].value !== -1){
          this.selectedOption=this.listOptions[0]
        }else{
          this.selectedOption={}
        }
        this.$emit('input',this.selectedOption)
        this.$emit('update',this.selectedOption)
      }else if(!this.isEmpty(this.default) && (this.isEmpty(this.value) || notInList) && !this.hasTitles){
        this.selectedOption=this.default
        this.$emit('input',this.selectedOption)
        this.$emit('update',this.selectedOption)
      }
      else if(this.hasTitles){
        this.selectedOption = {}
      }
      else{
        this.selectedOption=this.value
        this.$emit('input',this.selectedOption)
        this.$emit('update',this.selectedOption)
      }
    },
    addColors(){
      if(this.order !== null && this.colors.length && this.colors[this.order]){
        return
      }
      else{
        var colors=['#3970E8','#6CD16A','#FF477E']
        this.colors = this.colors.concat(colors)
        this.$forceUpdate()
        this.addColors()
      }
    },

    mouseOverCard(){
      this.mouseIn = false
      this.$forceUpdate()
    },

    mouseOver(){
      this.mouseIn = true
    },

    mouseLeave(){
      this.mouseIn = false
    },

    selectSubOption(option){
      // this.indexSelected = (47 * index+1) + 20

      // this.$nextTick(()=>{
        this.listSubMenu = option
        // this.$nextTick(()=>{})
        this.$forceUpdate()
        this.showSubMenu = true
        this.$forceUpdate()
      // })
    },

    // remove dropdown type metric
    removeDropdown(){
      this.$emit("remove",this.data)
    },

    // select a menu option
    selectOption(item){

      if(this.selectable && !this.hasTitles){
        this.previousSelected=this.selectedOption
        this.selectedOption=item
        this.$emit('input',this.selectedOption)
        this.$emit('update',this.selectedOption)

        this.$emit("change",this.previousSelected)
        if(this.display=='inline'||this.display=='select' || this.display=='campaigns' || this.display=='adsets' || this.display=='metric'){
          this.showMenu=false
        }
      }
      else if(!this.selectable && !this.hasTitles){
        this.$emit("text",item.value)
      }
      else if(this.hasTitles){
        var obj = {name:parent.name,value:item}
        this.previousSelected= {...this.selectedOption}
        this.selectedOption = obj
        this.$emit('input',this.selectedOption)
        this.$emit('update',this.selectedOption)
        // this.$emit('change',this.previousSelected)

      }

    },
    //Hide Menu
    hideMenu(){
      if(this.showMenu){
          this.showMenu=false
          this.showSubMenu = false
          this.listSubMenu = []
      }
      else{
          this.showSubMenu = false
          return
      }
    },
    // Check an item if selected
    isSelected(item){

      if(!this.hasTitles && this.selectedOption && this.selectedOption.value ==  item.value && this.selectedOption.name ==  item.name){
        return true
      }
      else if(this.hasTitles && this.selectedOption && this.selectedOption.value == item){
        return true
      }
      else{
          return false
      }
    },
    //Load More

    async loadMoreData(paging){
      this.loadingPaging = true;
      try{
        const pagination = await this.getAPIData(paging)
        if (this.display == "select") {
          if(paging.includes("customconversions")){
            var dt = pagination.data
            this.$emit("more",dt)
          }
          this.listOptions = this.listOptions.concat(pagination.data);
        }else if(this.display == "campaigns"){
          pagination.data.map((campaign)=>{
            this.listOptions.push({value: campaign.id, name: campaign.name, objective:campaign.objective, status:campaign.status })
          })
        }else if(this.display == "adsets"){
          pagination.data.map((adset)=>{
            this.listOptions.push({value: adset.id, name: adset.name, status:adset.status })
          })
        }
        if (pagination.paging && pagination.paging.next) {
            this.paging = pagination.paging.next;
        }else{
            this.paging = null;
        }
        this.loadingPaging = false;
      }
      catch(err){
        this.loadingPaging = false
        this.handleErrors(err)
      }
    },
    // search campaign or adset:
    startSearch(type){
      this.loadingData=true;
      this.includeFirst = true;
      this.listOptions = [];
      var t = 700;
      clearTimeout(window.popTimeout);
      window.popTimeout = setTimeout(async () => {
        try{
          if(type=="campaigns"){
              //campaigns request for search
              var campaigns;
              if (this.searchText == "") {
                campaigns = await this.getAdAccountCampaigns()
              }else{
                campaigns = await this.getAdAccountCampaigns(this.searchText)
              }
              campaigns.data.map((campaign)=>{
                this.listOptions.push({value: campaign.id, name: campaign.name, status:campaign.status })
              })
              if (campaigns.paging && campaigns.paging.next) {
                  this.paging = campaigns.paging.next;
              }else{
                  this.paging = null;
              }
              this.loadingData=false
          }
          else if(type=="adsets"){
              //adsets request for search
              let campaingAdsets;
              if (this.searchText == "") {
                campaingAdsets = await this.getCampaignAdSets(this.idCampaign)
              }else{
                campaingAdsets = await this.getCampaignAdSets(this.idCampaign,this.searchText)
              }
              campaingAdsets.data.map((adset)=>{
                this.listOptions.push({value: adset.id, name: adset.name, status:adset.status })
            })
              if (campaingAdsets.paging && campaingAdsets.paging.next) {
                  this.paging = campaingAdsets.paging.next;
              }else{
                  this.paging = null;
              }
              this.loadingData=false
          }
        }
        catch(err){
          this.loadingData = false
          this.handleErrors(err)
        }
      },t)
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>