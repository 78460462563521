<template>
<!-- display-inline-flex -->
    <div class="">
      <div class="loader" :class="isBlue?'isBlue':''" v-if="type=='normal'">
        <div :style="[isWhite?{background:'white !important'}:{},isBlue?{background:'#2B4D75 !important'}:{}]"></div>
        <div :style="[isWhite?{background:'white !important'}:{},isBlue?{background:'#2B4D75 !important'}:{}]"></div>
        <div :style="[isWhite?{background:'white !important'}:{},isBlue?{background:'#2B4D75 !important'}:{}]"></div>
      </div>
      <img v-if="type=='scalify'" width="20" height="30" src="./../../../assets/images/scalify.gif" class="display-table-cell" />
      <div class="loader-circullar" v-if="type=='circullar'">
        <div class="lds-dual-ring"></div>
      </div>
    </div>
</template>

<script>



export default {
  name: 's-loader',
  data(){
    return {
    
    }
  },
  props: {
    isWhite:{type:Boolean,default:false},
    isBlue:{type:Boolean,default:false},
    type:{type:String,default:"normal"}
  },
  methods:{
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
