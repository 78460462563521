<template>
    <div id="login">


        <svg class="login__right-shape" width="316" height="315" viewBox="0 0 316 315" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M126.676 8.14362L246.713 23.1707C270.077 26.0954 288.638 44.1625 292.145 67.3921L309.994 185.622C312.952 205.209 304.596 224.79 288.394 236.242L201.368 297.753C185.347 309.078 164.337 310.554 146.887 301.58L55.6303 254.653C42.1728 247.732 32.3604 235.359 28.7054 220.7L5.9966 129.624C1.30904 110.824 7.39092 90.9753 21.8145 78.0007L84.9192 21.2351C96.2706 11.024 111.513 6.24538 126.676 8.14362Z" fill="#DFEEFF"/>
        </svg>
        <svg class="login__left-shape" width="406" height="406" viewBox="0 0 406 406" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M347.856 157.694L276.507 60.0004C262.62 40.986 237.819 33.5135 215.802 41.7107L103.747 83.4311C85.183 90.3428 72.1104 107.145 69.9513 126.868L58.3547 232.805C56.2198 252.308 65.1168 271.398 81.4262 282.308L166.719 339.362C179.297 347.776 194.876 350.358 209.468 346.446L300.132 322.143C318.847 317.126 333.257 302.182 337.61 283.277L356.655 200.561C360.081 185.682 356.869 170.035 347.856 157.694Z" fill="#DFEEFF"/>
        </svg>

        <div class="login__wrapper">
          <!-- cookiesIsDisabled -->
          <!-- <s-card v-if="false" class="primary-alert">
            <s-card-body class="p-2 pl-4 display-table w-100">
              <div class="primary-alert--div"></div>
              <p class="primary-alert--text display-table-cell w-100">Please enable your browser cookies to be able to sign in.</p>
            </s-card-body>
          </s-card> -->
          <svg class="login__wrapper__left-top-shape" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle r="8" transform="matrix(1 0 0 -1 8 8)" fill="#2A8CFF"/>
          </svg>
          <svg class="login__wrapper__left-bottom-shape" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle r="7" transform="matrix(1 0 0 -1 7 7)" fill="#FFC04B"/>
          </svg>
          <svg class="login__wrapper__right-top-shape" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="5" cy="5" r="5" fill="#3FC886"/>
          </svg>
          <svg class="login__wrapper__right-bottom-shape" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="10" r="10" fill="#E65D5B"/>
          </svg>

          <!-- scalify logo -->
          <div class="w-100">
            <img src="../../../assets/images/logo-S.svg" class="login_wrapper__logo" />

            <!-- Components (signIn) -->
            <sign-in @enable-cookies="enableCookies"></sign-in>

          </div>
          <div class="login__wrapper__signup">
            <p class="display-block">{{$t('login.dont_have_account')}}<span @click.stop="signUp">{{$t('login.sign_up_here')}}</span></p>
          </div>
        </div>

        <!-- <Notes></Notes> -->
    </div>
</template>

<script>
import SignIn from './SignIn.vue'
// import Notes from './Notes.vue'
export default {
  name: 'login',
  props:{

  },
  components: {
    SignIn
    // Notes
  },
  watch:{

  },
  data(){
    return{
      cookiesIsDisabled: false
    };
  },
  created(){
    // if(this.$Cookies.get("X-Scalify-Token") && this.$Cookies.get("X-Scalify-CurrentAccount") && this.$Cookies.get("X-Scalify-CurrentProfile") && this.$Cookies.get("X-Scalify-CurrentAdAccount") && this.$Cookies.get("X-Scalify-CurrentStore")){
    //   this.$router.push({name:'dashboard'})
    // }
    // if (this.$route.query && this.$route.query.t) {
    //   this.$Cookies.set("X-Scalify-InvitationToken", this.$route.query.t, { expires: 1 });
    // }

  },
  mounted(){
    // if (this.$Cookies.get("X-Scalify-Token")) {
    //   this.$Cookies.remove("X-Scalify-Token");
    // }
    // if (this.$Cookies.get("X-Scalify-CurrentAccount")) {
    //   this.$Cookies.remove("X-Scalify-CurrentAccount");
    // }
    // if (this.$Cookies.get("X-Scalify-CurrentProfile")) {
    //   this.$Cookies.remove("X-Scalify-CurrentProfile");
    // }
    // if (this.$Cookies.get("X-Scalify-CurrentAdAccount")) {
    //   this.$Cookies.remove("X-Scalify-CurrentAdAccount");
    // }
    // if (this.$Cookies.get("X-Scalify-CurrentStore")) {
    //   this.$Cookies.remove("X-Scalify-CurrentStore");
    // }
  },

  methods:{
    signUp(){
      this.$router.push("register")
    },
    enableCookies(data){
      this.cookiesIsDisabled = data;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
