<template>

    <div class="dropdown-group">
        <div class="display-table dropdown-group--row row no-gutters">
            <div class="dropdown-group--col-from display-table-cell">
                <s-dropdown display="ageGender" label="From" :class="large?'dropdown--lg':''" class="dropdown-group__from" takeWidth="content" :default="selectedFrom" v-model="selectedFrom" :options="from"></s-dropdown>
            </div>
            <div class="dropdown-group--col-to display-table-cell">
                <s-dropdown display="ageGender" label="To" :class="large?'dropdown--lg':''" class="dropdown-group__to" takeWidth="content" :default="selectedTo" v-model="selectedTo" :options="to"></s-dropdown>
            </div>
            <div class="dropdown-group--col-split display-table-cell">
                <s-dropdown :class="large?'dropdown--lg':''" class="dropdown-group__split" takeWidth="content" :default="selectedSplit" v-model="selectedSplit" :options="split" :reverse="true" :label="selectedSplit.value=='65'?'No Split':'Split'"></s-dropdown>
            </div>
            <div class="dropdown-group--col-genders display-table-cell">
                <s-dropdown :class="large?'dropdown--lg':''" class="dropdown-group__genders" takeWidth="content" :default="selectedGender" v-model="selectedGender" :options="genders" label="Genders"></s-dropdown>
            </div>
        </div>
    </div>
</template>

<script>



export default {
  name: 's-dropdown-group',
  props: {
    from:{default:() => []},
    to:{default:() => []},
    split:{default:() => []},
    genders:{default:() => []},
    value:{},
    large:{type:Boolean,default:true}
  },
  data(){
    return{
        selectedFrom:{},
        selectedTo:{},
        selectedSplit:{},
        selectedGender:{},
        groupVariation:this.value
    }
  },
  mounted(){
    this.selectedFrom = this.groupVariation.from;
    this.selectedTo = this.groupVariation.to;
    this.selectedSplit = this.groupVariation.splitEvery;
    this.selectedGender = this.groupVariation.gender;
  },
  watch: {
        value(){
            this.groupVariation=this.value
        },
        selectedFrom(){
            this.groupVariation.from=this.selectedFrom
        },
        selectedTo(){
            this.groupVariation.to=this.selectedTo
        },
        selectedSplit(){
            this.groupVariation.splitEvery=this.selectedSplit
        },
        selectedGender(){
            this.groupVariation.gender=this.selectedGender
        },
        groupVariation(){
            this.$emit('input',this.groupVariation)
        }
  },
  methods:{

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
