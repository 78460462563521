<template>
    <div id="emails">

      <p>{{$t('email_swipes.description1')}}</p>
      <p>{{$t('email_swipes.description2')}}</p>

      <div class="emails__row row no-gutters">

        <!-- Emails -->
        <div class="emails__col col-md-12 col-lg-4" v-for="(email, indexEmail) in emails" :key="indexEmail">
          <s-card class="h-100">
            <s-card-header class="">
              <h3 class="mb-2">{{email.title}}</h3>
              <p><span class='font-weight-sbold pr-1'>Angle:</span>{{email.description}}</p>
            </s-card-header>
            <s-card-body>
              <s-text-area ref="textarea1" :showClear="false" rows="20" class="textarea" name="message" v-model="email.email"></s-text-area>

            </s-card-body>
            <s-card-footer>
              <button  @click="copyText(1)" class="btn btn--primary w-100">{{$t('email_swipes.copy')}}</button>
            </s-card-footer>
          </s-card>
        </div>

      </div>
    </div>
</template>

<script>

export default {
  name: 'emails',

  components:{
  },

  props: {

  },

  data(){
    return{
      emails: [{title: this.$t('email_swipes.email_1.title') , description: this.$t('email_swipes.email_1.description') , email: this.$t('email_swipes.email_1.email')},
      {title: this.$t('email_swipes.email_2.title') , description: this.$t('email_swipes.email_2.description') , email: this.$t('email_swipes.email_2.email')},
      {title: this.$t('email_swipes.email_3.title') , description: this.$t('email_swipes.email_3.description') , email: this.$t('email_swipes.email_3.email')}
      ]
    }
  },

  async mounted(){

  },

  methods:{
    copyText(index){
      var copyText = this.$refs[`textarea${index}`].$refs.textArea;
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");
      this.notify('success', this.$t('success.text_copied'));
    }
  },

  watch:{

  },




}
</script>
