<template>
    <div id="login">
        <div class="login__wrapper">
          <!-- scalify logo -->
          <div>
            <img src="../../../assets/images/logo-S.svg" class="login_wrapper__logo mb-4" />

            <!-- Components (reset password) -->
            <reset></reset>
          </div>
        </div>
        <!-- <Notes></Notes> -->
    </div>
</template>

<script>
import Reset from './ResetPasswordForm.vue'
// import Notes from './Notes.vue'
export default {
  name: 'reset-password',
  props:{

  },
  components: {
    Reset
    // Notes
  },
  watch:{

  },
  data(){
    return{
      isSignIn:true,
      isSignUp: false,
      isForgotPassword:false
    };
  },
  methods:{

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
