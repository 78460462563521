import Vue from 'vue';

import Checkbox from './Checkbox.vue'
import Toggle from './Toggle.vue'

Vue.component('s-checkbox',Checkbox);
Vue.component('s-toggle',Toggle);


export default{
    Checkbox,
    Toggle
}

