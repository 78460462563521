import Vue from 'vue';

import Button from './Button.vue'
import ButtonGroup from './ButtonGroup.vue'
import ButtonChartType from './ButtonChartType.vue'

Vue.component('s-button',Button);
Vue.component('s-button-group',ButtonGroup);
Vue.component('s-button-chart',ButtonChartType);


export default{
    Button,
    ButtonGroup,
    ButtonChartType
}

