<template>
    <div id="register">
        <div class="register__wrapper">

          <div>
            <!-- cookiesIsDisabled -->
            <!-- <s-card v-if="false" class="primary-alert mb-4" style="max-width: 350px;">
              <s-card-body class="p-2 pl-4 display-table w-100">
                <div class="primary-alert--div"></div>
                <p class="primary-alert--text display-table-cell w-100">Please enable your browser cookies to be able to sign in.</p>
              </s-card-body>
            </s-card> -->
            <img src="../../../assets/images/logo-S.svg" class="register_wrapper__logo mb-4" />
            <!-- Components (signIn or signUp) -->
            <sign-up @enable-cookies="enableCookies"></sign-up>
          </div>
        </div>
        <Notes></Notes>
    </div>
</template>

<script>

import SignUp from './SignUp.vue'
import Notes from './Notes.vue'

export default {
  name: 'Register',
  props:{

  },
  components: {
    Notes,
    SignUp,
  },
  watch:{

  },
  data(){
    return{
      cookiesIsDisabled: false
    };
  },
  methods:{
    enableCookies(data){
      this.cookiesIsDisabled = data;
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
