import Vue from 'vue';

import ListGroup from './ListGroup.vue'
import ListGroupItem from './ListGroupItem.vue'

Vue.component('s-list-group', ListGroup);
Vue.component('s-list-group-item', ListGroupItem);

export default{
    ListGroup,
    ListGroupItem  
}


