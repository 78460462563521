import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../components/scalify-views/Dashboard.vue'
import Login from '../components/scalify-views/login/Login.vue'
import Register from '../components/scalify-views/login/Register.vue'
import ForgetPassword from '../components/scalify-views/login/ForgetPassword.vue'
import ResetPassword from '../components/scalify-views/login/ResetPassword.vue'
import CheckInbox from '../components/scalify-views/login/CheckInbox.vue'
import CompleteSubscribe from '../components/scalify-views/login/CompleteSubscribe.vue'

import AllComponents from '../components/scalify-views/AllComponents.vue'

Vue.use(VueRouter)

const routes = [

  { path: '/', name: 'dashboard', component: Dashboard },


  { path: '/login', name: 'login', meta:{ layout:"no-sidebar"}, component: Login },
  { path: '/register', name: 'register', meta:{ layout:"no-sidebar"}, component: Register },
  { path: '/reset-password', name: 'reset-password',meta:{ layout:"no-sidebar"}, component: ResetPassword },
  { path: '/forgot-password', name: 'forgot-password', meta:{ layout:"no-sidebar"}, component: ForgetPassword },
  { path: '/check-inbox', name: 'check-inbox', meta:{ layout:"no-sidebar"}, component: CheckInbox },
  { path: '/complete-subscribe', name: 'complete-subscribe', meta:{ layout:"no-sidebar"}, component: CompleteSubscribe },



  { path: '/components', name: 'Components', component: AllComponents },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
