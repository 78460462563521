<template>
    <div class="container">
        <div id="complete-subscribe">

            <div class="complete-subscribe__wrapper pt-4">
                <div class="w-100 h-100">
                    <div class="w-100 position-relative mb-3 complete-subscribe__wrapper--top-header">
                        <div class="display-table-cell mr-2 w-100 text-left logo-and-title" >
                            <div class="display-table w-100">
                                <img height="30" src="../../../assets/images/logo-full.svg" class="display-table-cell mr-4">
                                <p class="display-table-cell">Welcome. Let’s setup your account.</p>
                            </div>
                        </div>
                        <div class="display-table-cell white-space-nowrap text-right">
                            <!-- <button class="btn btn--white display-table-cell mr-2" @click="openHelpCenter()">
                                <span class="text-veniceblue font-weight-sbold">Support</span>
                            </button> -->
                            <button class="btn btn--white display-table-cell" @click="logout()">
                                <svg class="mr-2 display-table-cell" width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.7924 6.22118L9.79215 11.2214C9.3457 11.6679 8.57185 11.3554 8.57185 10.7154V7.85817H4.52403C4.12818 7.85817 3.80971 7.5397 3.80971 7.14384V4.28656C3.80971 3.89071 4.12818 3.57224 4.52403 3.57224H8.57185V0.714954C8.57185 0.0780178 9.34272 -0.237474 9.79215 0.208977L14.7924 5.20922C15.0692 5.489 15.0692 5.9414 14.7924 6.22118ZM5.71457 11.0726V9.88208C5.71457 9.68564 5.55385 9.52491 5.35741 9.52491H2.85728C2.33047 9.52491 1.90486 9.0993 1.90486 8.57249V2.85792C1.90486 2.33111 2.33047 1.90549 2.85728 1.90549H5.35741C5.55385 1.90549 5.71457 1.74477 5.71457 1.54833V0.357794C5.71457 0.161355 5.55385 0.000633025 5.35741 0.000633025H2.85728C1.27983 0.000633025 0 1.28046 0 2.85792V8.57249C0 10.1499 1.27983 11.4298 2.85728 11.4298H5.35741C5.55385 11.4298 5.71457 11.269 5.71457 11.0726Z" fill="#2B4D75"/></svg>
                                <span class="text-veniceblue font-weight-sbold">Sign out</span>
                            </button>
                        </div>
                    </div>
                    <div v-if="step == 'done' && user && !user.approved && !loadingSteps">

                      <s-card class="border-0 border-radius complete-subscribe__wrapper--card">

                        <s-card-body class="complete-subscribe__wrapper--card-done">

                          <svg class="icone" width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="52" cy="52" r="48.1" fill="#3FC886" stroke="#3FC886" stroke-width="7.8"/>
                            <path d="M41.7373 75.7573L20.9372 54.8376C19.6876 53.5808 19.6876 51.543 20.9372 50.2861L25.4626 45.7346C26.7122 44.4776 28.7385 44.4776 29.9881 45.7346L44 59.8269L74.0119 29.6426C75.2616 28.3857 77.2878 28.3857 78.5374 29.6426L83.0628 34.1941C84.3125 35.4509 84.3125 37.4887 83.0628 38.7456L46.2628 75.7574C45.013 77.0142 42.9869 77.0142 41.7373 75.7573Z" fill="white"/>
                          </svg>

                          <h2 class="title">Application in Review</h2>

                          <p class="para">Thank you for joining the Scalify Partners Program.</p>
                          <p class="para">We will review your application and get back to you via email shortly.</p>


                        </s-card-body>

                      </s-card>

                    </div>
                    <div v-else>
                      <s-card class="border-0 border-radius mb-3 complete-subscribe__wrapper--card" v-if="!loadingSteps">
                          <s-card-header class="complete-subscribe__wrapper--card-steps border-0 border-radius" id="headerScroller" style="overflow-x:auto;">
                              <div class="display-block text-center" style="min-width:100%;width:max-content;">
                                  <div :id="'scalify-password'" v-if="steps.includes('password')" class="complete-subscribe__container mr-5">
                                      <div class="complete-subscribe__wrapper--div-rounded display-table-cell" :class="checked.includes('password') ? 'checked' : step=='password'?'active-div':''">
                                          <div class="display-table">
                                              <svg class="display-table-cell" v-if="checked.includes('password')" width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M6.1136 13.9849L0.263593 8.10122C-0.0878643 7.74774 -0.0878643 7.17462 0.263593 6.8211L1.53636 5.54099C1.88781 5.18747 2.4577 5.18747 2.80915 5.54099L6.75 9.50445L15.1908 1.01511C15.5423 0.66163 16.1122 0.66163 16.4636 1.01511L17.7364 2.29522C18.0879 2.6487 18.0879 3.22183 17.7364 3.57534L7.3864 13.9849C7.0349 14.3384 6.46506 14.3384 6.1136 13.9849Z" fill="white"/>
                                              </svg>
                                              <span v-else class="display-table-cell">{{steps.indexOf('password')+1}}</span>
                                          </div>
                                      </div>
                                      <p :class="step == 'password'?'active-text':''" class="complete-subscribe__wrapper--title display-table-cell">Name and Password</p>
                                      <div class="complete-subscribe__wrapper--isCurrent" v-if="step == 'password'"></div>
                                  </div>

                                  <div :id="'scalify-about-you'" v-if="steps.includes('about-you')" class="complete-subscribe__container mr-5">
                                      <div class="display-table-cell complete-subscribe__wrapper--div-rounded" :class="checked.includes('about-you') ? 'checked' : step=='about-you'?'active-div':''">
                                          <div class="display-table">
                                              <svg class="display-table-cell" v-if="checked.includes('about-you')" width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M6.1136 13.9849L0.263593 8.10122C-0.0878643 7.74774 -0.0878643 7.17462 0.263593 6.8211L1.53636 5.54099C1.88781 5.18747 2.4577 5.18747 2.80915 5.54099L6.75 9.50445L15.1908 1.01511C15.5423 0.66163 16.1122 0.66163 16.4636 1.01511L17.7364 2.29522C18.0879 2.6487 18.0879 3.22183 17.7364 3.57534L7.3864 13.9849C7.0349 14.3384 6.46506 14.3384 6.1136 13.9849Z" fill="white"/>
                                              </svg>
                                              <span v-else class="display-table-cell">{{steps.indexOf('about-you')+1}}</span>
                                          </div>
                                      </div>
                                      <p :class="step == 'about-you'?'active-text':''" class="display-table-cell complete-subscribe__wrapper--title">About You</p>
                                      <div class="complete-subscribe__wrapper--isCurrent" v-if="step == 'about-you'"></div>
                                  </div>

                              </div>
                          </s-card-header>
                      </s-card>

                      <s-card class="border-0 border-radius complete-subscribe__wrapper--card">
                          <s-card-body class="complete-subscribe__wrapper--card-body border-0 border-radius" v-if="!loadingSteps">
                              <form @submit.prevent="saveNameAndPassword" v-if="step == 'password'" autocomplete="on">
                                <div class="complete-subscribe__wrapper--password m-auto">
                                      <h1 class="complete-subscribe__password--title header">Name and Password</h1>
                                      <p class="complete-subscribe__password--para">Please enter your fullname and new password.</p>
                                      <s-input v-if="user" v-show="false" :hasLabel="true" name="name" type="email" :value="user.email" class="complete-subscribe__password--name" fieldFor="settings" placeholder="Email"></s-input>
                                      <s-input :hasLabel="true" name="username" v-model="name" class="complete-subscribe__password--name" fieldFor="settings" :placeholder="name==''?'First Name':'First Name'"></s-input>
                                      <s-input :hasLabel="true" name="password" type="password" v-model="password" class="complete-subscribe__password--password" fieldFor="settings" :placeholder="password==''?'New Password':'New Password'"></s-input>
                                      <s-button v-if="!loadingPassword" class="complete-subscribe__password--btn btn btn--success btn--lg w-100">Next</s-button>
                                      <!-- loading button -->
                                      <s-button v-else class="complete-subscribe__password--btn btn btn--success btn--lg w-100 disabled">
                                          <s-loader :isWhite="true"></s-loader>
                                      </s-button>

                                      <!-- error alert -->
                                      <div class="complete-subscribe__password--alert" v-if="messageErreurPassword.length">{{messageErreurPassword}}</div>

                                  </div>
                              </form>

                              <div v-show="step == 'about-you'">
                                  <about-you @next="getNextFromAboutYou"></about-you>
                              </div>
                          </s-card-body>
                          <s-card-body v-if="loadingSteps" style="padding: 224px 0;" class="text-center complete-subscribe__wrapper--card-body border-radius">
                              <s-loader></s-loader>
                          </s-card-body>
                      </s-card>
                    </div>

                </div>


            </div>

        </div>
    </div>

</template>

<script>
// import FacebookLogin from '../facebook/ButtonFacebookLogin.vue'
import AboutYou from './AboutYou.vue'
export default {
  name: 'complete-subscribe',
  props:{
    newAccount:{type:Boolean, default:false}
  },
  components: {
    // FacebookLogin
    AboutYou
  },

  data(){
    return{
      steps: ['password','about-you'],
      step: 'password',
      checked: [],
      name: '',
      password: '',
      loadingPassword: false,
      messageErreurPassword: '',
      accessToken : null,
      hideFirstStep:false,
      loadingSteps:false,
      inSmall:false,
      selectedView: null,
      headerWidth:0,
      isOpenedWindowToConnected:false
    };
  },

  beforeRouteLeave(to, from, next) {
    localStorage.removeItem("isSocialMediaSignup")
    next()
  },

  async mounted(){
    window.addEventListener("resize",(ev)=>{
        this.windowWidth(ev.target.innerWidth)
    })
    this.windowWidth(window.innerWidth);

    this.loadingSteps = true
    if (this.$route.query.at || this.$Cookies.get("X-Scalify-Token")) {
        this.setToken(this.$route.query.at ? this.$route.query.at : this.$Cookies.get("X-Scalify-Token"));
        this.accessToken = this.$route.query.at ? this.$route.query.at : this.$Cookies.get("X-Scalify-Token");
        // await this.userData();
    }

    if (this.$route.query.social_network && JSON.parse(JSON.stringify(this.$route.query.social_network))){
        localStorage.setItem("isSocialMediaSignup",true)
    }

    const step = await this.checkSteps();
    console.log('step checkSteps: ', step);
    let currentStep = "";
    if (step && step.data && step.data.checkSteps && step.data.checkSteps == "done") {
      this.step = step.data.checkSteps;
      await this.userData();
      if (this.user && this.user.approved) {
        this.$router.push('/');
      }
    }else{
      currentStep = step.data.checkSteps
    }


    if (currentStep == "password") {
        let getName = await this.getUserName();
        if(getName && getName.name != "Unnamed") {
            this.name = getName.name;
        }else{
            this.name = "";
        }
        if (getName && getName.id) {
            this.$store.state.user = {id: getName.id, name: getName.name, email: getName.email, accounts: [] }
        }

    }else if (currentStep != "password" && currentStep != "done") {
        await this.getUserData();
    }


    if(JSON.parse(localStorage.getItem("isSocialMediaSignup")) == true){
      this.steps = ['about-you']
    }else{
      this.steps = ['password','about-you']
    }


    if (currentStep !== 'done' && currentStep !== '') {
        this.step = currentStep;
        if (this.step == 'about-you') { this.checked = ['password'] }
    }else if(currentStep == 'done' && this.user && this.user.approved){
      this.step = this.steps[0];
    }

    this.loadingSteps = false
  },

  watch:{
    step(){
      this.scrollToCurrentStep()
    }

  },

  methods:{

    scrollToCurrentStep(){
        let parent = document.getElementById("headerScroller")
        let elmnt = document.getElementById('scalify-'+this.step);
        if(elmnt){
            let elementLeft = elmnt.offsetLeft
            elmnt.scrollIntoView(false);
            parent.scrollTo({
                top: 0,
                left: elementLeft,
                behavior: 'smooth'
            })
        }
    },

    windowWidth(w){
      this.$nextTick(()=>{
        this.scrollToCurrentStep()
        if(w<768){
          this.inSmall = true
        }
        else{
          this.inSmall = false
        }
      })
    },

    logout(){
        this.loadingSteps = true
        window.location.assign(window.location.origin+"/login")
        this.$store.state.scalifyAccessToken = null
        this.$Cookies.remove('X-Scalify-Token');
        // this.$store.state.user = null
        this.loadingSteps = false
    },

    async saveNameAndPassword(){

        this.loadingPassword = true;
        if (this.validationSchema.validate({ full_name: this.name, password: this.password }) && this.validationSchema.validate({ full_name: this.name, password: this.password }).error) {
            if (this.validationSchema.validate({ full_name: this.name, password: this.password}).error.details[0].context.key == "full_name") {
                this.messageErreurPassword = "Name must be at least 2 characters";

            }if (this.validationSchema.validate({ full_name: this.name, password: this.password}).error.details[0].context.key == "password") {
                this.messageErreurPassword = "Password must be at least 9 characters";
            }
        }else{
            try{
                const resultPasswordAndName = await this.updateAffiliate(null, null ,this.password, this.name,null,null)
                if (resultPasswordAndName && resultPasswordAndName.data.updateAffiliate) {

                  this.$store.state.user.name = this.name;
                  this.nextStep('password', false)

                }
            }
            catch(err){
                this.handleErrors(err)
            }
        }

        this.loadingPassword = false;
    },

    getNextFromAboutYou(data){
        this.nextStep("about-you", data)
        localStorage.removeItem('isSocialMediaSignup')
    },

    async nextStep(current){
        this.checked.push(current);
        var step = await this.checkSteps();
        console.log('step: ', step);
        if (step && step.data && step.data.checkSteps) {
            if (step.data.checkSteps == 'done') {
                this.step = "done"
                await this.userData()
                if (this.user && this.user.approved) {
                  this.$router.push('/');
                }
            }else{
                this.step = step.data.checkSteps;
            }
        }

    },

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
