import Input from './input'
import Badge from './badge'
import Tag from './tag'
import Button from './button'
import Card from './card';
import Dropdown from './dropdown'
import Checkbox from './checkbox';
import Slider from './range-slider'
import MultipleSelect from './multiple-select'
// import Carousel from './carousel'
import Loader from './spinner'
import ListGroup from './list-group';
import Progress from './progress';
import Confirm from './confirm'
import Tabs from './tabs'
import Tooltip from './tooltip'
import Toast from './toast'
export default {
    Input,
    Badge,
    Tag,
    Button,
    Card,
    Slider,
    Dropdown,
    Checkbox,
    MultipleSelect,
    Loader,
    ListGroup,
    Progress,
    Confirm,
    Tabs,
    Tooltip,
    Toast
}
