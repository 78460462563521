import Vue from 'vue';

import Dropdown from './Dropdown.vue'
import DropdownGroup from './DropdownGroup.vue'


Vue.component('s-dropdown',Dropdown);
Vue.component('s-dropdown-group',DropdownGroup);



export default{
    Dropdown,
    DropdownGroup,
}

