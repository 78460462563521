/* eslint-disable no-undef */
const moment = require("moment");

export default {

    data(){
        return{
            apiAuth: `https://affiliate-api.scalify.app/auth`,
            apiGraphql: `https://affiliate-api.scalify.app/graphql`,
            validationSchema: this.$joi.object().keys({
                email: this.$joi.string().email({ minDomainSegments: 2, tlds: false }).min(10).max(60),
                confirm_email: this.$joi.ref('email'),
                password: this.$joi.string().min(9),
                confirm_password: this.$joi.ref('password'),
                account_name: this.$joi.string().min(2).max(30),
                full_name: this.$joi.string().min(2),
                subject: this.$joi.string().min(2),
                message: this.$joi.string().min(2)
            }),
        }
    },

    computed:{
        dashboardStep() {
            return this.$store.state.dashboardStep;
        },
        user() {
            return this.$store.state.user;
        },
        scalifyAccessToken() {
            return this.$store.state.scalifyAccessToken;
        }
    },

    methods: {

            // ------------------------------- Globale POST & GET------------------------------- //

                // Post Data
                async postDataWithoutToken(endpoint, data){
                    try {
                        var res = await this.$axios.post(endpoint, data);

                        console.log('Post: ', res);
                        if (res && res.data && (res.data.status == "Token is Invalid" || res.data.status == "Token is Expired")) {
                            this.$router.push('/login');
                            return;
                        }
                        return res.data;
                    }
                    catch(error) {
                        console.log('error: ', error);
                    }
                },
                // Post Data
                async postData(endpoint, data){
                    this.$axios.defaults.headers.common["X-Scalify-Token"] = this.scalifyAccessToken;
                    try {
                        var res = await this.$axios.post(endpoint, data);

                        if (res && res.data && (res.data.status == "Token is Invalid" || res.data.status == "Token is Expired")) {
                            this.$router.push('/login');
                            return;
                        }
                        return res.data;
                    }
                    catch(error) {
                        console.log('error: ', error);
                    }
                },

                // Get Data
                async getData(endpoint){
                    this.$axios.defaults.headers.common["X-Scalify-Token"] = this.scalifyAccessToken;
                    try {
                        var res = await this.$axios.get(endpoint);
                        if (res && res.data && (res.data.status == "Token is Invalid" || res.data.status == "Token is Expired")) {
                            this.$router.push('/login');
                            return;
                        }
                        console.log('Get: ', res);
                        return res.data;
                    }
                    catch(error) {
                        console.log('error: ', error);
                    }
                },

            // ------------------------------- About Authentication ------------------------------- //

                async login(email,password){
                    try {
                        let endpoint = `${this.apiAuth}`;
                        let data = {query: `mutation LOGIN($email:String!,$password:String!){
                                login(email:$email,password:$password){
                                    access_token
                                }
                            }`,variables:{email:email, password: password}
                        }
                        const resultLogin = await this.postDataWithoutToken(endpoint,data);
                        return resultLogin;

                    } catch (err){
                        return err;
                    }
                },

                async subscribe(email){
                    try {
                        let endpoint = `${this.apiAuth}`;
                        let data = {query: `mutation SUBSCRIBE($email: String!){
                                subscribe(email:$email)
                            }`,variables:{email:email}
                        }
                        const resultSubscribe = await this.postDataWithoutToken(endpoint,data);
                        console.log('resultSubscribe: ', resultSubscribe);
                        return resultSubscribe;

                    } catch (err){
                        return err;
                    }
                },

                async resetPassword(email){
                    try {
                        let endpoint = `${this.apiAuth}`;
                        let data = {query: `mutation RESETPASSWORD($email: String!){
                            resetPassword(email:$email)
                            }`,variables:{email:email}
                        }
                        const resultResetPassword = await this.postData(endpoint,data);
                        return resultResetPassword;

                    } catch (err){
                        return err;
                    }
                },

                async receiveNewPassword(password, access_token){
                    try {
                        let endpoint = `${this.apiAuth}`;
                        let data = {query: `
                            mutation RECEIVENEWPASSWORD($password: String!, $access_token: String!){
                                receiveNewPassword(password:$password, access_token:$access_token)
                            }`,variables:{password: password, access_token:access_token}
                        }
                        const updatePassword = await this.postData(endpoint,data);
                        return updatePassword;

                    } catch (err){
                        return err;
                    }
                },

                async checkSteps(){
                    try {
                        let endpoint = `${this.apiGraphql}`;
                        let data = {query: `
                            {
                                checkSteps
                            }`
                        }
                        const checkStep = await this.postData(endpoint,data);
                        return checkStep;

                    } catch (err){
                        return err;
                    }
                },

                async getUserName(){
                    try {
                        let endpoint = `${this.apiGraphql}`;
                        let data = {query: `
                            query{
                                me{
                                    id name email
                                }
                            }`
                        }
                        const userName = await this.postData(endpoint,data);
                        return userName.data.me;

                    } catch (err){
                        return err;
                    }
                },

                async updateAffiliate(paypal_email, discount, password, name, about_you, language){
                    try {
                        let endpoint = `${this.apiGraphql}`;
                        let data = {query: `
                            mutation UPDATEAFFILIATE($paypal_email: String, $discount: Float, $password: String, $name: String, $about_you: JSONObject, $language: String){
                                updateAffiliate(paypal_email:$paypal_email, discount:$discount, password:$password, name:$name, about_you:$about_you, language:$language)
                            }`,variables:{paypal_email: paypal_email, discount: discount, password: password, name: name, about_you: about_you, language: language}
                        }
                        const updateAffiliate = await this.postData(endpoint,data);
                        return updateAffiliate;

                    } catch (err) {
                        return err;
                    }
                },

            // ------------------------------- About USER ------------------------------- //

                async userData(){
                    // return new Promise(async (resolve)=>{
                        try {
                            let endpoint = `${this.apiGraphql}`;
                            let data = {query: `
                                query USERDATA{
                                    me{
                                        id,name,email,discount,total_earned,upcoming_payout,available_balance,is_requested,approved,language,paypal_email
                                    }
                                }`
                            }
                            const userData = await this.postData(endpoint,data);
                            if (userData && userData.data && userData.data.me) {
                                this.$store.state.user = userData.data.me;
                                // return resolve(userData.data.me);
                                return userData.data.me;
                            }else{
                                // this.notify('error', userData.errors[0].message);
                                this.$router.push('/login');
                            }

                        } catch (err){
                            // return resolve(err);
                            return err;
                        }
                    // })
                },
                async getUserData(){

                    try {
                        let endpoint = `${this.apiGraphql}`;
                        let data = {query: `
                            query{
                                me{
                                    id,name,email,discount,total_earned,upcoming_payout,available_balance,is_requested,approved,language,paypal_email
                                }
                            }`
                        }
                        const userData = await this.postData(endpoint,data);
                        if (userData && userData.data && userData.data.me) {
                            this.$store.state.user = userData.data.me;
                            return userData.data.me;
                        }

                    } catch (err){
                        return err;
                        // return err;
                    }
                },

            // ------------------------------- About Activities------------------------------- //

                async getActivities(page){
                        try {
                            let endpoint = `${this.apiGraphql}`;
                            let data = {query: `{
                                getActivities(page:${page},per_page :10)
                              }`
                            }
                            const activities = await this.postData(endpoint,data);
                            return activities;

                        } catch (err){
                            return err;
                        }
                },

            // ------------------------------- About Payout------------------------------- //

                async getPayouts(page){
                    try {
                        let endpoint = `${this.apiGraphql}`;
                        let data = {query: `{
                            getPayouts(page:${page},per_page :10)
                        }`
                        }
                        const payouts = await this.postData(endpoint,data);
                        return payouts;

                    } catch (err){
                        return err;
                    }
                },

            // ------------------------------- Withdraw Button ------------------------------- //

                async withdraw(){
                    try {
                        let endpoint = `${this.apiGraphql}`;
                        let data = {query: `mutation {
                            withdraw
                            }`
                        }
                        const withdraw = await this.postData(endpoint,data);
                        return withdraw;

                    } catch (err) {
                        return err;
                    }
                },

            // ------------------------------- Contact Us ------------------------------- //

                async contactUs(endpoint, data){
                    this.$axios.defaults.headers.common["X-Scalify-Token"] = '$2y$12$ewYmAd1fTnYtr5nCm3unT.fnnslYUZa8UvRYWz92MbQhAAigWjfM.';
                    try {
                        var contactUs = await this.$axios.post(endpoint, data);
                        console.log('contactUs')
                        // if (contactUs && contactUs.data && (contactUs.data.status == "Token is Invalid" || contactUs.data.status == "Token is Expired")) {
                        //     this.$router.push('/login');
                        //     return;
                        // }
                        return contactUs.data;
                    }
                    catch(error) {
                        console.log('error: ', error);
                    }
                },

            // ------------------------------- Charts ------------------------------- //

                async getCharts(type, start_date, end_date){
                    try {
                        let endpoint = `${this.apiGraphql}`;
                        let data = {query: `
                            query GETCHARTS($start_date: String!, $end_date: String!, $type: String!){
                                getCharts(start_date:$start_date, end_date:$end_date, type:$type)
                            }`,variables:{start_date: start_date, end_date: end_date, type: type}
                        }
                        const dataChart = await this.postData(endpoint,data);
                        return dataChart;

                    } catch (err){
                        return err;
                    }
                },
                async getMultipleCharts(start_date, end_date){
                    try {
                        let endpoint = `${this.apiGraphql}`;
                        let data = {query: `
                            query GETCHARTS($start_date: String!, $end_date: String!){
                                revenues: getCharts(start_date:$start_date, end_date:$end_date, type:"revenues")
                                clicks: getCharts(start_date:$start_date, end_date:$end_date, type:"clicks")
                                signups: getCharts(start_date:$start_date, end_date:$end_date, type:"signups")
                            }`,variables:{start_date: start_date, end_date: end_date}
                        }
                        const dataCharts = await this.postData(endpoint,data);
                        return dataCharts;

                    } catch (err){
                        return err;
                    }
                },

            // ------------------------------- Login using google or facebook ------------------------------- //

                async loginWithSocialNetwork(user,type){
                    try {
                        let endpoint = `${this.apiAuth}`;
                        let data = {query: `mutation loginWithSocialNetwork($email:String,$token:String!,$type:String!){
                                loginWithSocialNetwork(email:$email,token:$token,type:$type){
                                    access_token
                                }
                            }`,variables:{email:user.email,type:type,token:user.token}
                        }
                        const resultLogin = await this.postData(endpoint,data);
                        return resultLogin;
                    } catch (err){
                        return err;
                    }
                },
                async facebookLoginSignUp(){
                    return new Promise((resolve,reject)=>{
                        FB.init({
                            appId      : "210906782939910",
                            cookie     : true,
                            xfbml      : true,
                            version    : 'v8.0'
                        });
                        FB.login((response)=> {
                            if (response.authResponse) {

                                FB.api('/me?fields=name,email',(result)=> {
                                    let res = result
                                    res.token = response.authResponse.accessToken
                                    resolve(res)
                                });
                            } else {
                                reject(null)
                            }
                        },{scope: 'email',return_scopes: true});
                    })
                },

                async signUpWithSocialNetwork(user,type){
                    try {
                        let endpoint = `${this.apiAuth}`;
                        let data = {query: `mutation signUpWithSocialNetwork($name:String!,$email:String,$token:String!,$type:String!){
                            signUpWithSocialNetwork(name:$name,email:$email,token:$token,type:$type)
                            }`,variables:{email:user.email, name: user.name,type:type,token:user.token}
                        }
                        const resultSubscribe = await this.postData(endpoint,data);
                        return resultSubscribe;

                    } catch (err){
                        return err;
                    }
                },



            // ------------------------------- OUR FUNCTIONS ------------------------------- //

                // show Toast
                notify(state ,text ,title,btn){
                    let button = btn?btn:null
                    let toastID = Math.random();
                    setTimeout(() => { this.removeToast(toastID) }, 7000);
                    // this.$store.state.toasts.push({id:toastID ,state: state, text: text, title:title})
                    let toasts = this.$store.state.toasts
                    let exist = false
                    for(let i=0;i<toasts.length;i++){
                        if(toasts[i].state == state && toasts[i].text == text){
                            exist = true
                            break
                        }
                    }
                    if(!exist && text && text!==""){
                        this.$store.state.toasts.push({id:toastID ,state: state, text: text, title:title,btn:button})
                    }
                    else{
                        return
                    }
                },
                removeToast(id) {
                    var index = this.$store.state.toasts.findIndex(obj => obj.id == id)
                    if (index != -1) {
                        this.$store.state.toasts.splice(index, 1)
                    }
                },
                setToken(token) {
                    this.$store.state.scalifyAccessToken = token;
                    this.$Cookies.set("X-Scalify-Token", token, { expires: 30 });
                },
                checkToken() {
                    // if we have a token
                    if (this.$Cookies.get("X-Scalify-Token")) {

                        // set cookies in axios
                        this.$store.state.scalifyAccessToken = this.$Cookies.get("X-Scalify-Token");
                        return true;

                    }
                    else {
                        // redirect to login
                        this.$router.push("/login");
                        return false;

                    }
                },
                getDates(startDate, endDate, days) {
                    if (!days) {
                        days = 1
                    }
                    var dateArray = [];
                    var currentDate = moment(startDate);
                    var stopDate = moment(endDate);
                    while (currentDate <= stopDate) {
                        dateArray.push( moment(currentDate).format('YYYY-MM-DD') )
                        if(days == 30){
                            currentDate = moment(currentDate).add(1, 'month');
                        }else if (days == 7){
                            currentDate = moment(currentDate).add(1, 'week');
                        }else{
                            currentDate = moment(currentDate).add(days, 'days');
                        }
                    }
                    return dateArray;
                },
                // check an object if empty
                isEmpty(obj) {
                    for(var prop in obj) {
                        // if(obj.hasOwnProperty(prop))
                         return false;
                    }
                    return true;
                },
                md5(s) {
                    return this.$md5(s);
                },
                capitalize(text) {
                    return text.replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
                },
                kFormatter(num) {
                    return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
                },
                fromNow(date){
                    return moment(date).fromNow()
                },
                formatDate(date){
                    return moment(date).format('ll')
                },
                handleErrors(err){
                    if(window && window.location && window.location.hostname !== "localhost"){
                        // alert(this.$t('errors.an_error_occured_try_again'));
                    }
                    else{
                        this.notify("error","Error to handle")
                        console.log("the error is : ",err)
                    }
                },
                loadGapi(){
                    if (!document.getElementById('gapi-sdk') || this.isEmpty(document.getElementById('gapi-sdk'))){
                        let gapiSDK = document.createElement("script");
                        gapiSDK.src = `https://apis.google.com/js/platform.js`;
                        gapiSDK.id = "gapi-sdk";
                        document.getElementsByTagName('head')[0].appendChild(gapiSDK);
                    }
                }
    }
};