import Vue from 'vue';

import Confirm from './Confirm.vue'

Vue.component('s-confirm',Confirm);

export default{
    Confirm,
}

