    <template>
    <div id="sign-up">
        <div class="sign-up__card mb-5">
            <!-- header menu sign up with (shopifu/faebook/gmail) -->
            <div class="sign-up__card--header">
                <h1 class="sign-up__card--header-title">{{$t('register.join_the_partner_program')}}</h1>
                <p class="sign-up__card--header-para" v-if="!isShopifySignUp">{{$t('register.description')}}</p>
                <p class="sign-up__card--header-para" v-if="isShopifySignUp">{{$t('register.sign_up_with_shopify')}}</p>
            </div>
            <div class="sign-up__card--body" v-if="!isShopifySignUp && !isBigcommerceSignUp">

                <!-- sign up with facebook -->
                <div class="row no-gutters mb-2" style="height:48px;">
                    <button class="btn btn--white mb-2 sign-up__with__button btn--lg" @click.stop="signUpWithFacebook">
                        <div class="left-part">
                            <svg class="fb-icon" width="9" height="18" viewBox="0 0 9 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.12886 5.84224C8.07051 6.41985 8.01437 6.97153 7.95822 7.52391C7.92726 7.82783 7.88618 8.13106 7.8696 8.43498C7.85735 8.6575 7.77595 8.7526 7.53973 8.7475C6.95494 8.73457 6.37016 8.74179 5.78536 8.74393C5.38421 8.74537 5.37485 8.75686 5.37485 9.15009C5.37844 11.8587 5.38205 14.5674 5.38565 17.2754C5.38637 17.6434 5.38493 17.6441 5.01619 17.6441C4.06265 17.6448 3.10838 17.6362 2.15485 17.6499C1.90061 17.6535 1.83796 17.5677 1.83868 17.3251C1.84588 14.6164 1.84372 11.9077 1.843 9.19908C1.843 8.76911 1.84228 8.76911 1.40944 8.76842C1.03134 8.76766 0.653236 8.7612 0.275854 8.77055C0.084281 8.77558 -0.00142255 8.71585 1.78456e-05 8.5106C0.00721978 7.69173 0.00649961 6.87285 0.000738045 6.05398C-0.000702354 5.87681 0.0669961 5.8156 0.238403 5.81848C0.643154 5.82568 1.0479 5.82064 1.45338 5.82136C1.81779 5.82208 1.84156 5.80263 1.84228 5.44686C1.84444 4.80804 1.82932 4.16851 1.84732 3.53042C1.86605 2.87 1.98056 2.22326 2.29169 1.63126C2.76917 0.721648 3.56932 0.221113 4.55022 0.121005C5.67373 0.00649407 6.81092 0.0252192 7.94232 1.23102e-05C8.00287 -0.00142808 8.1195 0.123886 8.12026 0.190864C8.13251 1.03638 8.12673 1.88261 8.13031 2.72812C8.13106 2.89448 8.05613 2.94345 7.89911 2.94418C7.38636 2.94634 6.87213 2.93337 6.36223 2.97299C5.63556 3.02988 5.38853 3.3266 5.38565 4.05472C5.38421 4.52285 5.37701 4.99026 5.37628 5.45838C5.37557 5.812 5.39285 5.82784 5.73782 5.82784C6.43066 5.82857 7.12345 5.82712 7.81634 5.82857C7.91281 5.82928 8.00934 5.83721 8.12886 5.84224Z" fill="#2A8CFF"/>
                            </svg>
                        </div>
                        <div class="right-part text-center">
                            <s-loader :isWhite="true" v-if="loadingSignUp=='facebook'"></s-loader>
                            <p v-else>{{$t('register.sign_up_with_facebook')}}</p>
                        </div>
                    </button>
                </div>

                <!-- sign up with gmail -->
                <div class="row no-gutters mb-2" style="height:48px;">
                    <button ref="googlebtnSign" class="g-signin2" id="my-signin2" hidden></button>
                    <button @click.stop="signupGoogle" class="btn btn--white sign-up__with__button mb-0 btn--lg">
                        <div class="left-part">
                            <svg class="shopify-icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0)">
                                <path d="M19.1565 10.1871C19.1565 9.3677 19.0913 8.76976 18.9502 8.14969H9.77423V11.848H15.1603C15.0518 12.7671 14.4654 14.1512 13.1623 15.0813L13.144 15.2051L16.0453 17.4969L16.2463 17.5174C18.0923 15.7789 19.1565 13.2211 19.1565 10.1871Z" fill="#4285F4"/>
                                <path d="M9.77426 19.9313C12.413 19.9313 14.6282 19.0454 16.2463 17.5174L13.1623 15.0813C12.337 15.6682 11.2294 16.0779 9.77426 16.0779C7.18979 16.0779 4.99625 14.3395 4.21432 11.9366L4.0997 11.9466L1.08291 14.3273L1.04346 14.4391C2.6506 17.6945 5.9518 19.9313 9.77426 19.9313Z" fill="#34A853"/>
                                <path d="M4.2143 11.9366C4.00798 11.3166 3.88858 10.6521 3.88858 9.96565C3.88858 9.27908 4.00798 8.61473 4.20345 7.99466L4.19798 7.8626L1.14339 5.44366L1.04345 5.49214C0.381067 6.84305 0.000991821 8.36008 0.000991821 9.96565C0.000991821 11.5712 0.381067 13.0882 1.04345 14.4391L4.2143 11.9366Z" fill="#FBBC05"/>
                                <path d="M9.77425 3.85336C11.6094 3.85336 12.8473 4.66168 13.5532 5.33718L16.3114 2.59107C14.6174 0.985496 12.413 0 9.77425 0C5.95179 0 2.6506 2.23672 1.04346 5.49214L4.20346 7.99466C4.99625 5.59183 7.18978 3.85336 9.77425 3.85336Z" fill="#EB4335"/>
                                </g>
                                <defs>
                                <clipPath id="clip0">
                                <rect width="19.1667" height="20" fill="white"/>
                                </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <div class="right-part text-center">
                            <s-loader v-if="loadingSignUp=='google'"></s-loader>
                            <p v-else>{{$t('register.sign_up_with_google')}}</p>
                        </div>
                    </button>
                </div>

                <!-- sign up with shopify -->
                <div v-if="false" class="row no-gutters mb-2" style="height:48px;">
                    <s-button class="btn btn--white sign-up__with__button mb-2 btn--lg disabled" @click.native="isShopifySignUp = !isShopifySignUp">
                        <div class="left-part">
                            <svg class="shopify-icon" width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.4637 3.84516C15.4493 3.74438 15.3629 3.68678 15.2909 3.68678C15.2189 3.68678 13.7935 3.65799 13.7935 3.65799C13.7935 3.65799 12.5985 2.50613 12.4833 2.37654C12.3681 2.26136 12.1377 2.29015 12.0513 2.31895C12.0513 2.31895 11.821 2.39094 11.4466 2.50613C11.389 2.30455 11.2882 2.05978 11.1586 1.80061C10.7411 0.994311 10.1076 0.562363 9.35886 0.562363C9.30127 0.562363 9.25807 0.562363 9.20048 0.576761C9.17168 0.547965 9.15729 0.519168 9.12849 0.50477C8.79733 0.159212 8.37978 -0.0135668 7.87584 0.000831489C6.91116 0.029628 5.94648 0.735142 5.15457 1.97339C4.60744 2.85169 4.18989 3.94595 4.06031 4.79545C2.95164 5.14101 2.17414 5.38578 2.14534 5.38578C1.58381 5.55856 1.56941 5.57295 1.49742 6.10569C1.46862 6.50884 0 17.8691 0 17.8691L12.3249 20L17.6667 18.6754C17.6523 18.6754 15.4781 3.94595 15.4637 3.84516ZM10.8275 2.7077C10.5395 2.79409 10.2228 2.89488 9.8772 3.01007C9.8772 2.52053 9.80521 1.82941 9.58923 1.25348C10.3235 1.36867 10.6835 2.20376 10.8275 2.7077ZM9.22928 3.19724C8.58136 3.39882 7.87584 3.61479 7.17033 3.83077C7.3719 3.06766 7.74626 2.31895 8.207 1.81501C8.37978 1.62783 8.62455 1.42626 8.89812 1.31107C9.18608 1.8726 9.24368 2.66451 9.22928 3.19724ZM7.90464 0.648753C8.13501 0.648753 8.32219 0.691947 8.48057 0.807133C8.2214 0.936718 7.96223 1.13829 7.73186 1.39746C7.11273 2.05978 6.63759 3.08206 6.45042 4.07554C5.86009 4.26271 5.28416 4.43549 4.76582 4.59387C5.11138 3.02446 6.42162 0.691948 7.90464 0.648753Z" fill="#95BF47"/>
                                <path d="M15.291 3.6868C15.219 3.6868 13.7935 3.658 13.7935 3.658C13.7935 3.658 12.5985 2.50614 12.4833 2.37656C12.4401 2.33336 12.3825 2.30457 12.3249 2.30457V20L17.6667 18.6754C17.6667 18.6754 15.4925 3.94597 15.4781 3.84518C15.4493 3.74439 15.3629 3.6868 15.291 3.6868Z" fill="#5E8E3E"/>
                                <path d="M9.35887 6.43685L8.73974 8.75497C8.73974 8.75497 8.04863 8.43821 7.22793 8.4958C6.01847 8.56779 6.01847 9.3309 6.01847 9.51808C6.09046 10.5548 8.81173 10.7851 8.97011 13.2184C9.0853 15.1334 7.96224 16.4436 6.32084 16.5444C4.36268 16.6452 3.28281 15.4934 3.28281 15.4934L3.70036 13.7224C3.70036 13.7224 4.79462 14.5431 5.65852 14.4855C6.22005 14.4567 6.43602 13.9815 6.40723 13.6648C6.32084 12.3113 4.10351 12.3977 3.95952 10.166C3.84434 8.29423 5.06819 6.40805 7.77506 6.23528C8.82613 6.16328 9.35887 6.43685 9.35887 6.43685Z" fill="white"/>
                            </svg>
                        </div>
                        <div class="right-part text-center">
                            <s-loader v-if="loadingSignUp=='shopify'"></s-loader>
                            <p v-else>{{$t('register.btn_sign_up_with_shopify')}}</p>
                        </div>
                    </s-button>
                </div>

                <!-- sign up with bigcommerce -->
                <div v-if="false" class="row no-gutters" style="height:48px;">
                    <s-button class="btn btn--white sign-up__with__button mb-2 btn--lg disabled" @click.native="isBigcommerceSignUp = !isBigcommerceSignUp">
                        <div class="left-part">
                            <!-- <svg class="shopify-icon" width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.4637 3.84516C15.4493 3.74438 15.3629 3.68678 15.2909 3.68678C15.2189 3.68678 13.7935 3.65799 13.7935 3.65799C13.7935 3.65799 12.5985 2.50613 12.4833 2.37654C12.3681 2.26136 12.1377 2.29015 12.0513 2.31895C12.0513 2.31895 11.821 2.39094 11.4466 2.50613C11.389 2.30455 11.2882 2.05978 11.1586 1.80061C10.7411 0.994311 10.1076 0.562363 9.35886 0.562363C9.30127 0.562363 9.25807 0.562363 9.20048 0.576761C9.17168 0.547965 9.15729 0.519168 9.12849 0.50477C8.79733 0.159212 8.37978 -0.0135668 7.87584 0.000831489C6.91116 0.029628 5.94648 0.735142 5.15457 1.97339C4.60744 2.85169 4.18989 3.94595 4.06031 4.79545C2.95164 5.14101 2.17414 5.38578 2.14534 5.38578C1.58381 5.55856 1.56941 5.57295 1.49742 6.10569C1.46862 6.50884 0 17.8691 0 17.8691L12.3249 20L17.6667 18.6754C17.6523 18.6754 15.4781 3.94595 15.4637 3.84516ZM10.8275 2.7077C10.5395 2.79409 10.2228 2.89488 9.8772 3.01007C9.8772 2.52053 9.80521 1.82941 9.58923 1.25348C10.3235 1.36867 10.6835 2.20376 10.8275 2.7077ZM9.22928 3.19724C8.58136 3.39882 7.87584 3.61479 7.17033 3.83077C7.3719 3.06766 7.74626 2.31895 8.207 1.81501C8.37978 1.62783 8.62455 1.42626 8.89812 1.31107C9.18608 1.8726 9.24368 2.66451 9.22928 3.19724ZM7.90464 0.648753C8.13501 0.648753 8.32219 0.691947 8.48057 0.807133C8.2214 0.936718 7.96223 1.13829 7.73186 1.39746C7.11273 2.05978 6.63759 3.08206 6.45042 4.07554C5.86009 4.26271 5.28416 4.43549 4.76582 4.59387C5.11138 3.02446 6.42162 0.691948 7.90464 0.648753Z" fill="#95BF47"/>
                                <path d="M15.291 3.6868C15.219 3.6868 13.7935 3.658 13.7935 3.658C13.7935 3.658 12.5985 2.50614 12.4833 2.37656C12.4401 2.33336 12.3825 2.30457 12.3249 2.30457V20L17.6667 18.6754C17.6667 18.6754 15.4925 3.94597 15.4781 3.84518C15.4493 3.74439 15.3629 3.6868 15.291 3.6868Z" fill="#5E8E3E"/>
                                <path d="M9.35887 6.43685L8.73974 8.75497C8.73974 8.75497 8.04863 8.43821 7.22793 8.4958C6.01847 8.56779 6.01847 9.3309 6.01847 9.51808C6.09046 10.5548 8.81173 10.7851 8.97011 13.2184C9.0853 15.1334 7.96224 16.4436 6.32084 16.5444C4.36268 16.6452 3.28281 15.4934 3.28281 15.4934L3.70036 13.7224C3.70036 13.7224 4.79462 14.5431 5.65852 14.4855C6.22005 14.4567 6.43602 13.9815 6.40723 13.6648C6.32084 12.3113 4.10351 12.3977 3.95952 10.166C3.84434 8.29423 5.06819 6.40805 7.77506 6.23528C8.82613 6.16328 9.35887 6.43685 9.35887 6.43685Z" fill="white"/>
                            </svg> -->
                            <img src="./../../../assets/images/bigcommerce.svg" class="shopify-icon" width="18" height="20" />
                        </div>
                        <div class="right-part text-center">
                            <s-loader v-if="loadingSignUp=='bigcommerce'"></s-loader>
                            <p v-else>{{$t('register.sign_up_with_bigcommerce')}}</p>
                        </div>
                    </s-button>
                </div>
            </div>
            <!-- sign up with email form -->
            <form id="scalify-form" v-if="!isShopifySignUp && !isBigcommerceSignUp" @submit.prevent.stop="createNewUser" class="sign-up__card--body">


                <p class="m-0 display-block sign-up__card--body__title mb-3">{{$t('register.use_your_email')}}</p>
                <s-input name="email" class="sign-up__card--body__input mb-2" v-model="email" fieldFor="settings" :placeholder="$t('register.placeholder_email')"></s-input>
                <!-- <s-input name="email" class="sign-up__card--body__input mb-2" v-model="paypalEmail" fieldFor="settings" placeholder="Paypal Email"></s-input>
                <s-input name="name" class="sign-up__card--body__input mb-2" v-model="name" fieldFor="settings" placeholder="Name"></s-input>
                <s-input name="password" class="sign-up__card--body__input mb-3" v-model="password" type="password" fieldFor="settings" placeholder="Password"></s-input> -->
                <button type="submit" class="btn btn--success btn--lg sign-up__card--body__login-button w-100" v-if="!loading">{{$t('register.create_my_account')}}</button>

                <!-- loading button -->
                <s-button class="btn btn--success btn--lg sign-up__card--body__login-button w-100 disabled" v-if="loading">
                    <s-loader :isWhite="true"></s-loader>
                </s-button>

                <!-- Terms of service -->
                <div class="sign-up__card--body__terms mt-3">
                    <p>{{$t('register.by_creating_account')}}</p>
                    <p>{{$t('register.you_agree_to_our')}} <a target="_blank" href="https://x.scalify.com/privacy-policy/">{{$t('register.terms_of_service')}}</a></p>
                </div>

                <!-- error alert -->
                <div class="sign-up__card--body__alert mt-3" v-if="messageErreur && messageErreur.length">{{this.messageErreur}}</div>

            </form>
            <form v-if="isShopifySignUp && !isBigcommerceSignUp && false" @submit.prevent="signupShopify" class="sign-up__card--body">
                <div class="sign-up__card--body__input-group mb-2">
                    <s-input v-model="storeName" type="text" fieldFor="settings" class="sign-up__card--body__input-group--field" placeholder="Store Name" />
                    <div class="sign-up__card--body__input-group--label display-table">
                        <span class="display-table-cell py-0">.myshopify.com</span>
                    </div>
                </div>
                <s-button class="btn btn--success btn--lg sign-up__card--body__login-button w-100" v-if="!loading">{{$t('register.sign_up')}}</s-button>
                <s-button class="btn btn--success btn--lg sign-up__card--body__login-button w-100 disabled" v-if="loading">
                    <s-loader :isWhite="true"></s-loader>
                </s-button>
                <div class="display-table my-3 sign-up__card--body__signup-scalify w-100 text-center">
                    <p class="sign-up__card--body__signup-scalify--forgot m-0 w-100" @click.stop="isShopifySignUp = !isShopifySignUp">{{$t('register.choose_another_sign_up_method')}}</p>
                </div>

                <!-- Terms of service -->
                <div class="sign-up__card--body__terms mt-3">
                    <p>{{$t('register.by_creating_account')}}</p>
                    <p>{{$t('register.you_agree_to_our')}} <a target="_blank" href="https://x.scalify.com/privacy-policy/">{{$t('register.terms_of_service')}}</a></p>
                </div>

                <div class="sign-up__card--body__alert" v-if="messageErreur.length">{{this.messageErreur}}</div>
            </form>

            <form v-if="!isShopifySignUp && isBigcommerceSignUp && false" @submit.prevent="signupBigcommerce" class="sign-up__card--body">
                <div class="sign-up__card--body__input-group mb-2">
                    <s-input v-model="storeName" type="text" fieldFor="settings" class="sign-up__card--body__input-group--field" placeholder="Store Name" />
                    <div class="sign-up__card--body__input-group--label display-table">
                        <span class="display-table-cell py-0">.mybigcommerce.com</span>
                    </div>
                </div>
                <s-button class="btn btn--success btn--lg sign-up__card--body__login-button w-100" v-if="!loading">Sign Up</s-button>
                <s-button class="btn btn--success btn--lg sign-up__card--body__login-button w-100 disabled" v-if="loading">
                    <s-loader :isWhite="true"></s-loader>
                </s-button>
                <div class="display-table my-3 sign-up__card--body__signup-scalify w-100 text-center">
                    <p class="sign-up__card--body__signup-scalify--forgot m-0 w-100" @click.stop="isBigcommerceSignUp = !isBigcommerceSignUp">{{$t('register.choose_another_sign_up_method')}}</p>
                </div>

                <!-- Terms of service -->
                <div class="sign-up__card--body__terms mt-3">
                    <p>{{$t('register.by_creating_account')}}</p>
                    <p>{{$t('register.you_agree_to_our')}} <a target="_blank" href="https://x.scalify.com/privacy-policy/">{{$t('register.terms_of_service')}}</a></p>
                </div>

                <div class="sign-up__card--body__alert" v-if="messageErreur.length">{{this.messageErreur}}</div>
            </form>
        </div>

        <!-- footer card if user have account -->
        <div class="sign-up__second-card">
            <p class="m-0 display-block">{{$t('register.already_have_an_account')}} <span @click.stop="signIn">{{$t('register.sign_in_here')}}</span></p>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
export default {
  name: 'sign-up',
  props:{

  },
  components: {
  },
  watch:{

  },
  data(){
    return{
        loading:false,
        messageErreur:'',
        email: '',
        loadingSignUp:null,
        isShopifySignUp:false,
        isBigcommerceSignUp:false,
        storeName:"",
        isSigned:false,
        paypalEmail:"",
        name:"",
        password:""
    };
  },
  methods:{
    async signupShopify(){
        try{
            this.loading = true
            let domain = this.storeName.split('.myshopify.com')[0]
            const resultLogin = await this.signUpSignInWithShopify(domain,"sign_up");
            if (resultLogin.data && resultLogin.data.signUpSignInWithShopify) {
                window.open(resultLogin.data.signUpSignInWithShopify,'_self')
            }else{
                // this.messageErreur = resultLogin.errors[0].message;
                if(typeof resultLogin.errors[0] == "string" ){
                    let msg = JSON.parse(resultLogin.errors[0])
                    this.messageErreur = msg.message;
                }
                else{
                    this.messageErreur = resultLogin.errors[0].message;
                }
            }
            this.loading=false;
        }
        catch(err){
            this.loading = false
            this.handleErrors(err)
        }
    },
    async signupBigcommerce(){
        try{
            this.loading = true
            let domain = this.storeName.split('.mysbigcommerce.com')[0]
            const resultLogin = await this.signUpSignInWithShopify(domain,"sign_up");
            if (resultLogin.data && resultLogin.data.signUpSignInWithShopify) {
                window.open(resultLogin.data.signUpSignInWithShopify,'_self')
            }else{
                // this.messageErreur = resultLogin.errors[0].message;
                if(typeof resultLogin.errors[0] == "string" ){
                    let msg = JSON.parse(resultLogin.errors[0])
                    this.messageErreur = msg.message;
                }
                else{
                    this.messageErreur = resultLogin.errors[0].message;
                }
            }
            this.loading=false;
        }
        catch(err){
            this.loading = false
            this.handleErrors(err)
        }
    },
    signupGoogle(){
        this.$refs.googlebtnSign.click()
    },
    onFailure(data){
        if (data && data.error == "popup_closed_by_user") {
            this.$emit('enable-cookies', true);
        }
    },
    async onSignIn(user) {
        var profile = user.getBasicProfile();
        let token = user.getAuthResponse().id_token
        let g_user = {
            name:profile.getName(),
            email:profile.getEmail(),
            token:token
        }
        this.loadingSignUp = "google"
        const sign = await this.signUpWithSocialNetwork(g_user,"google")
        console.log('sign: ', sign);
        // return
        if(sign.data.signUpWithSocialNetwork){
            window.open(sign.data.signUpWithSocialNetwork,'_self')
        }
        else{
            this.loadingSignUp = null
            // this.messageErreur = sign.errors[0].message;
            if(typeof sign.errors[0] == "string" ){
                let msg = JSON.parse(sign.errors[0])
                this.messageErreur = msg.message;
            }
            else{
                this.messageErreur = sign.errors[0].message;
            }
        }
    },
    async signUpWithFacebook(){
        const res = await this.facebookLoginSignUp()
        if(res){
            this.loadingSignUp = "facebook"
            const sign = await this.signUpWithSocialNetwork(res,"facebook")
            if(sign.data.signUpWithSocialNetwork){
                window.open(sign.data.signUpWithSocialNetwork,'_self')
            }
            else{
                this.loadingSignUp = null
                // this.messageErreur = sign.errors[0].message;
                if(typeof sign.errors[0] == "string" ){
                    let msg = JSON.parse(sign.errors[0])
                    this.messageErreur = msg.message;
                }
                else{
                    this.messageErreur = sign.errors[0].message;
                }
            }
        }
        // this.createNewUser()
    },
    // Create New User
    async createNewUser(){
        this.loading = true;

        if (this.validationSchema.validate({ email: this.email}) && this.validationSchema.validate({ email: this.email}).error) {

            if(this.validationSchema.validate({ email: this.email}) && this.validationSchema.validate({ email: this.email}).error.details[0].context.key == "email") {
                this.messageErreur = this.$t('error.valid_email');
            }
            this.loading = false;
            return false
            // this.messageErreur = this.validationSchema.validate({ email: this.email}).error.details[0].message;
        }else{
            try{
                console.log('this.email: ', this.email);
                const resultSubscribe = await this.subscribe(this.email);
                if (resultSubscribe.data.subscribe) {
                    if(Vue.prototype.$isTesting){
                        this.isSigned = true
                    }
                    else{
                        // this.$router.push('/login');
                        this.$router.push('/check-inbox');
                    }
                    this.loading = false;
                    return true

                }else{
                    if(typeof resultSubscribe.errors[0] == "string" ){
                        let msg = JSON.parse(resultSubscribe.errors[0])
                        this.messageErreur = msg.message;
                    }
                    else{
                        this.messageErreur = resultSubscribe.errors[0].message;
                    }
                    this.loading = false;
                    return false
                }
            }
            catch(err){
                this.handleErrors(err)
            }
        }

        this.loading = false;
    },

    //emit that user clicks signin to login
    signIn(){
        this.$router.push("/login")
    }

  },
  async mounted(){

    await this.loadGapi()
    // eslint-disable-next-line no-undef
    Vue.prototype.$gapi = gapi ? gapi : null;
    this.$gapi.load('auth2', () => {
        // profile
        let auth2 = this.$gapi.auth2.init({client_id: "327952325257-caoeijrushnvfct66vnvvgr9vietm4dt.apps.googleusercontent.com",scope: 'email'})
        auth2.attachClickHandler('my-signin2', {}, this.onSignIn, this.onFailure);
    });
    this.$gapi.signin2.render('my-signin2', {
        'longtitle': true
    });

    if (this.$route && this.$route.query && this.$route.query.email && this.$route.query.from == 'website') {
        this.email = this.$route.query.email;
        this.createNewUser();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
