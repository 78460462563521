<template>
    <div class="slidercontainer">
      <div class="slidercontainer__adspend header" :style="{left:'calc('+((minValue/max)*100)+'% - 65px)'}">Your Ad Spend</div>
      <input @input="changingValue" :step="step" type="range" :min="min" :max="max" v-model="inputValue" class="slider" :style="{background:'linear-gradient(90deg,#fff '+((minValue*100)/max)+'%,#FFB300 '+((minValue*100)/max)+'%)'}">
      <div class="slidercontainer__steps" v-for="(item,index) in ((max-min)/step)+1" :key="index" v-show="parseInt(value) !== parseInt(index*step)">
        <div :style="{left:index!==0?'calc('+index*((100/((max-min)/step)))+'% - 2px )':index==0?'5px':'unset',bottom:'15px',borderRadius:'5px 5px 0 0'}" ></div>
        <div :style="{left:index!==0?'calc('+index*((100/((max-min)/step)))+'% - 2px )':index==0?'5px':'unset',borderRadius:'0 0 5px 5px'}" ></div>
      </div>
    </div>

</template>

<script>



export default {
  name: 's-slider',
  data(){
    return {
      inputValue:this.value,
    }
  },
  created(){
    if(this.minValue>this.value){
      this.inputValue=this.minValue
    }
    else if(this.maxValue<this.value){
      this.inputValue=this.maxValue
    }
  },
  props:{
    value:{type:[Number,String]},
    min:{type:[Number,String]},
    max:{type:[Number,String]},
    minValue:{type:[Number,String]},
    maxValue:{type:[Number,String]},
    step:{type:[Number,String],default:1}
  },
  methods:{
    changingValue(){
      if(this.inputValue>this.maxValue){
        this.inputValue=this.maxValue
      }
      else if(this.inputValue<this.minValue){
        this.inputValue=this.minValue
      }
    }
  },
  watch:{
    inputValue(){
      this.$forceUpdate()
      this.$emit('input',this.inputValue)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
