<template>
  <div :style="disabled?{cursor:'not-allowed',display:'inline-block'}:{}">
      <label class="toggle" :style="[disabled?{pointerEvents:'none',opacity:'.5'}:{}, isMini?{Height: '15px !important',Width: '30px !important'}:{}]">
          <input type="checkbox" v-model="inputValue">
          <span :class="isMini ? 'slider-mini round' : 'slider round'" :style="isMini && inputValue ? 'background-color: '+color : ''" ></span>
      </label>
    </div>
</template>

<script>



export default {
  name: 's-toggle',
  data(){
    return {
      inputValue : this.value
    }
  },
  props: {
    value: {
      type:Boolean,
      default:false
    },
    disabled:{
      type:Boolean,
      default:false
    },
    isMini:{
      type:Boolean,
      default:false
    },
    color:{
      type: String,
      default: "#6CD16A"
    }
  },

  watch:{
    inputValue(){
      this.$emit("input",this.inputValue)
    },
    value(){
      this.inputValue = this.value
    }
  },

  methods:{
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
