<template>
    <div class="card__header">
      <slot />
    </div>
</template>

<script>



export default {
  name: 's-card-header',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
