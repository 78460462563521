<template>
    <div class="login__notes-wrapper">
      <div class="login__notes-wrapper--card">

        <div class="login__notes-wrapper--card-background">
          <svg class=""  width="408" height="406" viewBox="0 0 408 406" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M163.626 10.5167L318.643 29.9227C348.814 33.6997 372.785 57.0316 377.313 87.0304L400.364 239.713C404.183 265.008 393.393 290.294 372.469 305.083L260.084 384.52C239.394 399.144 212.262 401.05 189.727 389.462L71.8775 328.859C54.4984 319.922 41.8266 303.943 37.1066 285.013L7.78034 167.397C1.7268 143.119 9.58099 117.486 28.2076 100.73L109.701 27.423C124.36 14.2365 144.044 8.0653 163.626 10.5167Z" fill="#FFC04B"/>
          </svg>
          <!-- <div class="login__notes-wrapper--pagination mt-0" >
            <svg v-for="(note, noteIndex) in notesList" :key="noteIndex" @click="changeNote(noteIndex)" :class="dotIndex == noteIndex ?'active':''" class="login__notes-wrapper--pagination-svg" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="10" height="10" rx="5" fill="white"/></svg>
          </div> -->
        </div>

        <s-card>
            <div ref="noteCard" class="login__notes-wrapper--div-container" >
              <s-card-body class="h-100">
                <div ref="nodeCardContent" class="login__notes-wrapper--div-container__div">
                  <div class="login__notes-wrapper--icon">
                    <svg width="38" height="31" viewBox="0 0 38 31" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.5 2.5C8.5 4.5 4.5 9.5 4.5 14C4.5 15 4.5 16 5 16.5C6 15.5 7.5 15 9.5 15C13.5 15 17 18 17 22.5C17 27 13.5 30 9.5 30C5.5 30.5 3.5 29 2 26.5C0.5 24 0 20.5 0 18.5C0 10.5 4 4 12.5 0L13.5 2.5ZM34 2.5C29 4.5 25 9.5 25 14C25 15 25 16 25.5 16.5C26.5 15.5 28 15 30 15C34 15 37.5 18 37.5 22.5C37.5 27 34 30 30 30C26.5 30 24.5 28.5 23 26C20.5 24 20.5 20.5 20.5 18.5C20.5 10.5 24.5 4 33 0L34 2.5Z" fill="#047FF1"/></svg>
                  </div>
                  <p class="login__notes-wrapper--para">{{notesList[selectedNoteIndex].text}}</p>
                  <h3 class="login__notes-wrapper--author">{{notesList[selectedNoteIndex].author}} - {{notesList[selectedNoteIndex].store}}</h3>
                  <div class="login__notes-wrapper--stars">
                    <svg v-for="(star, indexStar) in notesList[selectedNoteIndex].stars" :key="indexStar" class="login__notes-wrapper--stars-svg" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.9658 1.03714L13.2215 5.60786C13.3784 5.92576 13.6817 6.14603 14.0325 6.19693L19.0768 6.92993C19.9604 7.05842 20.3129 8.14395 19.6737 8.76681L16.0238 12.3246C15.7701 12.572 15.6541 12.9287 15.7142 13.2779L16.5757 18.3016C16.7267 19.1815 15.8031 19.8523 15.0129 19.4372L10.5015 17.0655C10.1877 16.9007 9.81267 16.9007 9.49895 17.0655L4.98747 19.4372C4.19732 19.8527 3.27367 19.1815 3.42469 18.3016L4.28618 13.2779C4.34626 12.9287 4.23028 12.572 3.97663 12.3246L0.326648 8.76681C-0.312484 8.14353 0.0400399 7.058 0.923644 6.92993L5.96787 6.19693C6.31872 6.14603 6.62202 5.92576 6.77888 5.60786L9.03462 1.03714C9.42928 0.23656 10.5707 0.23656 10.9658 1.03714Z" fill="#FDC02F"/></svg>
                  </div>
                </div>
              </s-card-body>
            </div>
        </s-card>

        <div class="login__notes-wrapper--pagination">
          <svg v-for="(note, noteIndex) in notesList" :key="noteIndex" @click="changeNote(noteIndex)" :class="dotIndex == noteIndex ?'active':''" class="login__notes-wrapper--pagination-svg" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="10" height="10" rx="5" fill="white"/></svg>
        </div>

      </div>

      <!-- <div class="login__notes-wrapper--card">
        <div class="login__notes-wrapper--card-background position-relative">
          <div class="login__notes-wrapper--pagination">
            <svg v-for="(note, noteIndex) in notesList" :key="noteIndex" @click="changeNote(noteIndex)" :class="dotIndex == noteIndex ?'active':''" class="login__notes-wrapper--pagination-svg" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="10" height="10" rx="5" fill="white"/></svg>
          </div>
        </div>
      </div> -->
    </div>
</template>

<script>
export default {
  name: 'Notes',
  props:{

  },
  components: {
  },
  watch:{

  },
  data(){
    return{
      notesList: [
        {author: 'Australian Discount Store', platform:'Shopify Store', stars: 5, text:'Awesome app - does the work of at least 10 people and I would highly recommend this app to anyone who is serious about scaling their eCommerce business.', store:'Shopify Store'},{author: 'Australian Discount Store', platform:'Shopify Store', stars: 5, text:'Awesome app - does the work of at least 10 people and I would highly recommend this app to anyone who is serious about scaling their eCommerce business.', store:'Shopify Store'},{author: 'Australian Discount Store', platform:'Shopify Store', stars: 5, text:'Awesome app - does the work of at least 10 people and I would highly recommend this app to anyone who is serious about scaling their eCommerce business.', store:'Shopify Store'},{author: 'Australian Discount Store', platform:'Shopify Store', stars: 5, text:'Awesome app - does the work of at least 10 people and I would highly recommend this app to anyone who is serious about scaling their eCommerce business.', store:'Shopify Store'},
        {author: 'Morocccan Discount Store', platform:'Shopify Store', stars: 5, text:'Awesome app - does the work of at least 10 people and I would highly recommend this app to anyone who is serious about scaling their eCommerce business.Awesome app - does the work of at least 10 people and I would highly recommend this app to anyone who is serious about scaling their eCommerce business.', store:'Shopify Store'},
        {author: 'Indian Discount Store', platform:'Shopify Store', stars: 5, text:'Awesome app - does the work of at least 10 people and I would highly recommend this app to anyone who is serious about scaling their eCommerce business.', store:'Shopify Store'},
        {author: 'Mexican Discount Store', platform:'Shopify Store', stars: 5, text:'Awesome app - does the work of at least 10 people and I would highly recommend this app to anyone who is serious about scaling their eCommerce business.Awesome app - does the work of at least 10 people and I would highly recommend this app to anyone who is serious about scaling their eCommerce business.', store:'Shopify Store'}
      ],
      selectedNoteIndex: 0,
      dotIndex: 0,

    };
  },

  mounted(){

    // gracefully show reviews
    this.$refs.noteCard.firstChild.style.opacity = 1;
    this.updateNotesWrapperHeight();
  },

  created() {
    window.addEventListener("resize", this.updateNotesWrapperHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.updateNotesWrapperHeight);
  },

  methods:{

    updateNotesWrapperHeight(){

      // get content height
      var contentHeight = this.$refs.nodeCardContent.clientHeight;
      // set wrapper height = content height + padding top + padding bottom
      this.$refs.noteCard.style.height = (parseFloat(contentHeight)  + parseFloat(120) ) +'px';
    },

     changeNote(noteIndex){

      // update dot index
      this.dotIndex = noteIndex;

      // hide content
      this.$refs.noteCard.firstChild.style.opacity = 0;

      // wait for animation to finish
      setTimeout(() =>{

        // change content
        this.selectedNoteIndex = noteIndex;

        // wait for dom to update
        this.$nextTick(()=>{

          this.updateNotesWrapperHeight();

          // wait for animation to finish
          setTimeout(()=>{

            // show content
            this.$refs.noteCard.firstChild.style.opacity = 1;

          }, 400);

        });

      },400);



     },


  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
