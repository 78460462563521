<template>
    <div id="overview">

      <div class="overview__mini-charts row no-gutters">

        <div class="overview__col-chart col-12 col-md-4">
          <MiniChart chartType="revenues" :startDate="showDateRange.startDate" :endDate="showDateRange.endDate" ></MiniChart>
        </div>
        <div class="overview__col-chart col-12 col-md-4">
          <MiniChart chartType="clicks" :startDate="showDateRange.startDate" :endDate="showDateRange.endDate" ></MiniChart>
        </div>
        <div class="overview__col-chart col-12 col-md-4">
          <MiniChart chartType="signups" :startDate="showDateRange.startDate" :endDate="showDateRange.endDate" ></MiniChart>
        </div>

      </div>
      <div  class="overview__multiple-chart">
        <MultipleChart :startDate="showDateRange.startDate" :endDate="showDateRange.endDate" ></MultipleChart>
      </div>

    </div>
</template>

<script>
import MultipleChart from '../charts/MultipleChart.vue'
import MiniChart from '../charts/MiniChart.vue'

export default {
  name: 'overview',

  components:{
    MiniChart,
    MultipleChart
  },

  props: ["showDateRange"],

  computed:{
  },

  data(){
    return{

    }
  },

  methods:{

  },

  async created(){

  },

  watch:{

  },

  async mounted(){

  }


}
</script>

<style scoped>

</style>
