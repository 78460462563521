import Vue from 'vue';

import Toast from './Toast.vue'

Vue.component('s-toast',Toast);


export default{
    Toast,
}

