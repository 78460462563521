import Vue from 'vue';

import Progress from './Progress.vue'

Vue.component('s-progress', Progress);

export default{
    Progress,  
}


