import Vue from 'vue';

import Slider from './Slider.vue'

Vue.component('s-slider', Slider);

export default{
    Slider, 
}


