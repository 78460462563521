<template>
    <div id="confirm">
        <s-card class="confirm__container">
            <div class="confirm__container--icon mb-4">
                <svg v-if="this.$store.state.confirmOptions.type =='error'" width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M60 30C60 46.5722 46.5674 60 30 60C13.4326 60 0 46.5722 0 30C0 13.4375 13.4326 0 30 0C46.5674 0 60 13.4375 60 30ZM30 36.0484C26.9268 36.0484 24.4355 38.5397 24.4355 41.6129C24.4355 44.6861 26.9268 47.1774 30 47.1774C33.0732 47.1774 35.5645 44.6861 35.5645 41.6129C35.5645 38.5397 33.0732 36.0484 30 36.0484ZM24.717 16.0469L25.6143 32.4985C25.6563 33.2683 26.2928 33.871 27.0637 33.871H32.9362C33.7072 33.871 34.3437 33.2683 34.3857 32.4985L35.283 16.0469C35.3284 15.2153 34.6663 14.5161 33.8336 14.5161H26.1663C25.3335 14.5161 24.6716 15.2153 24.717 16.0469Z" fill="#FDC02F"/>
                </svg>
            </div>
            <div class="confirm__container--para">
                <p class="mb-4">{{this.$store.state.confirmOptions.message}}</p>
            </div>
            <div class="confirm__container--btn">
                <button class="btn btn--white mr-2" @click="cancelConfirm">Cancel</button>
                <button class="btn btn--danger" @click="acceptConfirm">{{this.$store.state.confirmOptions.button?this.$store.state.confirmOptions.button:"Delete"}}</button>
            </div>

        </s-card>

    </div>
</template>

<script>
export default {
  name: 'confirm',
  props:{

  },
  components: {
  },
  watch:{

  },
  data(){
    return{

    };
  },
  methods:{
    acceptConfirm(){
        this.executeFunc(this.$store.state.confirmOptions.callback)
        this.$store.state.isConfirm = false
    },
    cancelConfirm(){
        this.$store.state.isConfirm = false
        this.$store.state.productToDelete = null
        this.$store.state.confirmOptions.type = null
        this.$store.state.confirmOptions.message = null
        this.$store.state.confirmOptions.callback = null
    },
    executeFunc(callback){
        callback()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
