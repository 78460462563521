<template>
    <div>
        <button @click="handleClick(item)"  class="btn btn--group" :class="['btn--'+color,item.value==selectedValue.value?'active':'',indexDisabled==index?'disabled':'']" v-for="(item,index) in options" :key="index">
            {{item.name?item.name:item}}
        </button>
    </div>
</template>

<script>



export default {
  name: 'button-group',
  props: {
    options: Array,
    color:String,
    value:{type:[Object,String]},
    indexDisabled:{type:Number,default:null}
  },
  data(){
    return{
        selectedValue:this.value
    }
  },
  methods:{
    handleClick(item) {
      this.selectedValue = item
      this.$emit('input', this.selectedValue);
    }
  },
  watch:{
    value:{
      deep:true,
      handler(){
        this.selectedValue = this.value
      }  
    }
  },
  mounted(){
    if(this.value=="" || this.isEmpty(this.value)){
        if(this.options.length){
            this.selectedValue = this.options[0]
        }
    }
    else {
        this.selectedValue = this.value
    }
    this.$forceUpdate()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
